
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { ProviderTypeEnum } from "@/Models/data-contract/index";
import { useStore } from "vuex";
import { DateHelper } from "@/Utilities/DateHelper";

class ProviderWage {

  constructor(effectiveStart: string, effectiveEnd: string | null, baseWage: number, goalWage: number) {
    this.effectiveStart = effectiveStart;
    this.effectiveEnd = effectiveEnd;
    this.baseWage = baseWage;
    this.goalWage = goalWage;  
  }

  effectiveStart: string;
  effectiveEnd: string | null;
  baseWage: number;
  goalWage: number;

  

  
}

interface ProviderWageHistory {
  providerId: string;
  providerWage: Array<ProviderWage>;
}

export default defineComponent({
  name: "EditWageHistory",
  emits: ["closeEditForm"],
  props: {
    selectedProvider: {
      required: true,
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      providerWageHistory: null as ProviderWageHistory | null,
      toast: useToast(),
      errorMessageCustom: "",
      assignableUsers: null,
      providers: [] as { id: ProviderTypeEnum; name: string; }[],
      providerTypeHygienist: ProviderTypeEnum.Hygienist
    };
  },
  updated() {

  },
  methods: {
   
   
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    discard() {
      // console.log("discard");
      this.$emit("closeEditForm");
    },
    async save() {
      
      if (!this.providerWageHistory || !this.providerWageHistory.providerWage) {
        this.$emit("closeEditForm");
        return;
      }
        

        //order by effective start date ascending
        this.providerWageHistory.providerWage = this.providerWageHistory.providerWage.sort((a, b) => {
          return Number(DateHelper.getDatePortionFromString(a.effectiveStart)) - Number(DateHelper.getDatePortionFromString(b.effectiveStart));
        });

        //convert effective end date to null if it is empty
        this.providerWageHistory.providerWage = this.providerWageHistory.providerWage.map(x => {
          if (x.effectiveEnd === "") {
            x.effectiveEnd = null;
          }
          return x;
        });

        


        for (let i = 0; i < this.providerWageHistory?.providerWage.length; i++) {
          const currentWage = this.providerWageHistory?.providerWage[i];
          const nextWage = this.providerWageHistory?.providerWage[i + 1];
          const startDate = DateHelper.getDatePortionFromString(currentWage.effectiveStart);
          const endDate = currentWage.effectiveEnd ? DateHelper.getDatePortionFromString(currentWage.effectiveEnd) : null;

          // Validate that the effective start date is less than the effective end date
          if (endDate && startDate > endDate) {
              this.toast.error("Effective Start Date must be less than Effective End Date");
              return;
          }

          // Validate that only the last record has no effective end date
          const isLastRecord = i === this.providerWageHistory?.providerWage.length - 1;
          if (!isLastRecord && !currentWage.effectiveEnd) {

              this.toast.error(`Only the last record should have no Effective End Date: ${currentWage.effectiveEnd}`);
              return;
          } else if (isLastRecord && currentWage.effectiveEnd) {
              this.toast.error("The last record must not have an Effective End Date");
              return;
          }

          // Validate that the base wage and goal wages are not empty and are greater than 0
          if (currentWage.baseWage <= 0 || currentWage.goalWage <= 0) {
              this.toast.error("Base Wage and Goal Wage must be greater than 0");
              return;
          }

          //validate that the end date, if it exists, is equal to the start date of the next record
          if (nextWage && endDate && Number(endDate) !== Number(DateHelper.getDatePortionFromString(nextWage.effectiveStart))) {
              this.toast.error(`Effective End Date must be equal to the Effective Start Date of the next record: ${Number(endDate)} and ${ Number(DateHelper.getDatePortionFromString(nextWage.effectiveStart))}`);
              return;
          }
      }


      console.log("providerSchedule", this.providerWageHistory);
      
      await ApiService.post(`/Provider/${this.selectedProvider.provider_id}/WageHistory`, this.providerWageHistory as any)
      this.$emit("closeEditForm");
    }
  },

  async mounted() {
    try {
      let res = await ApiService.get(`/Provider/${this.selectedProvider.provider_id}/WageHistory`)
      if (res.status === 200) {   
        res.data.providerWage = res.data.providerWage.map((x: any) => new ProviderWage(
          DateHelper.getDatePortion(DateHelper.getDatePortionFromString(x.effectiveStart)), 
          x.effectiveEnd ? DateHelper.getDatePortion(DateHelper.getDatePortionFromString(x.effectiveEnd)) : null, 
          x.baseWage, x.goalWage));     
        this.providerWageHistory = res.data;
        console.log("this.providerWageHistory", this.providerWageHistory);
      } else {
        this.toast.error(codes.error)
      }
    } catch (e) {
      console.log(e);
      this.toast.error(codes.error)
    }
  },
});
