
import { defineComponent } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import Avatar from "@/components/Avatar.vue";
import FullScreen from "@/components/FullScreen.vue";
import HardReload from "@/components/HardReload.vue";
import ApiService from "@/core/services/ApiService";
import ContactUs from "@/components/ContactUs.vue"
export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    Avatar,
    FullScreen,
    HardReload,
    ContactUs
  },
 data() {
    return {
      appointmentId: null,
    };
  }, 
  methods: {
    searchForAppointment(){
      if(this.appointmentId){
        this.$router.push({path: `/appointment/${this.appointmentId}`})
      }
    }
  }
/*   methods: {

    async checkStatus(){
      try {
         let response= await ApiService.get("user/current").then((response) => 
         {
            return response.status >= 200 && response.status < 300;
         });
         return response;
      }
      //  // either true or false
       catch (err) {
        return false; // definitely offline
      }
    },
  },
     */
/*   mounted() {

    setInterval(async () => {
      let currentStatus = this.isOnline;
      const result = await this.checkStatus();
      if (result !== true && this.isStatusDisplayed !== true) {
        this.isOnline = false;
        this.isStatusDisplayed = true;
      }
      if (
        result == true &&
        currentStatus === true &&
        this.isStatusDisplayed == true
      ) {
        this.isStatusDisplayed = false;
      }

      else if (result == true && currentStatus !== true) {
        this.isOnline = true;
        this.isStatusDisplayed = true;
      }
      
    }, 30000);
  }, */
});
