
	import useValidate from "@vuelidate/core";
	import { required, helpers, sameAs } from "@vuelidate/validators";
	import { defineComponent, reactive, computed, ref } from "vue";
	import ApiService from "@/core/services/ApiService";
	import { Models } from "@/Models/Models";
  import {useToast} from "vue-toastification";
  import {codes} from "@/Codes/Codes";

	interface FormState {
		currentPassword: string;
		password: string;
	}

	export default defineComponent({
		name: "EditUserPassword",
		props: {
			selectedUser: {
				required: true,
				type: Object,
			},			
		},
		components: {},
		data() {
			return {
        toast: useToast(),
				errorMessageCustom: "",
			};
		},
		mounted() {
			console.log("mounted now");
		},
		updated() {
			if (this.selectedUser && this.userId != this.selectedUser.userId) {
				this.userId = this.selectedUser.userId;
				this.state.password = "";
				this.state.currentPassword = "";
			}
		},
		methods: {
			showErrorMessage(message) {
				this.errorMessageCustom = message;
				let self = this;
				setTimeout(() => {
					self.errorMessageCustom = "";
				}, 5000);
			},
      checkPassStrength(pass){
           let upper = /[A-Z]/.test(pass);
           let number = /[0-9]/.test(pass);
           let length = pass.length >=6;
           if (upper && number && length){
             return{success: true}
           }else{
             return {success: false, upper: upper, num: number, len: length}
           }
      },
			async submit() {
				this.v$.$validate();
				if (!this.v$.$error) {
          if(!this.checkPassStrength(this.state.password).success){
            let results = this.checkPassStrength(this.state.password);
            this.errorMessageCustom = `Password must ${!results.upper ? 'contain an upper case letter' : (!results.num ? 'contain a number': (!results.len ? 'be at least 6 characters':'')) }`
            return;
          }
					try {						
						const user = await ApiService.get(`User/current`);
            if(user.status !== 200){
              this.toast.error(codes.error)
            }
						const currentUser = user.data as Models.CurrentUser;
            console.log(currentUser)
						const payload: any = {
              email: this.selectedUser.email,
              firstName: this.selectedUser.firstName,
              lastname: this.selectedUser.lastName,
			  password: this.state.password,
              userId: this.selectedUser.userId,
              isClinicAdmin: this.selectedUser.isAdmin,
						};

						if (!payload) {
							return;
						} else {
							console.log(payload);
							const response = await ApiService.postData("User", payload, {});
							console.log("RESPONSE", response);

							if (response.status === 200) {
								console.log(response.data);
                this.$emit("closeEditPassModal");
							} else {
								console.error(response);
                this.toast.error(codes.error)
							}
						}
					} catch (ex) {
            this.toast.error(codes.error)
						console.warn(ex);
					}
				}
			},
			discard() {
				console.log("discard");
				this.$emit("verifyParamsSubmitCompleted");
			},
		},

		setup(props, context) {
			// create user form
			console.log("props: ", props);
			let userId = 0;
			let state: FormState = reactive({
				currentPassword: "",
				password: "",
				// firstName: "",
				// lastName: "",
				// isAdmin: false,
				// baseWage: 0,
				// goalWage: 0,
				// position: 1,
			});

			if (props.selectedUser) {
				let user_to_edit = props.selectedUser as Models.UserToEdit;
				userId = user_to_edit.userId;
				let stateEdit: any = reactive({
					firstName: user_to_edit.firstName,
					lastName: user_to_edit.lastName,
					// baseWage: user_to_edit.baseWage,
					// goalWage: user_to_edit.goalWage,
					// position: user_to_edit.position,
					email: user_to_edit.email,
					password: "",
					isAdmin: user_to_edit.isAdmin,
				});

				state = stateEdit;
			}

			const rules: any = computed(() => {
				return {
					password: {
						required: helpers.withMessage("Password is required.", required),
					},
				};
			});

			let v$ = useValidate(rules, state);

			const closeForm = () => {
				context.emit("closeEditUserForm");
			};

			return {
				//  create new user
				state,
				closeForm,
				v$,
				userId,
				//  end create new user
			};
		},
	});
