<template>
      <Avatar :initials="params.value" :avatarSize="2.8" v-if="params.value != ''"/>
</template>

<script>
import { defineComponent, ref } from "vue";
import Avatar from '@/components/Avatar.vue';
export default defineComponent({
  name: "BtnCellRenderer",
  components: {Avatar},
  props: {},
  data() {
    return {};
  },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params.value);
    },
    deleteClicked() {
      this.params.deleteClicked(this.params.value);
    },
  },
});
</script>

<style scoped>
.margin-lr {
  margin: 0px 13px 0px 13px;
  /* margin-top: 12.5px; */
}
.margin-r {
  margin: 0px 13px 0px 0px;
  /* margin-top: 12.5px; */
}
i {
  font-size: 15px;
  cursor: pointer;
}
div {
  height: 21px !important;
}
</style>
