
import { defineComponent } from "vue";
import { format } from "date-fns";
import ApiService from "@/core/services/ApiService";
import { DateHelper } from "@/Utilities/DateHelper";
import * as bootstrap from "bootstrap";
import Modal from "@/components/Modal.vue";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";
import { ref, nextTick } from 'vue';

export default defineComponent({
  components: { Modal },
  emits: ['historyChanged'],
  data() {
    return {
      toast: useToast(),
      showTab: "treatments",
      showAddProcedure: false,
      notes: null as any,
      clinicalNotes: null as any,
      clinicalNoteToShow: null as any,
      dateformatOptions: {
        year: "numeric",
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      } as Intl.DateTimeFormatOptions,
      noteModal: null as any,
      clinicalNotesModal: null as any,
      additionalProcedure: null as any,
      additionalProcedureDate: null as any,
      additionalProcedureValidation: null as any,
      templatesHtml: null as any,
      formData : [
            {"label": "t1", "value": "8:45 am (15 min late)"},
            {"label": "t2", "value": "9:30 am"},
            {"label": "t3", "value": "Simona Apostol"},
            {"label": "c1", "value": "True"},
            {"label": "c2", "value": "False"},
            {"label": "c3", "value": "True"}]
    }
  },
  computed: {
    historyDivider: function () {
      let divider = 6;
      let history = this.patientHistory as any;
      let arrays = [] as any;
      let copy = history.slice(); // Create a shallow copy of the array
      
      while (copy.length > 0) {
        arrays.push(copy.slice(0, divider)); // Copy the next segment
        copy = copy.slice(divider); // Update the copy to remove the segment
      }
      
      return { divider: divider, blocks: arrays };
    },
  }
  ,
  props: {
    patientHistory: { type: Object, required: true },
    appointment: { type: Object, required: true },
  },
  methods: {
    popupNotes() {
      console.log("showing modal", this.noteModal);
      this.noteModal.show();
    },
    popupClinicalNotes(clinicalNote) {
      this.clinicalNoteToShow = clinicalNote;
      console.log("showing", this.clinicalNoteToShow);
      this.clinicalNotesModal.show();

      nextTick(() => {
        this.populateClinicalNotes(this.clinicalNoteToShow.noteData);
      });
    },
    populateClinicalNotes(noteData){
      var note = JSON.parse(noteData)
      note.forEach(item => {
          const element = document.querySelector(`[name="${item.label}"]`) as any;
          if (element) {
              if (element.type === "checkbox") {
                  element.checked = item.value === "True";
              } else {
                  element.value = item.value;
              }
          } else {
              console.log("element not found", item.label);
          }
      });
    },
    
    newNote() {
      window.open(`${this.appointment.appointmentId}/NewNote?in=${this.appointment.patientInitials}`)
    },
    newProcedure() {
      this.showAddProcedure = true;
    },
    async addProcedure(){
      //actually have to do something here
      if (!this.additionalProcedure){
        this.additionalProcedureValidation = "Please select procedure";
        return;
      }
      if (!this.additionalProcedureDate){
        this.additionalProcedureValidation = "Please select valid date";
        return;
      }
      this.additionalProcedureValidation = null;

      let payload = { dateOfProcedure: this.additionalProcedureDate, adaCode : this.additionalProcedure } as any;
      let res = await ApiService.post(`appointment/ProcedureHistory/${this.appointment.patientId}`, payload)
      if(res.status !== 200 && res.status !== 204){
          this.toast.error(codes.error)
        }
        else{
          //this.toast.success("Procedure was added to history");
          //have to refresh this page somehow
          this.$emit("historyChanged");
        }
      this.showAddProcedure = false;
    },
    async removeHistory(proc){
      let payload = { dateOfProcedure: proc.date, adaCode : proc.adaCode } as any;
      let res = await ApiService.post(`appointment/ProcedureHistoryDelete/${this.appointment.patientId}`, payload)
      if(res.status !== 200 && res.status !== 204){
          this.toast.error(codes.error)
        }
        else{
          //this.toast.success("Procedure was added to history");
          //have to refresh this page somehow
          this.$emit("historyChanged");
        }
    },
    
    switchToTreatments() {
      this.showTab = "treatments";
    },
    async switchToNotes() {
      this.showTab = "notes";
      if (this.notes === null) {
        let res = await ApiService.get(`appointment/patientNote/${this.appointment.patientId}`,);
        this.notes = res.data;
      }
    },
    async switchToForms() {
      this.showTab = "clinicalForms";
      if (this.clinicalNotes === null) {
        
        //this.rawHtml = '<div>START TIME: <input name="t1" type="text" maxlength="400" class="text" style="width:185px"/></div>'
        //this.clinicalNotes = [{ note: this.rawHtml, dateCreated: new Date('2024-01-01'), providerName: "Mark" }, { note: this.rawHtml, dateCreated: new Date('2024-01-02'), providerName: "Mark" }];
        let templatesRes = await ApiService.get(`note/DmsTemplate`);
        console.log(templatesRes, "templatesRes");    
        //store as dictionary of templateId and actual html
        this.templatesHtml = templatesRes.data.reduce((acc, template) => {
          acc[template.dmsTemplateId] = template.templateHtml;
          return acc;
        }, {});   
        console.log(this.templatesHtml, "templatesHtml");     
        
        let res = await ApiService.get(`note/patient/${this.appointment.patientId}/ClinicalFormsData`);        
        this.clinicalNotes = res.data;        
      }
    },
    formatDate(date: Date) {
      //show date as 2021-Jan-01 without time
      return format(date, 'yyyy-MMM-dd');
      
    },
    dateSubtract(date1, date2) {
      const years = date1.getYear() - date2.getYear();
      const diff = date1 - date2;
      const diffInDays = Math.floor(diff / 1000 / 3600 / 24);
      const diffInMonths = Math.floor(diffInDays / 30);
      const remainder = diffInDays - diffInMonths * 30;
      return `${diffInMonths > 0 ? diffInMonths + " mths, " : ""
        } ${remainder} days ago`;
    },
    dateFormat(date, formatString) {
      return format(date, formatString);
    },
    getMonthDifference(appointmentDate, procDate) {
      var subtractLastMonth;
      if (appointmentDate.getDate() > procDate.getDate()) {
        subtractLastMonth = 0;
      } else {
        subtractLastMonth = 1;
      }

      const diffMonths =

        ((appointmentDate.getMonth() - subtractLastMonth) - procDate.getMonth()) +
        (12 * (appointmentDate.getFullYear() - procDate.getFullYear()))

      if (diffMonths === 0) {
        return this.dateSubtract(appointmentDate, procDate)
      } else {
        return `${diffMonths == 1 ? diffMonths + " month" : diffMonths + " months"} ago`;
      }
    }
  },
  mounted() {
    var modalElement = document.getElementById("noteModal");
    this.noteModal = new bootstrap.Modal(modalElement);

    var modalElement = document.getElementById("clinicalNotesModal");
    this.clinicalNotesModal = new bootstrap.Modal(modalElement);
  }
});
