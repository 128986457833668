

import { defineComponent } from "vue";

export default defineComponent({
    name: "ProcedurePlanOmissionModal",
    components: {  },
    props: {
        procedureRuleTypeId: { type: Number, required: false },
        missedProcedureReasonId: {type: Number, required: false},
        missedProcedureReasonText: {type: String, required: false},        
    },
    data() {
        return {
            selectedOption: '1' as String,
            otherReason: '' as String | null,
        }
    },
    methods: {
       clearReason(){
           this.otherReason = '';
       }
    },
    mounted() {
       this.selectedOption = this.missedProcedureReasonId?.toString() ?? '1';
       this.otherReason = this.missedProcedureReasonText ?? null;
    }

});

