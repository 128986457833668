
import { defineComponent  } from "vue";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { AgendaItemDetail, AgendaItem, getProductionCalculationTypeStrategies, EditMode, TimeMode, FeeMode, ProcedureRuleType } 
    from "@/ProcedureRules/ProductionCalculationType";
import { ProcedureCalculator } from '@/components/Calendar/AppointmentComponents/ProcedureCalculator';
import { table } from "table";
import odspCriteria  from '@/ProcedureRules/OdspCriteria.json';
import { IProviderDataContract, IClinicDataContract, ProviderTypeEnum } from "@/Models/data-contract/index";  
import { ElPopover } from 'element-plus';
import AlertComponent from "@/components/atoms/Alert.vue";


export default defineComponent({    
    data() {
        
        return {
            
        };
    },
    components: {AlertComponent}, 
    props: {
        scalingComponents: { type: Array<AgendaItem>, required: true },
        scalingItem: { type: Object, required: true },        
    },
});
