<template>
	<div :class="`pr-0 ${permission ? 'col-12 col-sm-12 col-md-6 col-lg-6' : 'col-12 pl-0'} custom-header`">
		<div :class="`card p-5 h-100 ${remainingTime.hours + remainingTime.minutes + remainingTime.seconds > 0 ? 'orange-white-background' : checkApplicationTimeLimit() !== '' ? 'red-white-background' : 'green-white-background'} d-flex  justify-content-center`">
			<div class="row px-7 d-flex align-items-center justify-content-center">
				<div class="col-4">
					<div>
						<span class="fs-8 fw-bold text-gray-400 w-100 color-white">Applicable Time</span>
						<div class="d-flex mb-2">
							<span class="fs-2hx fw-bolder text-gray-800 me-2 lh-1 color-white">{{ convertDifferenceInMinutesToHumanReadableFromMinutes(timeUsed) }}</span>
						</div>
					</div>
				</div>
				<div class="col-4 d-flex justify-content-center middle-card-indicator py-2">
					<div class="text-center">
						<div class="d-flex mb-2">
							<span class="fs-2hx fw-bolder text-gray-800 me-2 lh-1 color-orange">{{ convertDifferenceInMinutesToHumanReadable(remainingTime) }}</span>
						</div>
						<span class="fs-8 fw-bold color-orange">Remaining Time</span>
					</div>
				</div>
				<div class="col-4 d-flex flex-row-reverse">
					<div class="row align-items-center">
						<div class="col">
							<span class="fs-8 fw-bold text-gray-400 w-100">Appointment Time</span>
							<div class="d-flex mb-2">
								<span class="fs-2hx fw-bolder text-gray-800 me-2 lh-1">{{ diffsInMinutes(appointment.startDate, appointment.endDate) }}<span class="fs-1">mins</span> </span>
							</div>
						</div>
						<div class="col">
							<inline-svg src="/media/material-access-time.svg" class="" width="100%" />
						</div>
					</div>
				</div>
				<!-- <div class="col-2"></div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import { differenceInMinutes, intervalToDuration } from "date-fns";

	export default {
		components: {},
		data() {
			return {};
		},
		props: {
			appointment: { type: Object, required: true },
			permission: { type: Boolean, required: true },
			timeUsed: { type: Number, required: true },
		},
		computed: {
			remainingTime: function () {
				//let duration = this.timeUsed;
				//let time = duration.hours * 60 + duration.minutes + duration.seconds / 60;
				let time = this.appointment.durationInMinutes -  this.timeUsed;
				time = time < 0 ? 0 : time;
				const durationString = intervalToDuration({
					start: 0,
					end: time * 60 * 1000,
				});
				return durationString;
			},
		},
		methods: {
			checkApplicationTimeLimit() {
				return this.timeUsed > this.diffsInMinutes(this.appointment.startDate, this.appointment.endDate) ? "(Time limit exceeded)" : "";
			},
			diffsInMinutes(start, end) {
				return differenceInMinutes(new Date(end), new Date(start));
			},
			convertDifferenceInMinutesToHumanReadable(diff) {
				const hours = diff.hours < 10 ? (diff.hours === 0 ? null : "0" + diff.hours) : diff.hours;
				const minutes = diff.minutes < 10 ? (diff.minutes === 0 ? "00" : "0" + diff.minutes) : diff.minutes;
				const seconds = diff.seconds < 10 ? (diff.seconds === 0 ? "00" : "0" + diff.seconds) : diff.seconds;
				return `${hours ? hours + ":" : ""}${minutes ? minutes + ":" : ""}${seconds ? seconds : ""}`;
			},
			convertDifferenceInMinutesToHumanReadableFromMinutes(min) {
				const diff = intervalToDuration({
					start: 0,
					end: min * 60 * 1000,
				});


				const hours = diff.hours < 10 ? (diff.hours === 0 ? null : "0" + diff.hours) : diff.hours;
				const minutes = diff.minutes < 10 ? (diff.minutes === 0 ? "00" : "0" + diff.minutes) : diff.minutes;
				const seconds = diff.seconds < 10 ? (diff.seconds === 0 ? "00" : "0" + diff.seconds) : diff.seconds;
				return `${hours ? hours + ":" : ""}${minutes ? minutes + ":" : ""}${seconds ? seconds : ""}`;
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/customStyle/style.scss";
	.orange-white-background {
		background: $orange;
		background: linear-gradient(90deg, $orange 50%, rgba(255, 255, 255, 1) 50%);

		.middle-card-indicator {
			background: white;
			border: 0.5px solid $orange;
			border-radius: 50px;
		}
	}
	.green-white-background {
		background: $green;
		background: linear-gradient(90deg, $green 50%, rgba(255, 255, 255, 1) 50%);
		.middle-card-indicator {
			background: white;
			border: 0.5px solid $green;
			border-radius: 50px;

			.color-orange {
				color: $green !important;
			}
		}
	}
	.red-white-background {
		background: $red;
		background: linear-gradient(90deg, $red 50%, rgba(255, 255, 255, 1) 50%);
		.middle-card-indicator {
			background: rgb(255, 255, 255);
			border: 0.5px solid $red;
			border-radius: 50px;

			.color-orange {
				color: $red !important;
			}
		}
	}
	@media screen and (max-width: 500px) {
		.pr-0 {
			padding: 0px !important;
		}
	}
</style>
