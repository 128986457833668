export class Agenda {
    addAgendaItem() {}
  }
  
  //import RadialChart from "@/components/Charts/RadialChart.vue";
  export class AgendaItem {
    procedureRuleTypeId: number;
    minutes: number;
    quantity: number | null;
    criteria: Array<number> | null;
    agendaItemDetails: Array<AgendaItemDetail>;
    childrenAgendaItems: Array<AgendaItem>;    
  
    name: string | null = null;
    showAdaCodes: boolean = true;
    requestedBy: string | null = null;
    description: string | null = "description"
    editable: boolean = true;
    removable: boolean = true;
    timeEditable: boolean = false;
    timeMode: TimeMode = TimeMode.AsIs;
    feeMode: FeeMode = FeeMode.AsIs;
    show: boolean = true;
    color: string = "yellow";
    fee: number | null = null;
    providerCommission: number | null = null;
    maxMinutes: number | null = null;
    procedure: any | null = null; //only used for legacy interfacing. DO NOT USE
    status: ProcedurePlanResultStatus | null = null;
    // constructor() {
    //     this.adaCodes = [];
    //     this.fee = 0;
    //     this.commission = 0;
    // }
  
    constructor(
      procedureRuleTypeId: number,
      minutes: number,
      quantity: number | null,
      criteria: Array<number> | null,
      procedure: any | null
    ) {
      this.procedureRuleTypeId = procedureRuleTypeId;
      this.minutes = minutes;
      this.quantity = quantity;
      this.criteria = criteria;
      this.agendaItemDetails = new Array<AgendaItemDetail>();
      this.childrenAgendaItems = new Array<AgendaItem>();
      this.procedure = procedure;
      
    }
  }
  export class AgendaItemDetail {
    adaCode: string | null;
    teeth: string | null;
    feeOverride: number | null;
  
    description: string | null;
    procedureRuleTypeId: number | null;

    // constructor() {
    //     this.adaCodes = [];
    //     this.fee = 0;
    //     this.commission = 0;
    // }
  
    constructor(
      adaCode: string | null,
      teeth: string | null,
      feeOverride: number | null
    ) {
      this.adaCode = adaCode;
      this.teeth = teeth;
      this.feeOverride = feeOverride;
      this.description = null;
      this.procedureRuleTypeId = null;
    }
  }
  export interface ProcedureInteractionStrategy {
    addProcedure(agendaItem: AgendaItem, agendaItems: Array<AgendaItem>);
    removeProcedure(procedureRuleTypeId: number);
  }
  
  export class StraightShotInteractionStrategy
    implements ProcedureInteractionStrategy
  {
    addProcedure(agendaItem: AgendaItem, agendaItems: Array<AgendaItem>) {
      agendaItems.push(agendaItem);
    }
    removeProcedure(procedureRuleTypeId: number) {
      throw new Error("Method not implemented.");
    }
  }

export enum TimeMode 
{
    CalculateFromFee = 1,
    CalculateFromRemaining = 2, // scaling
    AsIs = 3
}

export enum ProcedurePlanResultStatus {
    Unknown = 0,
    Missed = 1,
    Partial = 2,
    Completed = 3,
    Exceeded = 4,
    MissedWithReason = 5
}

export enum FeeMode
{
    AsIs = 1,
    CalculatePerUnitOfTime = 2,
    CalculatePerMinute = 3    
}

export enum ProductionCalculationType 
{
    PerAdaCode = 1, //simple 1 to 1
    PerUnitOfTime = 2, //simple 1 to 1
    PerMinute = 3, //simple 1 to 1
    Scaling = 4, // 1 to 1 with agendaitem but combines multiple into 1 ada code and scaling itself acts as parent
    Sealants = 5, // 1 to 1 with AgendaItem but splits into multiple ada codes 
    JustTime = 6,
    PAs = 7, // 1 to 1 with AgendaItem but has parent and children grouping. Also combines multiple into 1 ada codes 
    NoTimeNoCharge = 8
}

export enum EditMode
{
    ReadOnly = 1,
    LiveCalculate = 2
}

export function getProductionCalculationTypeStrategy(productionCalculationType: ProductionCalculationType): ProductionCalculationTypeStrategy {
    switch(productionCalculationType){
        case ProductionCalculationType.PerAdaCode: {
            return new PerAdaCodeStrategy();
            break;
        }
        case ProductionCalculationType.PerUnitOfTime: {
            return new PerUnitOfTimeStrategy();
            break;
        }
        case ProductionCalculationType.PerMinute: {
            return new PerMinuteStrategy();
            break;
        }
        case ProductionCalculationType.Scaling: {
            return new ScalingStrategy();
            break;
        }
        case ProductionCalculationType.Sealants: {
            return new SealantsStrategy();
            break;
        }
        case ProductionCalculationType.JustTime: {
            return new JustTimeStrategy();
            break;
        }
        case ProductionCalculationType.PAs: {
            return new PAsStrategy();
            break;
        }
        case ProductionCalculationType.NoTimeNoCharge: {
            return new NoTimeNoChargeStrategy();
            break;
        }

    }
}

export function getProductionCalculationTypeStrategies(): Array<ProductionCalculationTypeStrategy> {
    return Array<ProductionCalculationTypeStrategy>(
        new PerAdaCodeStrategy(),new PerUnitOfTimeStrategy(),new PerMinuteStrategy(),new ScalingStrategy(),new SealantsStrategy()
        ,new JustTimeStrategy(),new PAsStrategy(),new NoTimeNoChargeStrategy()
    )    
}

export abstract class ProductionCalculationTypeStrategy {
    abstract calculationType: ProductionCalculationType
    abstract calculateTimeFromFee: boolean
    abstract feeMode: FeeMode

    getAgendaItemsFromSavedProcedures(procedureRulesTypes : Map<number, ProcedureRuleType>, savedProcedures) : Array<AgendaItem> {        
        const agendaItems = Array<AgendaItem>();
        const proceduresForThisCalculationType = savedProcedures.filter(p => p.calculationType === this.calculationType);

        //get children 
        // const childIds = new Array<number>();
        // for(const s of proceduresForThisCalculationType){
        //     const procRule = procedureRulesTypes[s.procedureRuleTypeId];
        //     if (procRule.ChildProcedureRuleTypeId) {
        //         childIds.push(procRule.ChildProcedureRuleTypeId);
        //     }
        // }

        //const children = savedProcedures.filter(p => p.calculationType === this.calculationType && )

        for(const s of proceduresForThisCalculationType){
            const procRule = procedureRulesTypes.get(s.procedureRuleTypeId);
            if (!procRule){                
                throw new Error("Procedure Rule Type not found for id: " + s.procedureRuleTypeId);
            }
            // if (childIds.includes(s.procedureRuleTypeId)){ // then this is a child. don't show it as top level task
            
            // } else {
                const a = new AgendaItem(s.procedureRuleTypeId, s.initialMinutes ?? 2, s.quantity, s.selectedCriteria, s);                
                a.name = procRule.name;
                a.color = procRule.procedureGroupColor;
                a.requestedBy = s.requestedBy;
                if (s.status){
                    a.status = s.status
                }
                
                //get fee somehow
                // for sealants hack
                if (s.procedureOptions && s.procedureOptions.length > 0){
                    let fee = 0;
                    let providerCommission = 0;
                    for(const p of s.procedureOptions){
                        fee += p.ammount;
                        providerCommission += p.ammount * p.commissionPercentage;
                    }
                    a.fee = fee;
                    a.providerCommission = providerCommission; 
                }
                a.timeEditable = this.getTimeEditable(procRule);
                a.timeMode  = this.getTimeMode(procRule);
                if (a.timeMode === TimeMode.CalculateFromFee){
                    a.minutes = s.initialMinutes ?? -1;
                }
                a.feeMode  = this.getFeeMode(procRule);                
                for(const d of s.procedureOptions){
                    const ad = new AgendaItemDetail(d.adaCode, Array.isArray(d.teeth) ? d.teeth[0] : d.teeth,d.feeOverride);
                    ad.description = d.name;
                    
                    a.agendaItemDetails.push(ad);
                }

                // if (procRule.ChildProcedureRuleTypeId) {
                //     //find the children if they exists
                //     for(const child of proceduresForThisCalculationType.filter(s => s.procedureRuleTypeId === procRule.ChildProcedureRuleTypeId)){
                //         const childProcRule = procedureRulesTypes[s.procedureRuleTypeId];
                //         const ad = new AgendaItemDetail(null, null,null);
                //         ad.description = childProcRule.name;                    
                //         a.agendaItemDetails.push(ad);
                //     }
                // }
                agendaItems.push(a);
            //}
        }
        return agendaItems
    }

    getTimeEditable(procedureRuleType){
        return true;// !this.calculateTimeFromFee;
    }
    getTimeMode(procedureRuleType): TimeMode {
        return this.calculateTimeFromFee? TimeMode.CalculateFromFee : TimeMode.AsIs;
    }
    getFeeMode(procedureRuleType): FeeMode {
        return this.feeMode;
    }
 
}

export class PerAdaCodeStrategy extends ProductionCalculationTypeStrategy
{    
    calculationType: ProductionCalculationType = ProductionCalculationType.PerAdaCode
    calculateTimeFromFee: boolean = true;
    feeMode: FeeMode = FeeMode.AsIs;
    
    getAgendaItemsFromSavedProcedures(procedureRulesTypes : Map<number,ProcedureRuleType>, savedProcedures) : Array<AgendaItem> {
        const agendaItems = super.getAgendaItemsFromSavedProcedures(procedureRulesTypes, savedProcedures);
        // find probe savedProcedures.filter
        //add it to child of exam
        return agendaItems;
    }
}

export class PerUnitOfTimeStrategy extends  ProductionCalculationTypeStrategy
{    
    calculationType: ProductionCalculationType = ProductionCalculationType.PerUnitOfTime
    calculateTimeFromFee: boolean = false;    
    feeMode: FeeMode = FeeMode.CalculatePerUnitOfTime;
} 

export class PerMinuteStrategy extends  ProductionCalculationTypeStrategy
{
    calculationType: ProductionCalculationType = ProductionCalculationType.PerMinute
    calculateTimeFromFee: boolean = false;    
    feeMode: FeeMode = FeeMode.CalculatePerMinute;
} 

export class ScalingStrategy extends  ProductionCalculationTypeStrategy
{
    calculationType: ProductionCalculationType = ProductionCalculationType.Scaling
    calculateTimeFromFee: boolean = false;    
    feeMode: FeeMode = FeeMode.CalculatePerUnitOfTime;

    getTimeEditable(procedureRuleType){
        return procedureRuleType.procedureRuleTypeId === 18 ? false : super.getTimeEditable(procedureRuleType)
    }

    getTimeMode(procedureRuleType): TimeMode {
        return procedureRuleType.procedureRuleTypeId === 18 ? TimeMode.CalculateFromRemaining : super.getTimeMode(procedureRuleType)
    }
} 

export class SealantsStrategy extends  ProductionCalculationTypeStrategy
{
    calculationType: ProductionCalculationType = ProductionCalculationType.Sealants
    calculateTimeFromFee: boolean = true;    
    feeMode: FeeMode = FeeMode.AsIs;
} 

export class JustTimeStrategy extends  ProductionCalculationTypeStrategy
{
    calculationType: ProductionCalculationType = ProductionCalculationType.JustTime
    calculateTimeFromFee: boolean = false;    
    feeMode: FeeMode = FeeMode.AsIs;
} 

export class PAsStrategy extends  ProductionCalculationTypeStrategy
{
    calculationType: ProductionCalculationType = ProductionCalculationType.PAs
    calculateTimeFromFee: boolean = true;    
    feeMode: FeeMode = FeeMode.AsIs;
} 

export class NoTimeNoChargeStrategy extends  ProductionCalculationTypeStrategy
{
    calculationType: ProductionCalculationType = ProductionCalculationType.NoTimeNoCharge
    calculateTimeFromFee: boolean = false;
    feeMode: FeeMode = FeeMode.AsIs;
}

export interface ClinicProcedureRule {
    procedureRuleTypeId: number;
    rules: ClinicProcedureRuleDetails[];
}

export interface ClinicProcedureRuleDetails {
    procedureRuleId: number | null;
    intervalInMonths: number | null;
    minAge: number | null;
    maxAge: number | null;
    active: boolean;
    defaultInMinutes: number | null;
}


export enum AgeRange {
    Children = 1,
    Adults = 2,
    Under6 = 3
}

export interface ProcedureRuleType {
    procedureRuleTypeId: number;
    name: string;
    isInterval: boolean;
    isAge: boolean;
    dDefaultMinutes: boolean;
    procedureGroupColor: string;
    appliesToOdsp: boolean;
    options: string | null;
    ageRange: AgeRange | null;
    calculationType: ProductionCalculationType;
    scheduleOrderBeginning: number | null;
    scheduleOrderEnd: number | null;
    ruleValues: ClinicProcedureRule | null;
    childProcedureRuleTypeId: number | null;
    isOutsideAppointment: boolean;  
    isWithScaling: boolean;
    includeWithScaling : boolean;
  }