// import "ant-design-vue/dist/antd.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import KTAside from "@/layout/aside/Aside.vue";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import "@/core/plugins/keenthemes";
import ApiService from "@/core/services/ApiService";
import "bootstrap";
import { Store } from "vuex";
import { VueAuth } from './auth/VueAuth'
import { getInstance } from './auth/auth'
// import Antd from 'ant-design-vue';
import VueApexCharts from "vue3-apexcharts";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "@/assets/customStyle/style.scss"
import GoatNotesService from "./core/services/GoatNotesService";
import { registerLicense } from '@syncfusion/ej2-base';


/* register syncfusion license */
registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdkBiWXxacXVWRGVV')


//import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        //$msalInstance: PublicClientApplication;    
        $auth: VueAuth,
        //$currentAccount: AccountInfo;
        $store: Store<boolean>

    }
}
const app = createApp(App);
const toastOptions = {
    position: "top-center",
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
}

let environment = "Dev";
if (window.location.href.includes("app.rna180.ca")) {
    environment = "Production"
} else if (window.location.href.includes("victorious-water")){
    environment = "PR"
}

//app.config.globalProperties.$msalInstance = new PublicClientApplication(store.state.msalConfig,);
//app.config.globalProperties.$currentAccount = null;
app.config.globalProperties.$auth = getInstance();
ApiService.init(app);
GoatNotesService.init();

app.use(store);
app.use(router);
app.use(ElementPlus);
initInlineSvg(app);
app.use(i18n);
app.use(VueApexCharts);
app.use(Toast, toastOptions);
// app.use(Antd);
app.mount("#app");
