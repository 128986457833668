<template>
	<div class="card">
		<div class="row ">
			<div class="col-12 pt-5 d-flex justify-content-start align-items-center">
				<div class="d-flex justify-content-start">

					<div class="d-flex align-items-center grey-bg py-2 px-7">
						<div class="symbol symbol-50px me-5">
							<i class="fas fa-calendar-alt fs-30-px mx-2" style="color: #009ef7;"></i>
						</div>
						<div class="flex-grow-1 text-start">
							<span class="text-muted d-block fw-bold">Start</span>
							<input type="date" name="xstart" class="form-control border-none p-0" v-model="startDate" />
						</div>
					</div>
					<div class="d-flex align-items-center grey-bg py-2 px-7">
						<div class="symbol symbol-50px me-5">
							<i class="fas fa-calendar-alt fs-30-px mx-2" style="color: #009ef7;"></i>
						</div>
						<div class="flex-grow-1">
							<span class="text-muted d-block fw-bold">End</span>
							<input type="date" name="end" class="form-control border-none p-0" v-model="endDate" />
						</div>
					</div>
					<!-- <div v-if="this.$store.getters.currentUser.clinics.length > 1" class="d-flex align-items-center grey-bg py-2 px-7 form-check form-switch form-check-custom form-check-solid">						
						<input class="form-check-input" type="checkbox" id="comparisonToggle" @change="compareToggleClicked"/>
						<label class="form-check-label" for="comparisonToggle">
							Compare clinics
						</label>
					</div>					 -->
				</div>
				<button class="btn btn-primary mx-10" @click="getAllData" style="width: fit-content;">
					Generate
				</button>
			</div>
		</div>
		<div class="row px-10 h-100  pt-10">
			<ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5">
				<li class="nav-item">
					<a class="nav-link active" data-bs-toggle="tab" @click="switchChartTabs(1)">RNA Usage by Provider</a>
				</li>				
				<li class="nav-item">
					<a class="nav-link" data-bs-toggle="tab" @click="switchChartTabs(2)">RNA Usage by User</a>
				</li>				
			</ul>
		</div>
		<RnaUsage v-if="chartSwitchedTo == 1" :startDate="startDate" :endDate="endDate" :comparisonToggle="comparisonToggle" :key="rnaUsageKey"></RnaUsage>		
		<RnaUsageByUser v-if="chartSwitchedTo == 2" :startDate="startDate" :endDate="endDate" :comparisonToggle="comparisonToggle" :key="rnaUsageByUserKey"></RnaUsageByUser>		
	</div>
</template>
<script>
import ApiService from "@/core/services/ApiService";
import { format } from "date-fns";
import BarChart from "@/components/Charts/BarChart.vue";
import LineChart from "@/components/Charts/LineChart.vue";
import ProviderProductivity from "@/components/Charts/ProviderProductivity.vue";
import ClinicProductivity from "@/components/Charts/ClinicProductivity.vue";
import RebookingPerformance from "@/components/Charts/RebookingPerformance.vue";
import RnaUsage from "@/components/Charts/RnaUsage.vue";
import RnaUsageByUser from "@/components/Charts/RnaUsageByUser.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { codes } from "@/Codes/Codes";
import { useToast } from "vue-toastification";
import { DateHelper } from "@/Utilities/DateHelper";

export default {
	props: {
		series: { type: Array },
		categories: { type: Array },
	},
	components: { ProviderProductivity, ClinicProductivity,  RebookingPerformance, RnaUsage, RnaUsageByUser},

	data: function () {

		let {startingStartDate, startingEndDate} = DateHelper.getStartingIntervalDates();


		return {
			toast: useToast(),
			providerProductivityKey: 1,
			clinicProductivityKey: 1,
			rebookingPerformanceKey: 1,
			rnaUsageKey: 1,
			rnaUsageByUserKey: 1,
			startDate: startingStartDate.toISOString().split("T")[0],
			endDate: startingEndDate.toISOString().split("T")[0],
			chartSwitchedTo: 1,
			comparisonToggle: false,			
		};
	},
	methods: {
		compareToggleClicked(e){
			this.comparisonToggle = e.target.checked;
		},
		switchChartTabs(number) {
			this.chartSwitchedTo = number;
		},
		getAllData() {			
			this.rnaUsageKey++;
			this.rnaUsageByUserKey++;
		},
	},

	mounted() {
		setCurrentPageTitle("Charts");		
	},
};
</script>
