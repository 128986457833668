<template>
  <div class="btn btn-icon btn-active-light-primary">
    <inline-svg
    class="fullScreen"
      src="/media/fullscreen.svg"
      @click="toggleFullScreen"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";

export default defineComponent({
  methods: {
    toggleFullScreen() {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
      if (this.isFullScreenCurrently()) {
        this.closeFullscreen();
      }
    },
    isFullScreenCurrently() {
      var full_screen_element =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        null;

      // If no element is in full-screen
      if (full_screen_element === null) return false;
      else return true;
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.fullScreen{
  width: 2.5rem;
  color: #a1a6b6;
  &:hover{
    color: inherit;
  }
}
</style>