<template>
	<td>
		<p v-if="(task.calculationType !== 2 && task.calculationType !== 3 && task.calculationType !== 4) || task.procedureRuleTypeId === 18">
			{{ calculateOptedCharge(task, null, true) == 0 ? "-" : `${calculateOptedCharge(task, null, true) ? "$" + calculateOptedCharge(task, null, true) : "-"}` }}
		</p>
		<p v-if="(task.calculationType === 2 || task.calculationType === 3) && task.calculationType !== 4 && task.initialMinutes && task.procedureRultypeId !== 18">
			{{ calculateOptedCharge(task, null, true) == 0 ? "-" : `${calculateOptedCharge(task, null, true) ? "$" + calculateOptedCharge(task, null, true) : "-"}` }}
		</p>
		<div :class="`${item.show ? 'pl-5 sub-p' : ''}`" v-show="task.calculationType !== 4" v-for="(item, vindex) in task.procedureOptions" :key="`v_${vindex}`">
			<p v-if="item.show && task.calculationType !== 4 && task.calculationType !== 5">
				{{ calculateOptedCharge(task, item, true) == 0 ? "-" : `${calculateOptedCharge(task, item, true) ? "$" + calculateOptedCharge(task, item, true) : "-"}` }}
			</p>
			<p v-if="item.show && task.calculationType === 5">
				{{ `$${item.ammount}` }}
			</p>
		</div>
	</td>
</template>

<script>
	export default {
		props: { task: { type: Object, required: true }, opted: { type: Boolean, required: true } },
		methods: {
			calculateOptedCharge(task, procOption, computed) {
				this.$emit("calculateCharge", task, procOption, computed);
			},
		},
	};
</script>
