
import { defineComponent  } from "vue";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { AgendaItemDetail, AgendaItem, ProductionCalculationType, getProductionCalculationTypeStrategy, 
  ProductionCalculationTypeStrategy, getProductionCalculationTypeStrategies, EditMode, TimeMode, FeeMode, ProcedureRuleType, ProcedurePlanResultStatus} 
    from "@/ProcedureRules/ProductionCalculationType";
import { ProcedureCalculator, CalculatedProcedures } from '@/components/Calendar/AppointmentComponents/ProcedureCalculator';
import { table } from "table";
import { fa } from "element-plus/lib/locale";
import odspCriteria  from '@/ProcedureRules/OdspCriteria.json';
import { DateHelper } from "@/Utilities/DateHelper";
import { AppointmentEndpoints } from "@/core/api/AppointmentEndpoints";
import ProcedurePlanOmissionModal from "@/components/Calendar/AppointmentComponents/ProcedurePlanOmissionModal.vue";
import Modal from "@/components/Modal.vue";
import { useTransitionFallthroughEmits } from "element-plus";

interface root {
  showMessage(message);
}

class CheckListItem {
  public procedureRuleTypeId: number
  public name: string | null;
  public status: ProcedurePlanResultStatus | null;
  public codes: string[];
  public descriptions: string[];
  public ammount: number | null;
  public procedurePlanResult: ProcedurePlanResult | null;
  public missedProcedureReasonText: string | null
  public missedProcedureReasonId: number | null
  public actualMinutes: number | null
  public plannedMinutes: number | null

  /**
   *
   */
  constructor(procedureRuleTypeId) {
    this.procedureRuleTypeId = procedureRuleTypeId;
    this.name = null;
    this.status = null;
    this.codes = new Array<string>();
    this.descriptions = new Array<string>();
    this.ammount = null;
    this.procedurePlanResult = null;
    this.missedProcedureReasonText = null;
    this.missedProcedureReasonId = null;
    this.actualMinutes  = null;
    this.plannedMinutes = null;
  }
}

class ProcedurePlanResult {
  public procedureRuleTypeId: number;
  public name: string;
  public status: ProcedurePlanResultStatus;
  public missedProcedureReason : number | null;
  public missedProcedureReasonText : string | null;
  public plannedCodes: string[];
  public completedCodes: string[];
  public plannedDescriptions: string[];
  public completedDescriptions: string[];
  public plannedAmmount: number | null;
  public completedAmmount: number | null;
  public plannedUnits: number | null;
  public completedUnits: number | null;
  public plannedMinutes: number | null;  
  public actualMinutes: number | null;  

  constructor(
    procedureRuleTypeId: number,
    name: string,
    status: ProcedurePlanResultStatus,
    plannedCodes: string[],
    completedCodes: string[],
    plannedDescriptions: string[],
    completedDescriptions: string[],
    plannedAmmount: number | null,
    completedAmmount: number | null,
    plannedUnits: number | null,
    completedUnits: number | null,
    plannedMinutes: number | null,
    missedProcedureReason : number | null,
    missedProcedureReasonText : string | null,
    actualMinutes : number | null
  ) {
    this.procedureRuleTypeId = procedureRuleTypeId;
    this.name = name;
    this.status = status;
    this.plannedCodes = plannedCodes;
    this.completedCodes = completedCodes;
    this.plannedDescriptions = plannedDescriptions;
    this.completedDescriptions = completedDescriptions;
    this.plannedAmmount = plannedAmmount;
    this.completedAmmount = completedAmmount;
    this.plannedUnits = plannedUnits;
    this.completedUnits = completedUnits;
    this.plannedMinutes = plannedMinutes;
    this.missedProcedureReason = missedProcedureReason;
    this.missedProcedureReasonText = missedProcedureReasonText;
    this.actualMinutes = actualMinutes;
  }
}

export default defineComponent({
  components: { ProcedurePlanOmissionModal,  Modal },
  emits: ['closed', 'saved', 'onToggleDms', 'showPreviewModal', 'markAsOpen'],
  // emits: {
  //   appointmentChanged(payload: {applicableTime: number, totalProduction: number}) {},
  //   editSealants() {}
  // },  
  data() {
    //const agendaItem = new AgendaItem(5, 5, 5, null);
    //agendaItem.agendaItemDetails.push(new AgendaItemDetail("10111","4", null));


    return {            
      checkListItems : new Array<CheckListItem>(),
      proceduresStatus: new Array<ProcedurePlanResult>(),
      procedureRuletypes: new Array<ProcedureRuleType>() as Array<ProcedureRuleType>,        
      showDms: true,
      editMode : EditMode.LiveCalculate,
      toast: useToast(),
      EditMode,        
      TimeMode,
      scalingItem: null as AgendaItem | null,
      paItem: null as AgendaItem | null,
      probeItem: null as AgendaItem | null,
      showDentrix: false as boolean,
      editable: true,
      myOdspCriteria: odspCriteria as any,
      ProcedurePlanResultStatus: ProcedurePlanResultStatus,
      ShowPreview: false,

      displayProcedurePlanOmissionModal: false,
      selectedProcedureRuleTypeId: null as Number | null,
      selectedProcedureName: null as string  | null,
      selectedMissedProcedureReasonId: 1 as Number,
      selectedOtherReason: '' as string | null,      

      selectedTask: null as CheckListItem | null,
      selectedActualMinutes: null as number | null,
      displayActualScalingMinutesModal: false
    };
  },
  props: {
    appointment: { type: Object, required: true },
    procedureRuleTypeOptions: {type: Object, required: true},
    showToggleDms: {type: Boolean, required: true},
    hasPlan: {type: Boolean, required: true},
    appointmentIsComplete: {type: Boolean, required: true}   
  },
  methods: {      
    getTextReason(missedProcedureReasonId : Number | null){
      if (!missedProcedureReasonId) {
        return "Other";
      }

      let reason = "";
      switch(missedProcedureReasonId)
      {
        case 1:
          reason = 'Patient declined';
          break;
        case 2:
          reason = 'Ran out of time';
          break;
        case 3:
          reason = 'Treatment plan changed';
          break;
        case 4:
          reason = "Doctor wasn't available";
          break;
        case 255:
          reason = 'Other';
          break;
        default:
          reason = 'Other';
          break;
      }
      return reason;
    },
    generateNotes() {

      let shouldShowAmount = this.$store.getters.currentClinicInfo.shouldShowAmountInNotes ?? true;
      

      //console.log("appt", this.appointment, this.agendaItems, this.appointment.inferredRecommendations);
      let firstItems = this.appointment.selectedRecommendations.filter(r => r.adaCode === null && r.scheduleOrderBeginning !== null)
      DateHelper.sortArrayByProperty(firstItems, "scheduleOrderBeginning", "asc");
      let lastItems = this.appointment.selectedRecommendations.filter(r => r.adaCode === null && r.scheduleOrderEnd !== null)
      DateHelper.sortArrayByProperty(lastItems, "scheduleOrderEnd", "asc");
      let tableCompleted = [] as any[];
      let tableMissed  = [] as any[];
      
      for (const item of firstItems) {					
        let body = {            
            service: `${item.name}`,            
            time: `${item.initialMinutes.toFixed(0)}`,
          };          
          tableCompleted.push(`${body.service}${(body.time ?? "0") === "0" ? "" : ", " + body.time + " min" }`);
      }

      for (const rec of this.proceduresStatus) {	
	      let correctedPlannedMinutes = rec.plannedMinutes
        let checkListItem = this.checkListItems.find(c => c.procedureRuleTypeId === rec.procedureRuleTypeId);
        if (checkListItem  && checkListItem.actualMinutes){
          correctedPlannedMinutes = checkListItem.actualMinutes;
        } else {
          if (rec.completedUnits){
            let addtionalMinutes = 0
            if (rec.procedureRuleTypeId === 18) { //scaling
              addtionalMinutes = this.appointment.selectedRecommendations.filter(r => r.calculationType === 4 && r.procedureRuleTypeId !== 18)
                .reduce((a,b) => a + b.initialMinutes,0);
                            
              if (rec.plannedMinutes && (rec.plannedMinutes + addtionalMinutes) > rec.completedUnits * 15){
                correctedPlannedMinutes = (rec.completedUnits * 15) - addtionalMinutes;
              } else if(rec.plannedMinutes && rec.plannedMinutes + addtionalMinutes < (rec.completedUnits * 15) - 7.5){
                correctedPlannedMinutes = ((rec.completedUnits * 15) - 7.5) - addtionalMinutes;
              }
            }
          }				
        }
        let body = {
          code: rec.completedCodes.map(d => d).join(", "),
          service: `${rec.name}`,
          selectedService: rec.completedDescriptions.length > 0 ? `(${rec.completedDescriptions[0]})` : '',
          ammount: rec.completedAmmount === null ? "" : rec.completedAmmount.toFixed(0),
          time: `${correctedPlannedMinutes}`,
        };          
        if (rec.status > 1){
          tableCompleted.push(`${body.code ? body.code + ' - ' : ""}${body.service}${(body.time ?? "0") === "0" ? "" : ", " + body.time + " min" }${body.ammount && shouldShowAmount ? ", " + body.ammount + "$" : ""}`);
        } else {
          let missedReasonText = ""
          if (checkListItem && checkListItem.missedProcedureReasonId){
            missedReasonText = checkListItem.missedProcedureReasonId === 255 ? checkListItem.missedProcedureReasonText ?? "" : this.getTextReason(checkListItem.missedProcedureReasonId);
          }
          tableMissed.push(`${body.code ? body.code + ' - ' : ""}${body.service} - ${missedReasonText}`);
        }
        
      }			

      // for (const agendaItem of this.agendaItems.filter(a => (a.status ?? 0) > 1)) {					
      //   let body = {
      //     code: agendaItem.agendaItemDetails.map(d => d.adaCode).join(", "),
      //     service: `${agendaItem.name}`,
      //     selectedService: agendaItem.agendaItemDetails.length > 0 ? `(${agendaItem.agendaItemDetails[0].description})` : '',
      //     ammount: agendaItem.fee === null ? "" : agendaItem.fee == 0 ? "" : agendaItem.fee?.toFixed(0),
      //     time: `${agendaItem.minutes.toFixed(0)}`,
      //   };          
      //   tablex.push(`${body.code ? body.code + ' - ' : ""}${body.service}${(body.time ?? "0") === "0" ? "" : ", " + body.time + " min" }${body.ammount ? ", " + body.ammount + "$" : ""}`);					
      // }			
      
      for (const item of lastItems) {					
        let body = {            
            service: `${item.name}`,            
            time: `${item.initialMinutes.toFixed(0)}`,
          };          
          tableCompleted.push(`${body.service}${(body.time ?? "0") === "0" ? "" : ", " + body.time + " min" }`);
      }
				let finalString = "********** Completed Procedures **********";
				for (let i = 0; i < tableCompleted.length; i++) {
					const row = tableCompleted[i];
					finalString += `\n${row}`;
				}
        if (tableMissed.length > 0){
          finalString += "\n********** Planned but not completed **********"
          for (let i = 0; i < tableMissed.length; i++) {
            const row = tableMissed[i];
            finalString += `\n${row}`;
          }
        }
				console.log(finalString);
				navigator.clipboard.writeText(finalString);
				((this.$root as unknown) as root).showMessage("Notes have been copied to the clipboard!");
			},
    onCloseClicked(){
      this.$emit("closed");
    },  
    onPreviewClicked(){
      this.$emit("showPreviewModal");
    },
    // click event for displaying the procedure plan omission modal  
    onProcedurePlanOmissionClicked(task: CheckListItem) {
      this.selectedProcedureRuleTypeId = task.procedureRuleTypeId;
      this.selectedProcedureName = task.name;
      this.selectedMissedProcedureReasonId = task.missedProcedureReasonId ?? 1;
      this.selectedOtherReason = task.missedProcedureReasonText ?? null;
      this.displayProcedurePlanOmissionModal = true;
    }, 
    onAddTimeClicked(task: CheckListItem){      
      this.selectedTask = task;
      this.selectedActualMinutes = task.actualMinutes ?? task.plannedMinutes;
      this.displayActualScalingMinutesModal = true;
    },
    formatTime(minutes: number){
      return minutes;
    },
    async saveProcedurePlanOmissionModal() {
      if (!this.selectedProcedureRuleTypeId){
        return;
      }
      let selectedOtherReason = (this as any).$refs.procedurePlanOmissionModal.otherReason;
      let selectedOption = (this as any).$refs.procedurePlanOmissionModal.selectedOption;

      if(selectedOption === '255' && selectedOtherReason === '')	{
        this.toast.error("Please provide a reason");
      }
      else
      {
        let procedurePlanToUpdate = this.checkListItems.find(p => p.procedureRuleTypeId === this.selectedProcedureRuleTypeId);
        if (procedurePlanToUpdate){
          procedurePlanToUpdate.missedProcedureReasonId = Number(selectedOption);
          procedurePlanToUpdate.missedProcedureReasonText = selectedOption === "255" ? selectedOtherReason : null

          let data = {
            actualMinutes: procedurePlanToUpdate.actualMinutes,
            missedProcedureReasonId: procedurePlanToUpdate.missedProcedureReasonId,
            missedProcedureReasonText: procedurePlanToUpdate.missedProcedureReasonText
          };
        
          let response = await AppointmentEndpoints.SaveAppointmentExtraInfo(this.appointment.appointmentId, this.selectedProcedureRuleTypeId, data);
          
          if (response.status === 200) {
            this.closeProcedurePlanOmissionModal();          
          }
          else {
            this.toast.error("Error saving procedure plan omission");
            console.log(response);
          }
        }
      }
    },
    async saveActualScalingMinutesModal() {        
      if (!this.selectedTask){
        return;
      }
      this.selectedTask.actualMinutes = this.selectedActualMinutes;
      let data = {
        actualMinutes: this.selectedTask.actualMinutes,
        missedProcedureReasonId: this.selectedTask.missedProcedureReasonId,
        missedProcedureReasonText: this.selectedTask.missedProcedureReasonText
      };
    
      let response = await AppointmentEndpoints.SaveAppointmentExtraInfo(this.appointment.appointmentId, this.selectedTask.procedureRuleTypeId, data);
      
      if (response.status === 200) {
        this.closeActualScalingMinutesModal();          
      }
      else {
        this.toast.error("Error saving scaling minutes");        
      }
      
    },
    closeProcedurePlanOmissionModal() {
      // close modal
      this.displayProcedurePlanOmissionModal = false;
    },
    closeActualScalingMinutesModal() {
      // close modal
      this.displayActualScalingMinutesModal = false;
    }
  },

  async mounted() {
     try {                        
        this.proceduresStatus = this.appointment.procedurePlanResults as Array<ProcedurePlanResult>;        
        for(let p of this.proceduresStatus){
          let item = new CheckListItem(p.procedureRuleTypeId);          
          item.name = p.name;
          item.status = p.status;
          item.procedurePlanResult = p;
          item.missedProcedureReasonId = p.missedProcedureReason;
          item.missedProcedureReasonText = p.missedProcedureReasonText;
          item.actualMinutes = p.actualMinutes;
          item.plannedMinutes = p.plannedMinutes;
          if (item.status > 1){ // anything greater than 1 means they got to it at least partially
            item.codes = p.completedCodes;
            item.descriptions = p.completedDescriptions;
            item.ammount = p.completedAmmount;
          } else {
            item.codes = p.plannedCodes
            item.descriptions = p.plannedDescriptions;
            item.ammount = p.plannedAmmount;
          }
          this.checkListItems.push(item);
        }
        this.checkListItems.sort((a,b) => {return (b.status ?? 0) - (a.status ?? 0)})        
      }
     catch (e) {
        //this.toast.error(codes.error);
      }
    //add some fun data. for another day
  },  
});
