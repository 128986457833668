<template>
      <div @click="hardReload" class="btn btn-icon btn-active-light-primary">
    <i  class="fas fa-redo-alt" style="font-size: 18px;"></i>
  </div>
</template>

<script>
export default {
    setup() {
        const hardReload = ()=>{
            location.reload(true);
        };
        return {
            hardReload,
        }
    },
}
</script>