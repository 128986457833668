
import { defineComponent } from "vue";

export default defineComponent({
  name: "avatar",  
  props: {
      avatarSize: {type: Number, default:3},
      initials: {type: String},
  },
  computed: {   
    userInfo() : any {
      let user = this.$store.getters.currentUser;
      
      if (user){    
        let currentUserInfo = {
          initials: user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase(),
          fullName: user.firstName + " " + user.lastName,
          avatarColor: "#0078D7"
        };
        
        if (user.preferences){
          let prefs = JSON.parse(user.preferences)
          if (prefs.avatarColor){
              currentUserInfo.avatarColor = prefs.avatarColor;
          }
          
        }
        return currentUserInfo;
      } else {
        return null;
      }
    }    
  }  
});
