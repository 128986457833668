import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e6dd01c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper",
  style: {"padding-left":"0px"}
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }
const _hoisted_5 = { class: "card mb-5 mb-xxl-8" }
const _hoisted_6 = { class: "card-body mb-4 pt-9 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_CreateNewNote = _resolveComponent("CreateNewNote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.pageTitle,
        onLogin: _ctx.onlogin
      }, null, 8, ["title", "onLogin"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass({
            'container-fluid': _ctx.contentWidthFluid,
            container: !_ctx.contentWidthFluid
          })
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_CreateNewNote)
              ])
            ])
          ], 2)
        ])
      ])
    ])
  ]))
}