<template>
  <apexchart v-if="show" type="radialBar" height="200" :options="chartOptions" :series="series"></apexchart>
</template>
<script>
export default {
  props: {
    series: { type: Array },
    categories: { type: Array },
    title: { type: String },
    color: {type: String},
  },
  watch:{
    color: function(newVal, oldVal){
      let titleToUseInFormatter = this.title;
      if(newVal != oldVal){
            this.chartOptions =  {
            chart: {
              type: 'radialBar',
              height: 200,
            },
            grid: {
              padding: {
              left: 0,
              right: 0,
              top: -20,
              bottom: -25,
              },
            },
             fill: {
              type: 'solid',
              colors:this.series.map(el => this.color)
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    show: true,
                    fontSize: '12px',
                    offsetY: 0
                  },
                  total: {
                    show: true,
                    label: this.title,
                    fontSize: '20px',
                    color: this.color,
                      formatter: function (w) {                        
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return titleToUseInFormatter === '' ? '' : 'Goal to reach';
                    },
                  },
                }
              }
            },
            labels: ['Goal', 'Extra', 'Extra'],
          };
      }
    }
  },
  data: function() {    
    let titleToUseInFormatter = this.title;
    return {
      show:true,
          chartOptions: {
            chart: {
              type: 'radialBar',
              height: 200,
            },
            grid: {
              padding: {
              left: 0,
              right: 0,
              top: -20,
              bottom: -25,
              },
            },
             fill: {
              type: 'solid',
              colors:this.series.map(el => this.color)
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    show: true,
                    fontSize: '12px',
                    offsetY: 0
                  },
                  total: {
                    show: true,
                    label: this.title,
                    fontSize: '20px',
                    color: this.color,
                    formatter: function (w) {                      
                      // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                      return titleToUseInFormatter === '' ? '' : 'Goal to reach';
                    },
                  },
                }
              }
            },
            labels: ['Goal', 'Extra', 'Extra'],
          },
    };
  },
};
</script>
