
	import ApiService from "@/core/services/ApiService";
	import { format } from "date-fns";
	import BarChart from "@/components/Charts/BarChart.vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import {codes} from "@/Codes/Codes";
  import {useToast} from "vue-toastification";
  import { createApp, defineComponent, ref } from "vue";
	export default defineComponent({
		props: {
			//series: { type: Array },
			//categories: { type: Array },
		},
		components: { BarChart },
		data() {

			let endDateToUse = new Date();
			endDateToUse.setDate(endDateToUse.getDate() - 1);
			

			return {
        		toast: useToast(),
				series: [{name:"Per Hour", data: new Array<number>()}],
				perHourSeries: [{name:"Per Hour", data: new Array<number>()}],
				hygienePerHourSeries: [{name:"Raw Hygiene Per Hour", data: new Array<number>()}],
				hygieneXRayPerHour: [{name:"Hygiene & Xrays Per Hour", data: new Array<number>()}],
				hoursWorkedSeries: [{name:"Hours Worked", data: new Array<number>()}],
				revenueSeries: [{name:"Revenue", data: new Array<number>()}],
				categories: new Array<string>(),
				startDate: `${new Date().getFullYear()-2}-01-01`,
				endDate: endDateToUse.toISOString().split("T")[0],			
				chartSelection: 1,	
				providerList: [] as any,
				chartNumber: 1,
				selectedProvider: null,
				max: undefined as Number | undefined,
				loading: false,
			};
		},
		methods: {	
			async bindData(chartNumber, selectedProvider){
				let baseYear = null as number | null;
				if (chartNumber === 1){
					baseYear = null;
				} else {
					baseYear = new Date().getFullYear();
				}
				await this.getData(baseYear,selectedProvider);
				this.chartNumber = chartNumber;
			},
			async valueHasChanged(event) {
				//this.series = [{name:"Per Hour", data: new Array<number>()}] //reset the series so it doesn't appear to be the same data during loading
				let selectedProvider = event.target.value === "0" ? null : event.target.value;				 
				await this.bindData(this.chartNumber, selectedProvider);
			},
			async getProviders() {				
				let res = null as any | null;
				if (this.$store.getters.currentUser.currentClinic.isAdmin) {
					res = await ApiService.query("Provider?activeOnly=true&providerType=1", null as any);
					res.data.unshift({ provider_id: "0", first_name: "All", last_name: "" })					
				} else {
					res = await (await ApiService.get("/Provider/UserProviders"));
					this.selectedProvider = res.data[0].provider_id;
					console.log("i have here", this.selectedProvider, res.data[0].provider_id);
				}
				if (res) {
					this.providerList = res.data;

					// tempProviderList.forEach((element) => {
					// 	if (element.isHygienist && element.excludeFromAnalysis == false) {
					// 		this.providerList.push({ id: element.provider_id });
					// 	}
					// });

					// console.log("providerList", this.providerList);
				}
			},
			async switchChartTabs(chartNumber){							
				await this.bindData(chartNumber, this.selectedProvider);		
			},
			chartFilterChange(value){
				console.log("value", value);
				if (value === 1){
					this.max = 400;
					this.series = this.perHourSeries;
					
				} else if (value === 2){
					this.max = undefined;
					this.series = this.hoursWorkedSeries;
					
				} else if (value === 3){
					this.max = undefined;
					this.series = this.revenueSeries;					
				} else if (value === 4){
					this.max = 400;
					this.series = this.hygienePerHourSeries;
				} else if (value === 5){
					this.max = 400;
					this.series = this.hygieneXRayPerHour;
				}

			 },
			 getWeekNumber(d : Date) : number{
				// Copy date so don't modify original
				d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
				// Set to nearest Thursday: current date + 4 - current day number
				// Make Sunday's day number 7
				d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
				// Get first day of year
				let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
				// Calculate full weeks to nearest Thursday
				var weekNo = Math.ceil((((<any>d-<any>yearStart) / 86400000) + 1)/7);
				// Return array of year and week number
				return weekNo;
			},
			async getData(baseYear, providerId) {
				this.loading = true;
				try{					
					const params = {
						startDate: this.startDate,
						endDate: this.endDate,			
						baseYear: baseYear,
						providerId: providerId
					};
					let res = await ApiService.query("Provider/MonthlyYearOverYear", { params });
					if (res.status == 200) {						
						
						const years = [...new Set(res.data.map(item => item.theYear))];
						const perHourSeries = new Array<any>();
						const hoursWorkedSeries = new Array<any>();
						const revenueSeries = new Array<any>();
						const hygienePerHourSeries = new Array<any>();
						const hygieneXraysPerHourSeries = new Array<any>();
						for(let year of years){
							perHourSeries.push({name:(<any>year).toString(), data: new Array<number>()});
							hoursWorkedSeries.push({name:(<any>year).toString(), data: new Array<number>()});
							revenueSeries.push({name:(<any>year).toString(), data: new Array<number>()});
							hygienePerHourSeries.push({name:(<any>year).toString(), data: new Array<number>()});
							hygieneXraysPerHourSeries.push({name:(<any>year).toString(), data: new Array<number>()});
						}

						console.log("here");
						var xaxisValues = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'];
						// for (let weekIndex = 1; weekIndex < 54; weekIndex++){
						// 	xaxisValues.push(weekIndex);
						// }

						for(let yearIndex = 0; yearIndex < years.length; yearIndex++){
							for(const r of res.data.filter(r => r.theYear === years[yearIndex]))
							{
								perHourSeries[yearIndex].data.push(Math.round(r.perHour));
								hoursWorkedSeries[yearIndex].data.push(r.totalHoursWorked);
								revenueSeries[yearIndex].data.push(Math.round(r.moneyEarned));
								hygienePerHourSeries[yearIndex].data.push(Math.round(r.hygienePerHour));
								hygieneXraysPerHourSeries[yearIndex].data.push(Math.round(r.hygieneXRayPerHour));
							}
						}
						// for(const r of res.data){
						// 	const d = new Date(r.date.split("T")[0]);	
							
						// 	let year = d.getFullYear();
						// 	let weekOfYear = this.getWeekNumber(d)

						// 	xaxisValues.add(weekOfYear);
						// 	series.find(s => s.name === year.toString())?.data.push(r.perHour)							
						// };
						// console.log(xaxisValues);
						// const xaxisValuesArray = Array.from(xaxisValues);						
						this.perHourSeries = perHourSeries;
						this.hoursWorkedSeries = hoursWorkedSeries;
						this.revenueSeries = revenueSeries;		
						this.hygienePerHourSeries = hygienePerHourSeries;
						this.hygieneXRayPerHour = hygieneXraysPerHourSeries;
						this.series = this.perHourSeries;
						this.categories = xaxisValues;

						this.selectedProvider = providerId;

					}else{
						this.toast.error(codes.error)
					}
        		} catch (e) {
          			this.toast.error(codes.error)
				}			
				finally{
					this.loading = false;
				}
			},
		},

		async mounted() {
			setCurrentPageTitle("Hygiene Year Over Year Comparison Report");			
			await this.getProviders();
			await this.bindData(this.chartNumber, this.selectedProvider);
			this.max = 400;
		},
	});
