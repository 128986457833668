
import ApiService from "@/core/services/ApiService";
import { format } from "date-fns";
import { codes } from "@/Codes/Codes";
import { useToast } from "vue-toastification";
import { DateHelper } from "@/Utilities/DateHelper";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        startDate: { type: String },
        endDate: { type: String },
        comparisonToggle: { type: Boolean, default: false }
    },
    data() {
        return {
            toast: useToast(),
            providerSeries: [],
            loading: false,
            providerProductivityLatestData: [],            
            filter: "provider",
            filters: [
                { name: '# Appointments Planned in RNA', val: "optimizedAppts" },
				],            
            chartOptions: {
                colors: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', 
                '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', 
                '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'],
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: false
                    }
                },
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                },
                dataLabels: {
                    enabled: false,                    
                },
                tooltip : {
                    enabled:true,                    
                },
                markers: {
                    size: 0,
                },
                noData: {
                    text: "No data available for selected range",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "black",
                        fontSize: "14px",
                        fontFamily: undefined,
                    },
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: "#fff",
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0,
                    },
                    onItemClick: {
                        toggleDataSeries: true,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                 xaxis: {
                     type: "category",
                     categories: [],
                },                
            },
            summarySeries: [] as any,
            summaryChartOptions: {
                //colors: ['#4363d8', '#000000'],
                chart: {
                    type: 'bar',                    
                    stacked: false,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                },
                dataLabels: {
                    enabled: true,                          
                },
                markers: {
                    size: 5,
                },
                noData: {
                    text: "No data available for selected range",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "black",
                        fontSize: "14px",
                        fontFamily: undefined,
                    },
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false,
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: "#fff",
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0,
                    },
                    onItemClick: {
                        toggleDataSeries: true,
                    },
                    onItemHover: {
                        highlightDataSeries: true,
                    },
                },
                 xaxis: {
                     type: "category",
                     categories: [],
                },
            }
        };
    },
    methods: {
        provProductivityFilterChange(f) {
            //let ans = { series: [], categories: [] } as any;
            if (f == "Revenue") {
                this.bindData(this.providerProductivityLatestData, "moneyEarned");
                
            } else if (f == "HoursWorked") {
                this.bindData(this.providerProductivityLatestData, "totalHoursWorked");
                
            } else if (f == "AppointmentsPlanned") {
                this.bindData(this.providerProductivityLatestData, "perHour");
                
            }            
        },        
        groupByProvidersProductivity(data, target, marker) {
            let allDates = [] as any;
            let allProviders = [] as any;
            let providerSeriesData = [] as any;

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (i == 0) {
                    allDates.push(element.date);
                } else {
                    let flag = true;
                    for (let j = 0; j < allDates.length && flag; j++) {
                        const datesElt = allDates[j];
                        if (datesElt.split("T")[0] == element.date.split("T")[0]) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        allDates.push(element.date);
                    }
                }
            }
            allDates = allDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
            for (let i = 0; i < data.length; i++) {
                const d = data[i];
                if (i == 0) {
                    if (d.moneyEarned !== null) {
                        allProviders.push({ id: `${d.clinicId}_${d.userFullName}`, name: `${d.userFullName}` });
                    }
                } else {
                    let flag = true;
                    for (let j = 0; j < allProviders.length && flag; j++) {
                        if (allProviders[j].id == `${d.clinicId}_${d.userFullName}`) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        if (d.moneyEarned !== null) {
                            allProviders.push({ id: `${d.clinicId}_${d.userFullName}`, name: `${d.userFullName}` });
                        }
                    }
                }
            }
            for (let i = 0; i < allProviders.length; i++) {
                const prov = allProviders[i];
                let ob = { id: prov.id, name: prov.name, data: [] as any } as any;
                if (prov.name === "Not Optimized"){
                    ob.color = "#A8A8A8";
                }
                allDates.forEach((d) => {
                    ob.data.push({ y : null });
                });
                providerSeriesData.push(ob);
            }
            for (let i = 0; i < allDates.length; i++) {
                const date = allDates[i].split("T")[0];
                for (let j = 0; j < data.length; j++) {
                    if (data[j].date.split("T")[0] == date) {
                        for (let k = 0; k < providerSeriesData.length; k++) {
                            const provSer = providerSeriesData[k];
                            if (provSer.id == `${data[j].clinicId}_${data[j].userFullName}`) {
                                providerSeriesData[k].data[i].y = Math.floor(data[j][target] || 0);
                                if (marker && data[j][marker] > 0){
                                   providerSeriesData[k].data[i].goals = [{name: "Planned", value:data[j][marker],  strokeHeight:5, strokeColor: '#775DD0'}]
                                }
                            }
                            providerSeriesData[k].data[i].x = data[j].date.split("T")[0];
                            
                        }
                    }
                }
            }
            let categoriesToReturn = allDates.map((date) => format(new Date(date), "PP"));
            return { series: providerSeriesData, categories: categoriesToReturn };
        },
        async getProviderData() {
            this.loading = true;
            try {
                const params = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    compare: this.comparisonToggle
                };
                let res = await ApiService.query("Report/RnaUsageByUser", { params });     
                
                
                
                if (res.status === 200) {
                    console.log("res.data", res.data);
                    let providerUsage = res.data;
                    this.bindData(providerUsage, "optimizedAppts");
                } else {
                    this.toast.error(codes.error)
                }
            } catch (e) {
                this.toast.error(codes.error)
                throw (e);
            }finally{
                this.loading = false;
            }
        },
        bindData(data :any, property: string){
            console.log("binddata")
            //grab the clinicID for currentclinic
            let myClinicId = this.$store.getters.currentUser.currentClinic.clinicId;
            let marker : string|null = null;

            let friendlyName = "N/A";
            if (property === "optimizedAppts") {
                friendlyName = "# Appointments Optimized";                
            } 

            this.providerProductivityLatestData = data;
            let providerProd = data.data;            
            let ans = this.groupByProvidersProductivity(providerProd, property, marker);
            let newOptions = JSON.parse(JSON.stringify(this.chartOptions));                          
            newOptions.xaxis.categories = ans.categories;
            this.chartOptions = newOptions;
            this.providerSeries = ans.series;
            console.log("series", ans.series);

            
            let summaryData = data.totals;
            //console.log("summary data: ", summaryData);
            const avgValue = parseFloat((summaryData.reduce((acc, curr) => acc + curr[property], 0) / summaryData.length).toFixed(1));        
            const averageDataPoint = { userFullName: "Average"};
            averageDataPoint[property] = avgValue;            
            let summarizedSummary : any = null;
            if (summaryData.length > 10){
                DateHelper.sortArrayByProperty(summaryData, property, "desc");                        
                const topFive = summaryData.slice(0, 5);//gets the first 5                
                const bottomFive = summaryData.slice(-5);//gets the last 5
                summarizedSummary = [...topFive, averageDataPoint, ...bottomFive];

                //grab all the items from the summaryData that match my clinic ID
                const filteredData = summaryData.filter(item => item.clinicId === myClinicId);

                //for each item, check if the item is in the summarized data already (if it is do nothing)
                filteredData.forEach(item => {
                    const isInSummarizedData = summarizedSummary.some(
                        summarizedItem =>
                        summarizedItem.clinicId === item.clinicId &&
                        summarizedItem.userFullName === item.userFullName
                    );

                    //if it doesn't exist in the summarized data add it to the bottom
                    if (!isInSummarizedData) {
                        summarizedSummary.push(item);
                    }
                 });

                 //re-sort the summarized data descending by property
                 DateHelper.sortArrayByProperty(summarizedSummary, property, "desc");

            } else {
                summarizedSummary = summaryData;
                summarizedSummary.push(averageDataPoint)
                DateHelper.sortArrayByProperty(summarizedSummary, property, "desc");
            }
            
            let series = summarizedSummary.map((t, index) => {
                const baseObject = {
                    x: t.userFullName,
                    y: t[property]                    
                };

                // For the "Average" item
                if (t.userFullName === "Average") {
                    return { ...baseObject, fillColor: "#A8A8A8" };
                } else if (t.clinicId === myClinicId){
                    return { ...baseObject, fillColor: "#4363d8" };
                } else {
                    return { ...baseObject, fillColor: "#B4C6E7" };
                }                
            });


            let summaryCategories = summarizedSummary.map(t => (this.comparisonToggle && t.clinicName) ? `${t.clinicName} - ${t.userFullName}` : t.userFullName);
            let newSummaryOptions = JSON.parse(JSON.stringify(this.summaryChartOptions));                          
            newSummaryOptions.xaxis.categories = summaryCategories;                    
            this.summaryChartOptions = newSummaryOptions;
            this.summarySeries = [{id: property, name: friendlyName, data: series} as any] ;
            console.log("summary series", this.summarySeries);

        },
        getAllData() {
            this.getProviderData();
        },
    },

    mounted() {        
        this.getAllData();
    },

    // watch: {
    //     // whenever question changes, this function will run
    //     startDate(oldStartDate, newStartDate) {
    //         this.getProviderData();
    //     },
    // },
});
