
export class DateHelper {
  static getDatePortion(date: Date): string {
    const year = date.getFullYear(); // get the year (e.g. 2023)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // get the month (0-11) and add 1 to convert it to a 1-12 range, then pad with a leading zero if necessary
    const day = String(date.getDate()).padStart(2, '0'); // get the day of the month (1-31), then pad with a leading zero if necessary
    const dateString = `${year}-${month}-${day}`; // concatenate the year, month, and day values into a string with hyphens as separators
    return dateString;
  }
  static getDatePortionAsDate(date: Date): Date {
    const year = date.getFullYear(); // get the year (e.g. 2023)
    const month = date.getMonth(); // get the month (0-11) and add 1 to convert it to a 1-12 range, then pad with a leading zero if necessary
    const day = date.getDate(); // get the day of the month (1-31), then pad with a leading zero if necessary    
    return new Date(year,month, day);;
  }
  static getDatePortionFromString(dateString: string): Date {
    const date = new Date(dateString.split("T")[0]);
    const currentOffset = date.getTimezoneOffset();
    date.setMinutes(currentOffset)
    return date;
  }

  static sortArrayByProperty(arr: [], propName: string, sortOrder: "asc" | "desc"): void {
    arr.sort((a, b) => {
      if (a[propName] < b[propName]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[propName] > b[propName]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  static sortArrayByFunction(arr: [], func: Function, sortOrder: "asc" | "desc"): void {
    arr.sort((a, b) => {
      if (func(a) < func(b)) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (func(a) > func(b)) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  static getStartingIntervalDates(){
    let startingEndDate = new Date();
		startingEndDate.setDate(startingEndDate.getDate() - 1);
		let startingStartDate = new Date();
		//set startingStartDate to beginning of the current month, except if it is the first of the month, then set it to the first of the previous month		
		if (startingStartDate.getDate() <= 2) {
			startingStartDate.setMonth(startingStartDate.getMonth() - 1);
		} 		
		startingStartDate.setDate(1);
    return {startingStartDate, startingEndDate};
  }
}
