import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.userInfo)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "circle btn-bg-light",
        style: _normalizeStyle(`background-color:${_ctx.userInfo.avatarColor}; height:${_ctx.avatarSize}rem; width:${_ctx.avatarSize}rem;`),
        title: _ctx.initials ? _ctx.initials : _ctx.userInfo.fullName
      }, [
        _createElementVNode("span", {
          class: "initials",
          style: _normalizeStyle(`font-size:${(_ctx.avatarSize < 3 ? 2 : _ctx.avatarSize)/2}rem; top:${_ctx.avatarSize/(_ctx.avatarSize < 3 ? 8 : 4)}rem`)
        }, _toDisplayString(_ctx.initials ? _ctx.initials : _ctx.userInfo.initials), 5)
      ], 12, _hoisted_1))
    : _createCommentVNode("", true)
}