import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export class ClinicContext {
  route;
  store;
  /**
   *
   */
  constructor(route, store) {
    this.route = route;
    this.store = store;
  }

  async buildClinic(auth0) {
    const clinic = await (await ApiService.get("Clinic")).data;
    if (!clinic) throw "zero clinics found";
    console.log("CLINIC: ", clinic);
    this.store.commit(Mutations.SET_CLINIC, clinic);
  }
}
