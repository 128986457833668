import ApiService from "@/core/services/ApiService";
import { Mutations} from "@/store/enums/StoreEnums";
import { LOCAL_STORAGE_CONST } from "@/Models/local-storage.model";
import { useRouter } from "vue-router";
import GoatNotesService from "@/core/services/GoatNotesService";

export class UserContext {
    route;
    store;
    router;
    /**
     *
     */
    constructor(route, store, router) {
        this.route = route;
        this.store = store;
        this.router = router;

    }

  

    async buildCurrentUser(auth0){
      
      console.log("build user")
      let currentUser;
      try {
         currentUser =  await (await ApiService.get("user/current")).data;
      } catch(e: any) {
        if (e.response?.status === 422){
          console.log("error on login", e.response.status);
          this.router.push("/noclinicerror");
          return false;
        } else {
          this.router.push("/500");
          return false;
        }
      }

      let clinicGuid : string | null = null
        
        //if no clinic is specified in the URL
      let redirectLinkContent : any  = null;
      if (this.route.redirectedFrom){
        redirectLinkContent = this.route.redirectedFrom.query;

      }
      if(redirectLinkContent && redirectLinkContent.c){
        clinicGuid = redirectLinkContent.c;
      } else if (this.route.query.c){
          clinicGuid = this.route.query.c.toString();
        } else {
          clinicGuid = localStorage.getItem(LOCAL_STORAGE_CONST.ClinicGuid); //what if clinic is now found? i'd want to erase this
          if (clinicGuid !== null){
            if (currentUser.clinics.filter(c => {return c.clinicGuid === clinicGuid}).length === 0){
              clinicGuid = null;
            }
          }
          if (clinicGuid === null){
            // take the prefereed clinic
            console.log("Prefs " + currentUser.preferences)
            if (currentUser.preferences){
                const prefs = JSON.parse(currentUser.preferences)
              if (prefs.defaultClinic){
                clinicGuid = prefs.defaultClinic
              }
            }
          }
          // take the first clinic
          if (clinicGuid === null){
            clinicGuid = currentUser.clinics[0].clinicGuid;
          }
        }

        if (currentUser.clinics.length === 0)
          throw "user has 0 clinics";

        const clinic = currentUser.clinics.filter(c => {return c.clinicGuid === clinicGuid})[0]
        currentUser.currentClinic = clinic;
        console.log("CLINIC: ", clinic);

        document.title = "RNA 180: " + clinic.name;
        ApiService.setClinicHeader(clinic.clinicGuid);
        
        //hard coded clinic for RNA in goatnotes
        GoatNotesService.setClinicHeader("58ae429a-e51a-4e2d-bf4d-77aafd3d076c");
        //currentUser.otherUsers = await (await ApiService.get("user")).data;



        this.store.commit(Mutations.SET_USER, currentUser);
        return true;
      }

}