<template>
<div v-if="show">
    <p class="floating-msg">{{msg}}</p>
</div>
</template>

<script>
	export default {
        data(){
            return {
                show: false,
                msg: "",
            }
        },
		methods: {
			showmsg(msg) {
                this.msg = msg;
                this.show = true;
                let self = this;
                this.show = true;
                setTimeout(() => {
                    self.show = false;
                    self.msg = "";
                }, 2500);
			},
		},
	};
</script>

<style lang="scss" scoped>
.floating-msg{
    position: fixed;
    top:40%;
    left: 45%;
    color: white;
    background: rgba(0, 0, 0, 0.384);
    border-radius: 24px;
    z-index: 9999;
    padding: 5px;
}
</style>
