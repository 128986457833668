<template>
  <div @click="show = !show" class="btn btn-icon btn-active-light-primary">
    <!-- <inline-svg src="/media/icons/duotone/Code/Question-circle.svg" /> -->
<i class="far fa-question-circle font-20"></i>
  </div>
  
  <modal
    :footer="false"
    :title="'Need Help?'"
    :show="show"
    okText=""
    cancelText=""
    @close="show = false"
  >
    <div class="row center-sm">
        <div class="col text-center">
            <h5 class="mb-5">
                Support
            </h5>
            <p>Office hours 8am to 8pm</p>
            <p>
          <!-- <span class=""
            ><inline-svg src="/media/icons/duotone/Devices/Phone.svg"/></span
          > -->
          <span>Phone: </span>
          <a href="tel:6138758665">6138758665</a>
        </p>
        <p>
          <!-- <span class="">
            <inline-svg src="/media/icons/duotone/Communication/Mail.svg"
          /></span> -->
          <span>Email: </span>
          <a href="mailto:support@rna180.ca">support@rna180.ca</a>
        </p>
        </div>
        <div class="col text-center" style="border-left: 1px solid #333;">
            <h5 class="mb-5">
                Training Videos
            </h5>
            <a href="https://www.youtube.com/watch?v=_O9ZlDGxcqM&list=PLmfDvWzn_a_aETcRw5sWVn9Uciuk3mne_" target="_blank"><i class="fa-brands fa-youtube" style="font-size: 90px;"></i></a>
        </div>
        <div class="row mt-20">
          <div class="col text-center">
           <img src="/RNA 180__Logo_Original_Dark Backround_large.png" style="max-width: 250px;">
          </div>
        </div>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import { ref } from "@vue/reactivity";
import InlineSvg from "vue-inline-svg";
export default {
  components: { Modal, InlineSvg },
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.width-inherit{
    width: inherit !important;
}
.text-muted{
    color: #A1A5B7 !important;
    margin-left: 1rem;
}
.font-20{
  font-size: 20px;
}
    .modal-body-custom{
      img{
        width: 75%;
      }
    }
@media only screen and (max-width: 575px) {
  .backdrop-custom {
    .modal-container{
      width: 90%;
      left: 8%;
    }
    .modal-body-custom{
      text-align: center !important;
      img{
        width: 50%;
      }
    }
  }
}
</style>