
import { defineComponent, computed } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import InlineSvg from "vue-inline-svg";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
    InlineSvg,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const currentClinic = computed(() => {
      if (!store.getters.currentUser) return null;

      if (!store.getters.currentUser.currentClinic) return null;

      return store.getters.currentUser.currentClinic.name;
    });
        const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      currentUser,
      currentClinic,
      breadcrumbs,
    };
  },
});
