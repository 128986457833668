<template>
  <div class="row gy-5 g-xl-8">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
      <calendar-controller />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CalendarController from "@/components/Calendar/CalendarController.vue"
export default defineComponent({
  name: "kt-widget-9",
  components: { CalendarController  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {

    };
  },
  methods: {
  },
  setup() {
    setCurrentPageTitle("Dashboard");

    return {
    };
  },
});
</script>
