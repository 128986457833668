<template>
  <div class="loader" v-if="loading || loadingProviders"></div>
  <h2>Provider Search</h2>
  <div class="row card">
    <div class="col-12">
      <div class="d-flex  py-2 align-items-center">
        <div class="d-flex align-items-center grey-bg py-2 px-7">
          <div class="symbol symbol-50px me-5">
            <i class="fas fa-calendar-alt fs-30-px mx-2" style="color: #009ef7;"></i>
          </div>
          <div class="flex-grow-1 text-start">
            <span class="text-muted d-block fw-bold">Start</span>
            <input type="date" name="start" class="form-control border-none p-0" v-model="startDate" />
          </div>
        </div>
        <div class="d-flex align-items-center grey-bg py-2 px-7">
          <div class="symbol symbol-50px me-5">
            <i class="fas fa-calendar-alt fs-30-px mx-2" style="color: #009ef7;"></i>
          </div>
          <div class="flex-grow-1">
            <span class="text-muted d-block fw-bold">End</span>
            <input type="date" name="end" class="form-control border-none p-0" v-model="endDate" />
          </div>
        </div>

        <Multiselect v-model="value" mode="tags" class="cornered-radius" ref="multiselect" :close-on-select="false"
          :searchable="true" :create-option="true" :options="providerList" />
        <button class="btn btn-primary mx-10 cornered-radius" @click="getProviderData(value)"
          style="width: fit-content">
          Generate
        </button>
      </div>
    </div>
  </div>

  <div v-for="(provider, index) in totalPayroll" :key="index" class="row my-5 card">
    <ProductivityReportByDayTable :name="`${provider.first_name} ${provider.last_name}`"
      :paymentPeriod="`${formatDate(startDate)} to ${formatDate(endDate)}`" :payrollArray="provider.payrollReport"
      :totalCompleted="provider.totalCompleted" :clinicName="currentClinicName" />
    <!-- <h2>Daily Breakdown</h2>
    <div class="d-flex flex-wrap">
      <div
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          me-6
          mb-3
        "
      >
        <div class="d-flex align-items-center">
          <div class="fs-4 fw-bolder">
            {{ provider.first_name + " " + provider.last_name }}
          </div>
        </div>
        <div class="fw-bold fs-6 text-gray-400">Name</div>
      </div>
      <div v-if="provider.baseWage"
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          me-6
          mb-3
        "
      >
        <div class="d-flex align-items-center">
          <div class="fs-4 fw-bolder">${{ provider.baseWage }}</div>
        </div>
        <div class="fw-bold fs-6 text-gray-400">Base Wage</div>
      </div>
      <div
        class="
          border border-gray-300 border-dashed
          rounded
          min-w-100px
          py-3
          px-4
          me-6
          mb-3
        "
      >
        <div class="d-flex align-items-center">
          <div class="fs-4 fw-bolder">
            {{ formatDate(startDate) + " - " + formatDate(endDate) }}
          </div>
        </div>
        <div class="fw-bold fs-6 text-gray-400">Payroll Period</div>
      </div>
    </div>
    <table>
      <tr>
        <th>Date</th>
        <th>Day</th>
        <th>Time In</th>
        <th>Time Out</th>
        <th>Time In</th>
        <th>Time Out</th>
        <th>Time In</th>
        <th>Time Out</th>
        <th>Base Total</th>
        <th>Bonus Total</th>
        <th>Total Hours</th>
        <th>Total Pay</th>
      </tr>
      <tr v-for="(item, index) in provider.payrollReport" :key="index">
        <td>{{ formatDate(item.date) }}</td>
        <td>{{ dayOfWeek(item.date) }}</td>
        <td class="timestamp">{{ getTimestamp(item.time1) }}</td>
        <td class="timestamp">{{ getTimestamp(item.time2) }}</td>
        <td class="timestamp">{{ getTimestamp(item.time3) }}</td>
        <td class="timestamp">{{ getTimestamp(item.time4) }}</td>
        <td class="timestamp">{{ getTimestamp(item.time5) }}</td>
        <td class="timestamp">{{ getTimestamp(item.time6) }}</td>
        <td>${{ Math.ceil(item.baseTotal * 100) / 100 }}</td>
        <td>${{ Math.ceil(item.bonus * 100) / 100 }}</td>
        <td>{{ getHours(item.totalMinutesClocked) }}</td>
        <td>${{ Math.ceil(item.total * 100) / 100 }}</td>
      </tr>
      <tr>
        <td colspan="10" class="total"><strong>Total</strong></td>
        <td class="hours">
          <strong>{{ provider.totalHours }}</strong>
        </td>
        <td class="price">
          <strong>${{ provider.totalWage }}</strong>
        </td>
      </tr>
    </table> -->
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent } from "@vue/runtime-core";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect/dist/multiselect";
import { minutesToHours, format, sub } from "date-fns";
import ProductivityReportByDayTable from '@/components/Tables/ProductivityReportByDayTable.vue';
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { DateHelper } from "@/Utilities/DateHelper";

export default defineComponent({
  components: { Multiselect, ProductivityReportByDayTable },
  data() {

    let { startingStartDate, startingEndDate } = DateHelper.getStartingIntervalDates();
    return {
      // Note `isActive` is left out and will not appear in the rendered table
      toast: useToast(),
      fields: ["first_name", "last_name", "age"],
      loading: false,
      loadingProviders: false,
      value: [],
      providerList: [],
      totalPayroll: [],
      startDate: startingStartDate.toISOString().split("T")[0],
      endDate: startingEndDate.toISOString().split("T")[0],
    };
  },
  async mounted() {
    console.log("mounted");
    await this.getProviders();
    this.$refs.multiselect.select(this.providerList);
    this.getProviderData(this.value);
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), "yyyy-MM-dd");
    },
    dayOfWeek(date) {
      return format(new Date(date), "EEEE");
    },
    getHours(totalMinutes) {
      return (totalMinutes / 60).toFixed(2);
    },
    getTimestamp(timestamp) {
      let time = format(new Date(timestamp), "H:mm");
      if (time == "0:00") return "-";
      return time;
    },
    async getProviders() {
      this.loadingProviders = true;
      try {
        let res = await ApiService.query(`Provider/Details?activeOnly=true`);
        console.log(res.data);
        if (res) {
          let tempProviderList = res.data;
          tempProviderList.forEach((element) => {
            if (element.isHygienist) {
              this.providerList.push({
                value: {
                  provider_id: element.provider_id,
                  baseWage: element.baseWage,
                  first_name: element.first_name,
                  last_name: element.last_name,
                },
                label: element.first_name + " " + element.last_name,
              });
            }
          });

          console.log("providerList", this.providerList);
        }
        if (res.status !== 200) {
          this.toast.error(codes.error)
        }
      } catch (e) {
        this.toast.error(codes.error)
      }finally{
                this.loadingProviders = false;
            }
    },
    async getProviderData(selectedProviders) {
      this.loading = true;
      this.totalPayroll = [];
      for (const provider of selectedProviders) {
        console.log("provider info ", provider);
        const providerInfo = {
          first_name: provider.first_name,
          last_name: provider.last_name,
          baseWage: provider.baseWage,
        };
        console.log("provider info 1", providerInfo);
        try {
          const params = {
            startDate: this.startDate,
            endDate: this.endDate,
            providerID: provider.provider_id,
          };
          let res = await ApiService.query("/Report/ProviderProductivityByDay", {
            params,
          });
          console.log("xxxx", res.data);
          if (res.status === 200) {
            console.log(provider.first_name, res.data)
            providerInfo.payrollReport = res.data;
            console.log("report: ", providerInfo.payrollReport)
            let totalCompleted = 0;
            for (const day of providerInfo.payrollReport) {
              totalCompleted += day.totalCompleted;
            }
            providerInfo.totalCompleted = totalCompleted;
            this.totalPayroll.push(providerInfo);
            console.log(this.totalPayroll)
          } else {
            this.toast.error(codes.error)
          }
        } catch (e) {
          this.toast.error(codes.error)
          console.error(e)
        } finally {
          this.loading = false;
        }
      }
      console.log("total report", this.totalPayroll);
    },
    getAllData() {
      this.getData();
      this.getProviderData();
    },
  },
  setup(props, context) {
    setCurrentPageTitle("Productivity by day");
    const store = useStore();
    const currentClinicName = store.getters.currentUser.currentClinic.name;
    const instanceInfo = store.getters.currentInstanceInfo;
    return {
      instanceInfo,
      currentClinicName
    }
  }
});
</script>

<style scoped>
table {
  border-collapse: collapse;
}

td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 10px;
  text-align: left;
}

tr:nth-child(even) td {
  background-color: #eee;
}

tr:nth-child(odd) td {
  background-color: #fff;
}

tr:last-child td {
  border: 0 !important;
}

td.total {
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: right;
}

td.hours {
  background-color: #c2d1f5 !important;
}

td.price {
  background-color: #fbdfc4 !important;
}

td.timestamp {
  width: 3%;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: -25%;
}

:deep(.multiselect-tag) {
  background: var(--ms-tag-bg, #0095e8);
}

:deep(.multiselect-clear:hover .multiselect-clear-icon) {
  background: var(--ms-clear-color, #999);
}
</style>