import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export class InstanceInfoContext {
  route;
  store;
  /**
   *
   */
  constructor(route, store) {
    this.route = route;
    this.store = store;
  }

  async buildInstance() {
    try {
      const providers = await (await ApiService.get("/Provider/UserProviders")).data;
      console.log("instance info", providers)
      if (!providers) throw "zero providers found";
      let instanceInfo = {};
      if (providers.length > 0 && providers) {
        instanceInfo = {
          isProvider: true,
          isClinic: false,
          goalHourlyProduction: providers[0].goalWage ?? (this.store.getters.currentClinicInfo.goalHourlyProduction * this.store.getters.currentClinicInfo.goalPercentage) ,
          providersIds: providers.map(x => x.provider_id),
          providerBaseWage: providers[0].baseWage,
          providers: providers,
        }
      } else {
        instanceInfo = {
          isProvider: false,
          isClinic: true,
          goalHourlyProduction: this.store.getters.currentClinicInfo.goalHourlyProduction,
          providersIds: [],
          providerBaseWage: 0,
          providers: providers,
        }
      }
      console.log("instance info: ", instanceInfo)
      this.store.commit(Mutations.SET_INSTANCE_INFO, instanceInfo);
    } catch (e) {
      console.error(e);
    }
  }
}
