
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import { useToast } from "vue-toastification";
import { codes } from "@/Codes/Codes";
import { ProviderTypeEnum } from "@/Models/data-contract/index";
import { useStore } from "vuex";

class ProviderScheduleDay {

  constructor(dayOfWeek: number, startTime1: String, endTime1: String, startTime2: String | null, endTime2: String | null, startTime3: String | null, endTime3: String | null, hasShift: boolean) {
    this.dayOfWeek = dayOfWeek;
    this.startTime1 = startTime1;
    this.endTime1 = endTime1;
    this.startTime2 = startTime2;
    this.endTime2 = endTime2;
    this.startTime3 = startTime3;
    this.endTime3 = endTime3;
    this.hasShift = hasShift;
  }

  dayOfWeek: number;
  startTime1: String;
  endTime1: String;
  startTime2: String | null;
  endTime2: String | null;
  startTime3: String | null;
  endTime3: String | null;
  hasShift: boolean;
}

interface ProviderSchedule {
  providerId: string;
  schedule: Array<ProviderScheduleDay>;
}

export default defineComponent({
  name: "EditProvider",
  emits: ["closeEditScheduleForm"],
  props: {
    selectedProvider: {
      required: true,
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      providerSchedule: null as ProviderSchedule | null,
      toast: useToast(),
      errorMessageCustom: "",
      assignableUsers: null,
      providers: [] as { id: ProviderTypeEnum; name: string; }[],
      providerTypeHygienist: ProviderTypeEnum.Hygienist
    };
  },
  updated() {

  },
  methods: {
    getDateOfWeekText(dayOfWeek: number) {
      switch (dayOfWeek) {
        case 1:
          return "Sunday";
        case 2:
          return "Monday";
        case 3:
          return "Tuesday";
        case 4:
          return "Wednesday";
        case 5:
          return "Thursday";
        case 6:
          return "Friday";
        case 7:
          return "Saturday";
        default:
          return "";
      }
    },
    clearForm() {

    },
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    discard() {
      // console.log("discard");
      this.$emit("closeEditScheduleForm");
    },
    async save() {
      let providerSchedule = { providerId: this.selectedProvider.provider_id, schedule: [] as any };
      providerSchedule.schedule = this.providerSchedule?.schedule.filter(x => x.hasShift).map(x => {
        return {
          dayOfWeek: x.dayOfWeek,
          startTime1: `2000-01-01T${x.startTime1}`,
          endTime1: `2000-01-01T${x.endTime1}`,
          startTime2: x.startTime2 ? `2000-01-01T${x.startTime2}` : null,
          endTime2: x.endTime2 ? `2000-01-01T${x.endTime2}` : null,
          startTime3: x.startTime3 ? `2000-01-01T${x.startTime3}` : null,
          endTime3: x.endTime3 ? `2000-01-01T${x.endTime3}` : null,
        }
      });
      console.log("providerSchedule", providerSchedule);
      await ApiService.post(`/Provider/${this.selectedProvider.provider_id}/Schedule`, providerSchedule as any)
    }
  },

  async mounted() {
    try {
      let res = await ApiService.get(`/Provider/${this.selectedProvider.provider_id}/Schedule`)
      if (res.status === 200) {
        let providerSchedule = new Array<ProviderScheduleDay>();
        for (let i = 1; i <= 7; i++) {
          let dayOfWeek = res.data.schedule.find(x => x.dayOfWeek === i);
          if (dayOfWeek) {
            providerSchedule.push(new ProviderScheduleDay(dayOfWeek.dayOfWeek,
              dayOfWeek.startTime1.split("T")[1],
              dayOfWeek.endTime1.split("T")[1],
              dayOfWeek.startTime2?.split("T")[1],
              dayOfWeek.endTime2?.split("T")[1],
              dayOfWeek.startTime3?.split("T")[1],
              dayOfWeek.endTime3?.split("T")[1], true))
          } else {
            providerSchedule.push(new ProviderScheduleDay(i, "00:00", "00:00", null, null, null, null, false))
          }
        }
        this.providerSchedule = { providerId: this.selectedProvider.provider_id, schedule: providerSchedule };
        console.log("this.providerSchedule", this.providerSchedule);
      } else {
        this.toast.error(codes.error)
      }
    } catch (e) {
      this.toast.error(codes.error)
    }
  },
});
