
import { defineComponent, computed } from "vue";
import Header from "@/layout/header/Header.vue";
import LayoutService from "@/core/services/LayoutService";
import { useStore } from "vuex";
import CreateNewNote from "@/components/Calendar/AppointmentComponents/CreateNewNote.vue"
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo
} from "@/core/helpers/config";


export default defineComponent({
  name: "Layout",
  data() {
    return {
      asideEnabled: asideEnabled,
      themeLightLogo: themeLightLogo,
      themeDarkLogo: themeDarkLogo,
      loaderLogo: loaderLogo,
      subheaderDisplay: subheaderDisplay,
      modal: null,
    }
  },
  components: { Header, CreateNewNote },
  setup() {
    const store = useStore();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const onlogin = function () { };

    // initialize html element classes
    LayoutService.init();

    return {
      pageTitle: pageTitle,
      contentWidthFluid,
      loaderEnabled,      
      onlogin
    }
  }
});
