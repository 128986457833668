/**
 * Provider Data contract. Maps 'ProviderModel'
 */
export interface IProviderDataContract {
    ClinicId: number;
    provider_id: string;
    last_name: string;
    first_name: string;
    suffix: string;
    isHygienist: boolean;
    issecondaryprovider: boolean;
    isnonperson: boolean;
    inactive: boolean;
    Notes?: string;
    ExcludeFromAnalysis: boolean;
    ExcludeFromCalendar: boolean;
    UserId?: number;
    CalendarOrder?: boolean;
    ProviderType: ProviderTypeEnum;
}


export enum ProviderTypeEnum {
    Other = 0,
    Hygienist = 1,
    Doctor = 2
}