

import { defineComponent } from "vue";

export default defineComponent({
    name: "AlertComponent",
    components: {},
    data(props) {
        return {
            pAlertType: "warning",
            icon: "fa-triangle-exclamation"
        };
    },
    props: {        
        alertType: { type: String, required: true },
        title: {type: String, required: true},
        text: {type: String, required: true},
        dismissable: {type: Boolean, required: false}
    },
    methods: {
        getAlertType()
        {

            switch (this.alertType) {
                case "warning":
                    this.pAlertType = "warning";
                    this.icon = "fa-triangle-exclamation"
                    break;
                case "info":
                    this.pAlertType = "primary";
                    this.icon = "fa-circle-info";
                    break;
                case "error":
                    this.pAlertType = "danger";
                    this.icon = "fa-triangle-exclamation";
                    break;
                case "success":
                    this.pAlertType = "success";
                    this.icon = "fa-circle-check";
                    break;
                default:
                    break;
            }
        }
    },
    mounted(){
        this.getAlertType();
    }

});

