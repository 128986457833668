<template>
    <div>
      <div v-if="lastLoggedInDate">
        {{ lastLoggedInDate }}
      </div>
      <div v-else>
        <a href="#" @click="resendInvitation(userId)">Resend Invitation</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['params'],
    data () {
      return {
        userId: this.params.data.userId,
        lastLoggedInDate: null
      }
    },
    mounted () {
      if (this.params.value) {
        this.lastLoggedInDate = new Date(this.params.value).toLocaleDateString()
      }
    },
    methods: {
      async resendInvitation (userId) {
        this.params.resendInvitation(userId);
      }
    }
  }
  </script>