
import useValidate from "@vuelidate/core";
import { required, helpers, sameAs, requiredIf } from '@vuelidate/validators';
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";

interface FormState {
  firstName: string;
  lastName: string;
  // baseWage: number;
  // goalWage: number;
  // position: number;
  isAdmin: boolean;
  email: string;
  //password: string;
}

export default defineComponent({
  name: "EditUser",
  props: {
    selectedUser: {
      required: true,
      type: Object,
    },
    initialUser: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      toast: useToast(),
      errorMessageCustom: "",
      pickedColor: "unspecifiedColor",
      performanceDisplay: "unspecified",
      colors : [
      "#FFB900",
      "#D83B01",
      "#B50E0E",
      "#E81123",
      "#B4009E",
      "#5C2D91",
      "#0078D7",
      "#00B4FF",
      "#008272",
      "#107C10",
    ]
    };
  }, 
  mounted(){
    console.log("mounted now")
    if (this.selectedUser.preferences) {
        let pref = JSON.parse(this.selectedUser.preferences);
        if (pref.avatarColor){
          this.pickedColor = pref.avatarColor;
        }
				if (pref.performanceDisplay){
          this.performanceDisplay =  pref.performanceDisplay;
        }        
		} 
  },
  updated() {
    if (this.selectedUser && this.userId != this.selectedUser.userId) {
      
      this.userId = this.selectedUser.userId;
      this.state.firstName = this.selectedUser.firstName;
      this.state.lastName = this.selectedUser.lastName;
      // this.state.goalWage = this.selectedUser.goalWage;
      // this.state.baseWage = this.selectedUser.baseWage;
      // this.state.position = this.selectedUser.position;
      this.state.email = this.selectedUser.email;
      this.state.isAdmin = this.selectedUser.isAdmin;
      
      
      //this.state.password = "";
      console.log("admin", this.selectedUser.isAdmin)
    }
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const initialUser = this.initialUser as any;
          console.log(initialUser);
          const user = await ApiService.get(`User/current`);
          if(user.status !== 200){
            this.toast.error(codes.error);
          }
          const currentUser = user.data as Models.CurrentUser;    
          if (!currentUser.preferences || currentUser.preferences == "") {
							currentUser.preferences = JSON.stringify({});
						}
          let newPrefs = {} as any 
          let hasPref = false         
          if (this.pickedColor != "unspecifiedColor") {
            newPrefs.avatarColor = this.pickedColor;
            hasPref = true;
          }
          if (this.performanceDisplay !== "unspecified") {
            newPrefs.performanceDisplay = this.performanceDisplay;
            hasPref = true;
          }

          let newPrefsString = hasPref  ? JSON.stringify(newPrefs) : null ;
						
          const payload: any = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,                   
            isClinicAdmin: this.state.isAdmin,
            email: this.state.email,
            preferences: newPrefsString,
          };
          if (this.selectedUser) {
            payload.userId = this.selectedUser.userId;
            delete payload.password
          }

          if (!payload) {
            return;
          } else {
            
            console.log(payload);
            const response = await ApiService.postData("User", payload, {});
            if (response.status === 200) {
              console.log(response.data);
              if (this.selectedUser) {
                this.$emit("closeEditUserForm");
              } else {
                this.$emit("closeAddUserForm");
              }
            } else {
              console.error(response);
              this.toast.error(codes.error)
            }
          }
        } catch (ex) {
          this.toast.error(codes.error)
          console.warn(ex);
        }
      }
    },
    discard() {
      console.log("discard");
      this.$emit("verifyParamsSubmitCompleted");
    },
    selectColor(color) {
				this.pickedColor = color;				
			},
  },

  setup(props, context) {
    
    // create user form
    console.log("props: ", props);
    let userId = 0;
    let state: FormState = reactive({
      email: "",
     // password: "",
      firstName: "",
      lastName: "",
      isAdmin: false,
      // baseWage: 0,
      // goalWage: 0,
      // position: 1,
    });

    if (props.selectedUser) {
      let user_to_edit = props.selectedUser as Models.UserToEdit;
      userId = user_to_edit.userId;
      let stateEdit: any = reactive({
        firstName: user_to_edit.firstName,
        lastName: user_to_edit.lastName,
        // baseWage: user_to_edit.baseWage,
        // goalWage: user_to_edit.goalWage,
        // position: user_to_edit.position,
        email: user_to_edit.email,
        password: "",
        isAdmin: user_to_edit.isAdmin,
      });
      
      state = stateEdit;
    }

    const rules: any = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First name is required.", required),
        },
        lastName: {
          required: helpers.withMessage("Last name is required.", required),
        },
        // baseWage: {
        //   required: helpers.withMessage("Base wage is required.", required),
        // },
        // goalWage: {
        //   required: helpers.withMessage("Goal wage is required.", required),
        // },
        // position: {
        //   required: helpers.withMessage("Position is required.", required),
        // },
        email: {
          required: helpers.withMessage("Email is required.", required),
        },
       // password: {
       //   required: helpers.withMessage("Password is required.", requiredIf(props.selectedUser == null)),
       // },

      };
    });

    let v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditUserForm");
    };

    return {
      //  create new user
      state,
      closeForm,
      v$,
      userId,
      //  end create new user
    };
  },
});
