
import { Auth0Client } from "@auth0/auth0-spa-js";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";


export default defineComponent({
    name: "Loader",
    props: {
        logo: String
    },
    data(){
        return {
            email: null as string | null
        }
    },
    methods: {
        logout() {
            this.$router.push("/logout");
        }
    },
    mounted(){
        console.log("auth", this.$auth);
        if (this.$auth.user){
            this.email = this.$auth.user.email;
        }
    },
    
});
