
import Avatar from "@/components/Avatar.vue";
import { defineComponent } from "vue";
import { format } from 'date-fns';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import parseISO from "date-fns/esm/fp/parseISO";
import {  ProcedurePlanResultStatus } from "@/ProcedureRules/ProductionCalculationType";

export default defineComponent({
  components: { Avatar },
  props: {
      providerName: {type: String},
      appointments: {type: Array<any>, required:true},
      date: {type: String},
      clinicName: {type: String},
      periodOfTime: {type: String}
  },
  data() {
    return {      
      ProcedurePlanResultStatus: ProcedurePlanResultStatus
    }
  },
  methods: {
    getScoreTitle(status: string, unitsPlanned? : Number|null, unitsCompleted? : number|null){
      if (unitsPlanned && unitsCompleted){
        if (unitsPlanned !== unitsCompleted){
          return `${status}. Had planned ${unitsPlanned} but completed ${unitsCompleted}`
        } else {
          return `${status}. Had planned ${unitsPlanned} and completed ${unitsCompleted}`
        }
      } else {
        return status;
      }     

    },
    downloadAsCSV(){
      let dataArray = [] as any;
      for (let j = 0; j < this.appointments.length; j++) {
        const app = this.appointments[j];
        const hasPlan = app.totalPlanned > 0;
        for (let i = 0; i < app.procedures.length; i++) {
          const procedure = app.procedures[i];                      
          let statusString = "";
          if (hasPlan){
            switch(procedure.status){
              case ProcedurePlanResultStatus.Completed: {
                statusString = "Completed";
                break;                            
              }
              case ProcedurePlanResultStatus.Exceeded: {
                statusString = "Exceeded";
                break;                            
              }
              case ProcedurePlanResultStatus.Missed: {
                statusString = "Not Completed";
                break;                            
              }
              case ProcedurePlanResultStatus.Partial: {
                statusString = `Partial (${procedure.completedUnits} of ${procedure.plannedUnits} units)`;
                break;                            
              }
            }        
          }
          if(i == 0){
            dataArray.push([
                this.dateFormat(new Date(app.startDate),'dd/MM/yyyy'),
                this.dateFormat(new Date(app.startDate), 'HH:mm'),
                `${app.durationInMinutes}`,
                app.patientInitials,
                statusString,
                procedure.completedCodes.length > 0 ? procedure.completedCodes.join(" ") : procedure.plannedCodes.join(" "),
                procedure.name.replace(",", ";"),
                procedure.actualMinutes ? procedure.actualMinutes : procedure.plannedMinutes,
                procedure.completedAmmount,
                procedure.plannedAmmount,
            ]);
          }else{
              dataArray.push([
                " ",
                " ",
                " ",
                " ",
                statusString,
                procedure.completedCodes.length > 0 ? procedure.completedCodes.join(" ") : procedure.plannedCodes.join(" "),
                procedure.name.replace(",", ";"),
                procedure.actualMinutes ? procedure.actualMinutes : procedure.plannedMinutes,
                procedure.completedAmmount,
                procedure.plannedAmmount,
              ]);
          }
          if(i == app.procedures.length -1){
              dataArray.push([
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",                
                "Total Completed",                
                "Total Planned",                
                "Success rate",                
                "Lost Revenue",
              ]);
              dataArray.push([
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",
                " ",                
                app.totalCompleted,
                app.totalPlanned,
                app.successPercentage,
                Math.round(app.totalPlanned - app.totalCompleted),
              ]);              
              dataArray.push([" "," "," "," "," ",""," "]);
          }
        }
      }
      const csvString = [
      ["Date", "Time", "Duration (min)","Patient","Score","Code","Service","Minutes", "Charge", "Planned"],
      ...dataArray
    ]
    .map(e => e.join(",")) 
    .join("\n");

      var csv_file, download_link;
      csv_file = new Blob([csvString], {type: "text/csv"});
      download_link = document.createElement("a");
      download_link.download = `Productivty report(${this.providerName}).csv`;
      download_link.href = window.URL.createObjectURL(csv_file);
      download_link.style.display = "none";
      document.body.appendChild(download_link);
      download_link.click();
      document.body.removeChild(download_link);

    },
    downloadCustom() {
      let customFiltered = this.appointments.sort(this.sortAscending);
      const doc = new jsPDF("landscape", "mm", "letter");
      doc.addImage("/RNA180Logo_Transparent BG.png", "PNG", 12, 8, 15, 15);
      doc.text("Productivity Report ("+this.providerName+")", 30, 18);
      doc.text(this.clinicName ?? "", 265, 18, { align: "right"});
      doc.setTextColor("#777");
      doc.setFontSize(10);
      let periodStr = `${this.dateFormat(parseISO(customFiltered[0].startDate), "dd/MM/yyyy")} - ${this.dateFormat(parseISO(customFiltered[customFiltered.length-1].startDate), "dd/MM/yyyy")}`
      doc.text(periodStr, 30, 23);
      let tableOptions = {
        styles: { fontSize: 7 },
        head: [["Date", "Time", "Duration","Patient","Code","Service","Charge"]],
        body: [] as any,
        startY: 28,
      }
      for (let j = 0; j < this.appointments.length; j++) {
        const app = this.appointments[j];
        for (let i = 0; i < app.procedures.length; i++) {
          const procedure = app.procedures[i];
          if(i == 0){
            tableOptions.body.push([
                this.dateFormat(new Date(app.startDate),'dd/MM/yyyy'),
                this.dateFormat(new Date(app.startDate), 'HH:mm'),
                `${app.durationInMinutes} min`,
                app.patientInitials,
                app.totalCompleted,
                procedure.adaCode,
                procedure.name,
                procedure.ammount
            ]);
          }else{
              tableOptions.body.push([
                " ",
                " ",
                " ",
                " ",
                procedure.adaCode,
                procedure.name,
                procedure.ammount,
              ]);
          }
          if(i == app.procedures.length -1){
              tableOptions.body.push([
                " ",
                " ",
                " ",
                " ",
                " ",
                "Total: ",
                app.totalCompleted,
              ]);
              tableOptions.body.push([" "," "," "," "," ",""," "]);
          }
        }
      }

      // tableOptions.body.push([])
      autoTable(doc, tableOptions);
      if (!(customFiltered || []).length) {
        doc.setFont('sans-serif', "bold");
        doc.setTextColor("#B22222");
        doc.setFontSize(14);
        doc.text("No Records Found", 15, 50);
      }
      doc.save(`Productivity report(${this.providerName}).pdf`);
    },
    dateFormat(date, formatString) {
        return format(date, formatString);
    },
    sortAscending(a, b) {
      var aDate = new Date(a["startDate"]);
      var bDate = new Date(b["startDate"]);
      return aDate.getTime() - bDate.getTime();
    }
  },
  setup() {},
});
