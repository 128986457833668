import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cef214e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = { class: "row px-10 py-3" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  key: 0,
  class: "toolbar d-flex justify-content-center"
}
const _hoisted_5 = ["id", "onChange"]
const _hoisted_6 = ["id", "onChange"]
const _hoisted_7 = {
  class: "form-check-label",
  for: "flexRadioDefault1"
}
const _hoisted_8 = {
  key: 1,
  class: "row px-10",
  style: {"height":"60vh"}
}
const _hoisted_9 = {
  key: 0,
  class: "col-4",
  style: {"margin-bottom":"30px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.filters)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (fil, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "form-check mx-5",
                  key: index
                }, [
                  (index === 0)
                    ? (_openBlock(), _createElementBlock("input", {
                        key: 0,
                        class: "form-check-input",
                        type: "radio",
                        name: `Provider Productivity`,
                        id: `${fil.name}${index}`,
                        onChange: ($event: any) => (_ctx.filterChange(fil.val)),
                        checked: ""
                      }, null, 40, _hoisted_5))
                    : (_openBlock(), _createElementBlock("input", {
                        key: 1,
                        class: "form-check-input",
                        type: "radio",
                        name: `Provider Productivity`,
                        id: `${fil.name}${index}`,
                        onChange: ($event: any) => (_ctx.filterChange(fil.val))
                      }, null, 40, _hoisted_6)),
                  _createElementVNode("label", _hoisted_7, _toDisplayString(fil.name), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.summarySeries.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.summarySeries.length > 0 && _ctx.summarySeries[0].data.length > 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_apexchart, {
                  width: "100%",
                  height: "100%",
                  type: "bar",
                  options: _ctx.summaryChartOptions,
                  series: _ctx.summarySeries
                }, null, 8, ["options", "series"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(`col-${_ctx.summarySeries.length > 0 && _ctx.summarySeries[0].data.length > 2 ? 8 : 12}`),
            style: {"margin-bottom":"30px"}
          }, [
            _createVNode(_component_apexchart, {
              width: "100%",
              height: "100%",
              type: "line",
              options: _ctx.chartOptions,
              series: _ctx.dataSeries
            }, null, 8, ["options", "series"])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}