export class ProcedureCalculator {

    constructor() {

    }

    calculateProceduresFromMinutes(procedureOptions, totalMinutes: number, isGradual? : boolean): CalculatedProcedures {
        if (typeof isGradual == 'undefined'){
            isGradual = false;
        }
        let price = 0;
        let adaCodes = [];
        let commission = 0;
        if (!procedureOptions || procedureOptions.length === 0) {
            return new CalculatedProcedures([], price, commission, null) ;
        }
        if (totalMinutes === 0) {
            return new CalculatedProcedures([], price, commission, null) ;
        }        

        //asc sort - Return -1 if the first element is greater than the second, otherwise return 1.
        let workingProcedureOptions = procedureOptions.filter(a => a.minutes); ////need to remove the additional unit thingy;
        
        

        workingProcedureOptions.sort((a, b) => parseFloat(a.minutes) < parseFloat(b.minutes) ? -1: 1); //sort ascending
        //first is there a half unit        
        const halfUnit = workingProcedureOptions.find(a => parseFloat(a.minutes) === 7.5); 
        let expandedArray = new Array<any>();
        if (halfUnit){
            //let's create an expanded array?
            
            
            //take max and find how many multipel it is then add 1
            let maxMinutes = parseFloat(workingProcedureOptions[workingProcedureOptions.length -1].minutes);
            let numberOfOptions = (maxMinutes / halfUnit.minutes) + 1            
            for (let i = 0;i < numberOfOptions;i++){
                let minutes = (i+1) * parseFloat(halfUnit.minutes)
                let previousMinutes = (i) * parseFloat(halfUnit.minutes)
                let procOption = workingProcedureOptions.find(a => parseFloat(a.minutes) === minutes); 
                if (procOption){
                    expandedArray.push({minutes: procOption.minutes, adaCodes: [procOption.adaCode], ammount: procOption.ammount, 
                        commissionPercentage: procOption.commissionPercentage})
                } else {
                    let previousOpt = workingProcedureOptions.find(a => parseFloat(a.minutes) === previousMinutes)
                    expandedArray.push({minutes: previousOpt.minutes + halfUnit.minutes, adaCodes: [previousOpt.adaCode, halfUnit.adaCode], ammount: previousOpt.ammount + halfUnit.ammount, 
                        commissionPercentage: previousOpt.commissionPercentage})
                }
            }
        }
        else { 
            for(const procOption of workingProcedureOptions){
                expandedArray.push({minutes: procOption.minutes, adaCodes: [procOption.adaCode], 
                    ammount: procOption.ammount, commissionPercentage: procOption.commissionPercentage})
            }
        }
        //just pick the closest highest one
        let highestOption = expandedArray[0];
        for(let i = 0; i< expandedArray.length; i++) {
            if (i === 0){
              if (totalMinutes <= parseFloat(expandedArray[0].minutes)){
                highestOption = expandedArray[0];
                break;
              }
            }
            else {
                if (totalMinutes > parseFloat(expandedArray[i-1].minutes) && totalMinutes <= parseFloat(expandedArray[i].minutes)){
                    highestOption = expandedArray[i];
                    break;
                }
            }                        
        }
        if (!highestOption){
            highestOption = expandedArray[expandedArray.length - 1];
        }

        let ammount : number;
        let maxMinutes : number;
        maxMinutes = maxMinutes = expandedArray[expandedArray.length-1].minutes;
        if (isGradual){
            if (highestOption.minutes){
                ammount = (highestOption.ammount / highestOption.minutes) * totalMinutes; 
                ammount = Number(ammount.toFixed(0));                                
            } else {
                throw "NO MINUTES";
            }
        } else {
            
            ammount = highestOption.ammount; 
        }

        commission = ammount * highestOption.commissionPercentage;
        ammount = Number(ammount.toFixed(2))
        return new CalculatedProcedures(highestOption.adaCodes, ammount, commission, maxMinutes) ;         
    }
}

export class CalculatedProcedures {
    adaCodes: Array<string>
    fee: number
    commission: number
    maxMinutes: number | null

    // constructor() {
    //     this.adaCodes = [];
    //     this.fee = 0;
    //     this.commission = 0;
    // }

    constructor(adaCodes: Array<string>, fee: number, commission: number, maxMinutes: number | null ) {
        this.adaCodes = adaCodes;
        this.fee = fee;
        this.commission = commission;
        this.maxMinutes = maxMinutes;
    }
}

