
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Avatar from "@/components/Avatar.vue";
import EditProfile from '@/components/Profile/EditProfile.vue'
import EditHygenistProfile from '@/components/Profile/EditHygenistProfile.vue'
import { useStore } from "vuex";
import * as bootstrap from 'bootstrap';
import ApiService from '@/core/services/ApiService';
export default defineComponent({
  name: "Profile",
  props: {},
  components: {
    Avatar,
    EditProfile,
    EditHygenistProfile,
  },
  data() {
    return {
      saveAll: false,
      showHygenistEdit: true, 
      edit: false,
      pickedColor: "",
    };
  },
  mounted() {
    // SET PICKED COLOR HERE
    setCurrentPageTitle("Profile");
  },
  methods: {
    saved(){
      this.saveAll = false;
    },
    changeAvatarColor(color){
      this.pickedColor = color;
    },
  },
    computed: {   
    userInfo() : any {
      let user = this.$store.getters.currentUser;
      
      if (user){    
        let currentUserInfo = {
          initials: user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase(),
          fullName: user.firstName + " " + user.lastName,
          avatarColor: "#0078D7"
        };
        
        if (user.preferences){
          let prefs = JSON.parse(user.preferences)
          if (prefs.avatarColor){
              currentUserInfo.avatarColor = prefs.avatarColor;
          }
          
        }
        return currentUserInfo;
      } else {
        return null;
      }
    }    
  }  ,
  setup() {
    const store = useStore();
    const initialUser = store.getters.currentUser;
    console.log(initialUser);

    const colors = [
      "#FFB900",
      "#D83B01",
      "#B50E0E",
      "#E81123",
      "#B4009E",
      "#5C2D91",
      "#0078D7",
      "#00B4FF",
      "#008272",
      "#107C10",
    ];

    return {
      initialUser,
      colors,
    };
  },
});
