
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar, { CalendarApi } from "@fullcalendar/vue3";
import { createApp, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import dayGridPlugin from "@fullcalendar/daygrid";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
    name: "CalendarController",
    components: { FullCalendar },
    data() {
        return {
            loading: false,
            calendarKey: 1,
            calendarOptions: {
                //callendar options in extendedProps we need to add ishtml to yes and add a tasksarray tasks array will show as list in callendar and as a list in a table in modal under opted services
                plugins: [dayGridPlugin],
                headerToolbar: {
                    left: "prev,next,today",
                    center: "title",
                    right: "",
                },
                eventDidMount: function(info) {
                    info.el.title = info.event.extendedProps.description;                    
                },                               
                timeZone: "America/New_York",
                initialView: "dayGridMonth",                
                nowIndicator: true,
                
                height: "calc(100vh - 245px)", //makes the calendar with bigger height to have more room for tiny events                
                expandRows: true,                                                
                events: [] as any,                
            }
        };
    },
    async mounted() {
        setCurrentPageTitle("Insights");
        (document.getElementsByClassName("fc-toolbar-chunk")[2] as any).style.width = 
		(document.getElementsByClassName("fc-toolbar-chunk")[0] as any).offsetWidth + "px"

        try {
            this.loading = true;
            let today = new Date()
            let endDate = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
			let params = {
					startDate: today.toISOString().split("T")[0],
					endDate: endDate.toISOString().split("T")[0]
					
				};

            let res = await ApiService.query("appointment/analyze", { params });
            this.loading = false;
            // console.log(res.data);
            if (res) {
                for (let appt of res.data) {
                    if (appt.almostDueProcedures.length === 0 && appt.noDoctor === null && appt.isBirthday === false && appt.appointmentTooLongForChild === null){
                        continue;
                    }
                    let description = "";
                    //public record AlmostDueProcedures(int ProcedureRuleTypeId, string ProcedureName, DateTime DueDate, int InDays);
                    for(let dueProcedure of appt.almostDueProcedures){
                        description += `${dueProcedure.procedureName} is due in ${dueProcedure.inDays} day${dueProcedure.inDays === 1 ? '' : 's'} on ${dueProcedure.dueDate.split("T")[0]}\n`;
                    }

                    if (appt.noDoctor){
                        description += `No doctor in the office. Appointment should be less than ${appt.noDoctor.recommendedMinutes}min.\n`
                    }

                    if (appt.appointmentTooLongForChild){
                        description += `Patient is less than 12yo. Appointment should be less than ${appt.appointmentTooLongForChild.recommendedMinutes}min.\n`
                    }

                    if (appt.isBirthday){
                        description += `Happy Birthday`
                    }
                    this.calendarOptions.events.push({                  
                        title: appt.patientInitials,
                        start: appt.date,
                        description: description,
                        providerId: appt.providerId,
                        hasNoDoctor: appt.noDoctor !== null,
                        hasAlmostDueProcs:  appt.almostDueProcedures.length > 0,
                        isBirthday:  appt.isBirthday,
                        appointmentTooLongForChild: appt.appointmentTooLongForChild
                    });
                }
                //this.calendarKey++;

            }
            if (res.status !== 200) {
                //this.toast.error(codes.error)
            }
        } catch (e) {
            console.log(e)
            //this.toast.error(codes.error)
        }
    }
});
