<template>
	<div class="row d-flex justify-content-center">
		<form @submit.prevent="findAdaCode">
			<div class="input-group mb-3">
				<input type="text" v-model="customAdaToSearchFor" class="form-control" placeholder="CDA code to search for" aria-label="CDA code" aria-describedby="basic-addon2" />
				<div class="input-group-append" title="Click to search for CDA code" @click="findAdaCode">
					<span class="input-group-text h-100 cursor-pointer" id="basic-addon2"><i class="fa-solid fa-magnifying-glass"></i></span>
				</div>
			</div>
		</form>
		<hr />
		<div class="row results">
			<p v-if="errorText" class="text-danger">
				{{ errorText }}
			</p>
			<div class="row" v-if="returnedAdaObject">
				<div class="col-10">
					<p>
						{{customAdaToSearchFor}} <br> {{ returnedAdaObject.abbrevDescription }}: {{ returnedAdaObject.description }} <br> ${{ returnedAdaObject.fee }}
					</p>
				</div>
				<div class="col-2">
					<i class="fas fa-plus-circle icon-font cursor-pointer mt-5" style="color: rgb(38, 205, 85)" @click="addCustomAda"> </i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ApiService from "@/core/services/ApiService";
	import InlineSvg from "vue-inline-svg";
  import { useToast } from "vue-toastification";
  import { codes } from "@/Codes/Codes.ts";

	export default {
		components: { InlineSvg },
        props: { appointment: {required: true},},

		data() {
			return {
        toast: useToast(),
				customAdaToSearchFor: "",
				returnedAdaObject: null,
				errorText: null,
			};
		},
		methods: {
			async findAdaCode() {
        try{
          let res = await ApiService.get(`/Appointment/Ada/${this.customAdaToSearchFor}/${this.appointment.feeScheduleId}`);
          if (res.status === 200) {
            console.log(res.data);
            this.returnedAdaObject = res.data;
          } else {
            this.toast.error(res.statusText || codes.error);
          }
        }catch (e) {
          this.toast.error(codes.error);
        }
			},
			addCustomAda() {
				this.returnedAdaObject.adaCode = this.customAdaToSearchFor;
				this.$emit("addCustomAda", this.returnedAdaObject);
			},
		},
		async mounted() {},
	};
</script>
<style lang="scss" scoped>
.results{
    background: #80808030;
    border-radius: 10px;
    padding: 5px;
    margin-left: 2px;
}
</style>
