<template>
  <div class="card">
    <div class="card-body">
      <h2 class="mb-5 fw-bolder">Daily Breakdown</h2>
      <div class="d-flex flex-row mb-5">
        <div class="d-flex align-items-stretch">
          <div class="d-flex align-items-center grey-bg py-2 px-7">
            <div class="symbol symbol-50px me-5">
              <!-- <img
                src="/media/avatars/150-26.jpg"
                class="rounded-corners"
                alt=""
              /> -->
            <Avatar  :initials="`${name.split(' ')[0].charAt(0)}${name.split(' ')[1].charAt(0)}`"/>

            </div>
            <div class="flex-grow-1">
              <span class="text-muted d-block fw-bold">Name</span>
              <a href="#" class="text-dark fw-bolder text-hover-primary fs-6"
                >{{name}}</a
              >
            </div>
          </div>
        </div>

        <div class="mx-2 d-flex align-items-stretch" v-if="baseWage">
          <div class="d-flex align-items-center grey-bg py-2 px-7">
            <div class="symbol symbol-50px me-5">
              <inline-svg
                class="fs-30-px"
                src="/media/icons/duotone/Shopping/Dollar.svg"
              />
            </div>
            <div class="flex-grow-1">
              <span class="text-muted d-block fw-bold">Base Wage</span>
              <a href="#" class="text-dark fw-bolder text-hover-primary fs-6"
                >${{baseWage}}</a
              >
            </div>
          </div>
        </div>

        <div class="mx-2 d-flex align-items-stretch" v-else>
          <div class="d-flex align-items-center pulsingError py-2 px-7">
            <div class="symbol symbol-50px me-5 svg-icon svg-icon-2tx svg-icon-primary">
              <inline-svg
                class="fs-30-px"
                src="/media/icons/duotone/Code/warning-2.svg"
              />
            </div>
            <div class="flex-grow-1">
              <span class="text-muted d-block fw-bold">Base Wage Unavailable</span>
              <router-link to="/settings/providers" class=" fw-bolder text-hover-primary fs-6">
                Configure
              </router-link>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-stretch">
          <div class="d-flex align-items-center grey-bg py-2 px-7">
            <div class="symbol symbol-50px me-5">
              <!-- <inline-svg
                class="fs-30-px"
                src="\media\icons\duotone\Interface\Calendar.svg"
              /> -->
              <i
                class="fas fa-calendar-alt fs-30-px mx-2"
                style="color: #009ef7;"
              ></i>
            </div>
            <div class="flex-grow-1">
              <span class="text-muted d-block fw-bold">Payment Period</span>
              <a href="#" class="text-dark fw-bolder text-hover-primary fs-6"
                >{{paymentPeriod}}</a
              >
            </div>
          </div>
        </div>
        <div class=" mx-2 d-flex align-items-stretch cursor-pointer" v-if="payrollArray && payrollArray.length > 0" >
          <div class="d-flex align-items-center grey-bg py-2 px-7">
            <div class="symbol">
              <inline-svg
              title="Click to download PDF report." 
              @click="downloadCustom()"
              style="width: 80%;"
                src="/media/svg/files/pdf.svg"
              />
            </div>
          </div>
        </div>
                <div class="col mx-2 d-flex align-items-stretch " v-if="payrollArray && payrollArray.length > 0"  >
          <div class="d-flex align-items-center grey-bg py-2 px-7">
            <div class="symbol">
              <i class="fa-solid fa-file-csv cursor-pointer" style="font-size: 45px" @click="downloadAsCSV()" title="Click to download CSV report."></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row tables" v-if="baseWage">
        <div class="col-8">
          <table class="table w-100">
            <thead>
              <tr class="fw-bold fs-5 text-gray-800">
                <th>Date</th>
                <th>Day</th>
                <th>In</th>
                <th>Out</th>
                <th>In</th>
                <th>Out</th>
                <th>In</th>
                <th>Out</th>
                <th>Base Pay</th>
<!--                <th>Bonus Total</th>-->
                <!--<th>Commission Total</th>-->
                <th>Cost (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item, index in payrollArray" :key="index">
                <td>{{ formatDate(item.date) }}</td>
                <td>{{ dayOfWeek(item.date) }}</td>
                <td class="timestamp">{{ item.time1 ? getTimestamp(item.time1) : "-" }}</td>
                <td class="timestamp">{{ item.time2 ? getTimestamp(item.time2) : "-" }}</td>
                <td class="timestamp">{{ item.time3 ? getTimestamp(item.time3) : "-" }}</td>
                <td class="timestamp">{{ item.time4 ? getTimestamp(item.time4) : "-" }}</td>
                <td class="timestamp">{{ item.time5 ? getTimestamp(item.time5) : "-" }}</td>
                <td class="timestamp">{{ item.time6 ? getTimestamp(item.time6) : "-" }}</td>
                <td>${{ item.basePay?.toFixed(2) }}</td>
                <td>{{ item.dailyWageToProductionPercentage ? `${item.dailyWageToProductionPercentage}%` : "" }}</td>
<!--                <td>${{ Math.ceil(item.bonus * 100) / 100 }}</td>-->
                <!--<td>${{ item.commissionTotal?.toFixed(2) }}</td>-->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-4">
          <table class="table w-100">
            <thead>
              <tr class="fw-bold fs-5 text-gray-800">
                <th>
                  <span
                    ><inline-svg
                      src="/media/material-access-time.svg"
                      width="10%"
                  /></span>
                  Total Hours
                </th>
                <th>
                  <span
                    ><inline-svg
                      width="23%"
                      class="fs-30-px"
                      src="/media/icons/duotone/Shopping/Dollar.svg"
                  /></span>
                  Bonus Pay
                </th>
                <th>
                  <span
                    ><inline-svg
                      style="width: auto"
                      class="fs-30-px"
                      src="/media/icons/duotone/Shopping/Dollar.svg"
                  /></span>
                  Total Pay
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item, index in payrollArray" :key="`_${index}`">
              <td>{{ getHours(item.totalMinutesClocked) }}</td>
              <td>${{ item.bonus?.toFixed(2) }}</td>
              <td>${{ item.totalPay?.toFixed(2) }}</td>
              </tr>

              <span class="total">Total</span>
              <tr>
                <td><p>{{totalHours}}</p></td>
                <td><p>${{totalBonus}}</p></td>
                <td><p>${{totalWage}}</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-else>
    <p class="text-danger">Base wage is not specified for "{{`${name}`}}". Please configure the base wage under provider settings</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import Avatar from "@/components/Avatar.vue";
import { minutesToHours, format, sub } from "date-fns";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default defineComponent({
  components: {
    InlineSvg,Avatar,
  },
  props: {
    name: {type: String},
    baseWage: {type: Number},
    paymentPeriod: {type: String},
    payrollArray: {type: Array},
    totalHours: {type: String},
    totalWage: {type: Number},
    totalBonus: {type: Number},
    clinicName: {type: String},
    isBonus: {type: Boolean}
  },
  methods: {
        downloadAsCSV(){
      let dataArray = [];
            this.payrollArray?.forEach(pay=>{
        dataArray.push([
          this.formatDate(pay.date),
          this.dayOfWeek(pay.date),
          this.getTimestamp(pay.time1),
          this.getTimestamp(pay.time2),
          this.getTimestamp(pay.time3),
          this.getTimestamp(pay.time4),
          this.getTimestamp(pay.time5),
          this.getTimestamp(pay.time6),
          `$${pay.basePay?.toFixed(2)}`,
          `$${pay.commissionTotal?.toFixed(2)}`,
          this.getHours(pay.totalMinutesClocked),
          `$${pay.bonus?.toFixed(2)}`,
          `$${pay.totalPay?.toFixed(2)}`,
        ]);
      })
      dataArray.push(["", "","", "", "", "", "", "", "", "Total: ", `${this.totalHours}`,`${this.totalBonus}`, `$${this.totalWage}`])

      const csvString = [
      ["Date", "Day","In","Out","In","Out","In","Out", "Base total", "Commission total", "Total hours", "Bonus","Total pay"],
      ...dataArray
    ]
    .map(e => e.join(",")) 
    .join("\n");

      var csv_file, download_link;
      csv_file = new Blob([csvString], {type: "text/csv"});
      download_link = document.createElement("a");
      download_link.download = `Payroll report(${this.name}).csv`;
      download_link.href = window.URL.createObjectURL(csv_file);
      download_link.style.display = "none";
      document.body.appendChild(download_link);
      download_link.click();
      document.body.removeChild(download_link);

    },
        downloadCustom() {
      let customFiltered = this.payrollArray;
      const doc = new jsPDF("landscape", "mm", "letter");
      doc.addImage("/RNA180Logo_Transparent BG.png", "PNG", 12, 8, 15, 15);
      doc.text("Payroll Report ("+this.name+")", 30, 18);
      doc.text(this.clinicName ?? "", 265, 18, "right");
      doc.setTextColor("#777");
      doc.setFontSize(10);
      doc.text(this.paymentPeriod ?? "", 30, 23);
      let tableOptions = {
        styles: { fontSize: 7 },
        head: [["Date", "Day","In","Out","In","Out","In","Out", "Base total", "Commission total", "Total hours", "Bonus", "Total pay"]],
        body: [],
        startY: 28,
      }
      this.payrollArray?.forEach(pay=>{
        tableOptions.body.push([
          this.formatDate(pay.date),
          this.dayOfWeek(pay.date),
          this.getTimestamp(pay.time1),
          this.getTimestamp(pay.time2),
          this.getTimestamp(pay.time3),
          this.getTimestamp(pay.time4),
          this.getTimestamp(pay.time5),
          this.getTimestamp(pay.time6),
          `$${pay.basePay?.toFixed(2)}`,
          `$${pay.commissionTotal?.toFixed(2)}`,
          this.getHours(pay.totalMinutesClocked),
          `$${pay.bonus?.toFixed(2)}`,
          `$${pay.totalPay?.toFixed(2)}`,
        ]);
      })
      tableOptions.body.push(["", "","", "", "", "", "", "", "", "Total: ", `${this.totalHours}`,`${this.totalBonus}`, `$${this.totalWage}`])
      autoTable(doc, tableOptions);
      if (!(customFiltered || []).length) {
        doc.setFont('sans-serif', "bold");
        doc.setTextColor("#B22222");
        doc.setFontSize(14);
        doc.text("No Records Found", 15, 50);
      }
      doc.save(`Payroll report(${this.name}).pdf`);
    },
    formatDate(date) {
      return format(new Date(date), "yyyy-MM-dd");
    },
    dayOfWeek(date) {
      return format(new Date(date), "EEEE");
    },
    getHours(totalMinutes) {      
      var hours = (totalMinutes / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + ":" + String(rminutes).padStart(2,'0');
    },
    getTimestamp(timestamp) {
      let time = format(new Date(timestamp), "H:mm");
      if (time == "0:00") return "-";
      return time;
    }
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
$faded-grey: rgba(128, 128, 128, 0.1);
$green: #50cd89;
$red: rgb(205, 80, 80);
$fadedRed: rgba(205, 80, 80, 0.1);
.text-good{
  color: $green;
}
.text-poor{
  color: $red;
}
.rounded-corners {
  border-radius: 50px;
}
.grey-bg {
  background: $faded-grey;
  border-radius: 5px;
}
.tables {
  div:nth-child(1) {
    margin-right: 0px;
    padding-right: 0px;
    table {
      tbody {
        td:first-child {
          padding-left: 5px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }
  }
  div:nth-child(2) {
    margin-left: 0px;
    padding-left: 0px;
    table {
      th {
        width: fit-content !important;
      }
      td {
        width: fit-content !important;
      }
      border-radius: 5px;
      padding-right: 5px;
      box-shadow: 0px 0px 7px 2px #00000061;
      th:first-child {
        padding-left: 10px;
      }
      th:last-child {
        padding-left: 0px;
        padding-right: 10px;
      }
      tbody {
        span.total {
          position: relative;
          left: -45%;
          top: 30px;
          color: black;
          font-weight: bold;
        }
        tr:last-child {
          td {
            background: white !important;
            padding: 0px 4px 4px 5px !important;
            p {
              background: rgb(80, 205, 137);
              font-weight: bold;
              color: white;
              width: 100%;
              margin-bottom: 0px;
              padding: 5px;
              border-radius: 5px;
            }
          }
        }
        td {
          text-align: center;
        }
      }
    }
  }
}
table {
  border-collapse: separate;
  border-spacing: 0 2.5px !important;
  thead {
    th {
      white-space: nowrap;
      overflow: hidden;
      padding: 10px;
      height: 55px !important;
    }
    svg {
      height: inherit;
    }
  }
  tr {
    // td:nth-child(11){
    //   box-shadow: -9px 0px 4px -2px #00000038;
    // }
    td {
      background: $faded-grey;
      color: grey;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
td.timestamp {
  width: 3%;
}
.pulsingError{
    background-color: $faded-grey;
    border-radius: 5px;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: 2;
}
@keyframes color {
  0% {
    background-color: $fadedRed;
  }
  50% {
    background-color: $red;
  }
  100% {
    background-color: $fadedRed;
  }
}
</style>
