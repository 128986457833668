
	import { defineComponent } from "vue";
	import AgendaView from "@/components/Calendar/AppointmentComponents/AgendaView.vue";
	import ChecklistView from "@/components/Calendar/AppointmentComponents/ChecklistView.vue";
	import AdaCodeCustomSearch from "@/components/Calendar/AppointmentComponents/AdaCodeCustomSearch.vue";
	import AppointmentInformation from "@/components/Calendar/AppointmentComponents/AppointmentInformation.vue";
	import AppointmentInsuranceCoverageHistory from "@/components/Calendar/AppointmentComponents/AppointmentInsuranceCoverageHistory.vue";
	import AppointmentEarnings from "@/components/Calendar/AppointmentComponents/AppointmentEarnings.vue";
	import AppointmentTimings from "@/components/Calendar/AppointmentComponents/AppointmentTimings.vue";
	import AdaView from "@/components/Calendar/AppointmentComponents/AdaView.vue";
	import ChargeView from "@/components/Calendar/AppointmentComponents/ChargeView.vue";
	import ServiceView from "@/components/Calendar/AppointmentComponents/ServiceView.vue";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import ApiService from "@/core/services/ApiService";
	import { differenceInMinutes, intervalToDuration } from "date-fns";
	import { useStore } from "vuex";
	import Modal from "@/components/Modal.vue";
	import PreviewAppointmentModal from "@/components/Calendar/AppointmentComponents/PreviewAppointmentModal.vue";
	import InlineSvg from "vue-inline-svg";
	import compareAsc from "date-fns/esm/compareAsc/index.js";
	import { table } from "table";
  	import { useToast } from "vue-toastification";
  	import { codes } from "@/Codes/Codes";
  	import { ProcedureCalculator, CalculatedProcedures } from './AppointmentComponents/ProcedureCalculator';
	import odspCriteria  from '@/ProcedureRules/OdspCriteria.json';
	import { LOCAL_STORAGE_CONST } from "@/Models/local-storage.model";
	import { AppointmentEndpoints } from "@/core/api/AppointmentEndpoints";
	import { DateHelper } from "@/Utilities/DateHelper";
	import {  ProcedureRuleType } from "@/ProcedureRules/ProductionCalculationType";



	export default defineComponent({
		components: { Modal, InlineSvg, AppointmentInformation, AppointmentInsuranceCoverageHistory, AppointmentEarnings, 
			AppointmentTimings, AdaView, ServiceView, ChargeView, AdaCodeCustomSearch, AgendaView, ChecklistView, PreviewAppointmentModal },
		data() {
			return {
        		toast: useToast(),
				noAppointmentFound: false as boolean,
				showDescription: true as boolean,
				customAdaModalVisible: false as boolean,
				recommended_filter: true as boolean,
				odspCriteria: odspCriteria as any,
				odspCheckboxList: [] as any,
				showOdspCriteriaModal: false as boolean,
				odspCriteriaModalObj: null as any,
				parentType8: null as any,
				type7Object: null as any,
				showType7Modal: false as boolean,
				savedAppointmentInfo: null as any,
				dmsVisible: false as boolean,
				checkEventObject: null as any,
				appointment: null as any,
				appointmentOrig: null as any,
				procedureRuleTypeOptions: null as any,
				type5Object: null as any,
				showType5Modal: false as boolean,
        		providerCommission: 0 as any, 
				teeth: {
					first: [18, 17, 16, 15, 14, 54, 55],
					second: [28, 27, 26, 25, 24, 64, 65],
					third: [38, 37, 36, 35, 34, 74, 75],
					fourth: [48, 47, 46, 45, 44, 84, 85],
				},
				selectedProcedureTypeIds: [] as any,
				appointmentProductionInDollars: 0 as any,
				applicableTime: 0 as any,
				displayPreviewModal: false as boolean,
				markAsCompleted: false as boolean,
				prePlanTitle: null as any,	
				prePlanApplied: false	as boolean,		
				originDate: null as any,	
				procedureRuletypes: new Array<ProcedureRuleType>() as Array<ProcedureRuleType>, 
				procedureRuleTypesById: new Map<number, ProcedureRuleType>() as Map<number, ProcedureRuleType>,
				patientHistory: [] as any,
				providersInvolved: [] as any,
			};
		},
		computed: {
			allowSaveAndNext() : boolean {				
				return this.getNextAppointment() !== null;
			},
			showPreviewAlert() : boolean {
				return this.appointment.totalCompleted === 0;
			},
			unusedType7: function () {
				let recs = this.appointment.recommendations.filter((x) => x.calculationType == 7 && x.procedureRuleTypeId != 35 && !this.selectedProcedureTypeIds.includes(x.procedureRuleTypeId));
				if (this.recommended_filter == true) {
					return recs.filter((x) => x.isRecommended == true);
				} else {
					return recs;
				}
			},
			groupRecommendationsByColor: function () {
				const groupSortOrder = [{color: "yellow", order: 1}, {color: "lightyellow", order: 2}, {color: "lavender", order: 3}, {color: "violet", order: 4}, {color: "orange", order:5}, {color: "grey", order:6}, {color: "red", order:7}];
				let self = this;
				let array = this.appointment.recommendations || [];
				let grouped = this.groupBy(array, (rec) => rec.color);				
				let colors = [] as any;
				let groupedArray = []  as any;
				for (let i = 0; i < array.length; i++) {
					if (!colors.find((x) => x === array[i].color)) {
						colors.push(array[i].color);
					}
				}
				let i = 0;
				//chatgpt generated sort 
				const sortOrder = groupSortOrder.reduce((acc, {color, order}) => {
					acc[color] = order;
					return acc;
				}, {});
				colors.sort((a, b) => sortOrder[a] - sortOrder[b]);
				///
				colors.forEach((color) => {
					if (i === 0) {
						groupedArray = grouped.get(color).sort(function (a, b) {
							let aScheduleOrder = !a.scheduleOrderBeginning && !a.scheduleOrderEnd ? 1000 :  (a.scheduleOrderBeginning ?? 0) + (100000*(a.scheduleOrderEnd ?? 0))
        					let bScheduleOrder = !b.scheduleOrderBeginning && !b.scheduleOrderEnd ? 1000 : (b.scheduleOrderBeginning ?? 0) + (100000*(b.scheduleOrderEnd ?? 0))            
							if (aScheduleOrder == bScheduleOrder) {
								return self.compareStrings(a.name, b.name);
							} else{
								return aScheduleOrder - bScheduleOrder;
							}							
						});
					} else {
						groupedArray = groupedArray.concat(
							grouped.get(color).sort(function (a, b) {
								let aScheduleOrder = !a.scheduleOrderBeginning && !a.scheduleOrderEnd ? 1000 :  (a.scheduleOrderBeginning ?? 0) + (100000*(a.scheduleOrderEnd ?? 0))
								let bScheduleOrder = !b.scheduleOrderBeginning && !b.scheduleOrderEnd ? 1000 : (b.scheduleOrderBeginning ?? 0) + (100000*(b.scheduleOrderEnd ?? 0))            
								if (aScheduleOrder == bScheduleOrder) {
									return self.compareStrings(a.name, b.name);
								} else{
									return aScheduleOrder - bScheduleOrder;
								}	
							})
						);
					}
					i++;
				});
				return groupedArray;
			}
		},
		methods: {
			async toggleAttributedProvider(procedureId: number, providerId: string) {
				if (!providerId)
					return;

				//get distinct list of providers involved in this appointment
				
				if (this.providersInvolved.length < 2) {					
					return;
				}

				//get the other provider
				const otherProvider = this.providersInvolved.find((x) => x !== providerId);

				//make a rest call to toggle provider

				try {
					const res = await ApiService.postData(`Appointment/Procedure/${procedureId}`, {appointmentId: this.appointmentOrig.appointmentId, providerId: otherProvider}, {});
					if (res.status == 200) {
						await this.fetchEventInfo(this.appointmentOrig.appointmentId);
					} else {
						this.toast.error(codes.error);
					}
				} catch (e) {
					this.toast.error(codes.error);
					throw e;
				}				
			},
			getNextAppointment() : number | null{
				if (this.$route.query.chain){
					const apptIds : number[] = (this.$route.query.chain as any).split("-").map(Number);						
					const index = apptIds.indexOf(this.appointment.appointmentId);
					if (index >=0 && index < apptIds.length - 1){
						return apptIds[index+1];
					}
				}
				return null;
			},
			applyPrePlan(){				
				this.prePlanApplied = true;
				this.recommended_filter = false;
				let toggle = document.getElementById("toggleRecommended") as any;
				toggle.style.color = "grey";				

				for(var task of this.appointment.recommendations){
					if (task.isPrePlanned){
						if ((task.calculationType === 1 && task.procedureOptions && task.procedureOptions.length > 1) ||
							(task.calculationType === 7)){
							//have too many options, can't chose for user. 
						} else {
							this.addToOpted(task);
						}
					}					
				}
			},
			async historyChanged(){
				console.log("historyChanged");
				await this.fetchEventInfo(this.appointment.appointmentId);
				this.dmsVisible = this.appointmentIsOld();
			},
			toggleCompleted() {
				this.markAsCompleted = !this.markAsCompleted;
				this.dmsVisible = this.markAsCompleted;				
			},
			onAgenViewChanged(payload){
				console.log("onAgenViewChanged", payload);
				this.selectedProcedureTypeIds = payload.selectedProcedureTypeIds;
				this.appointmentProductionInDollars = payload.appointmentProductionInDollars;
				this.providerCommission = payload.totalProviderCommission;
				this.applicableTime = payload.applicableTime;
			},

			addCustomAdaCodeToOpted(procedureToAdd) {
				let optedAlready = this.appointment.selectedRecommendations.find((x) => x.adaCode == procedureToAdd.adaCode);
				let recommendedalready = this.appointment.recommendations.find((x) => x.adaCode == procedureToAdd.adaCode);
				if (optedAlready || recommendedalready) {
					alert(`${procedureToAdd.description} already in ${optedAlready ? "opted services" : "applicable services"}. `);
					return;
				}
				let parent41 = this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 41);
				let child = {
					adaCode: procedureToAdd.adaCode,
					ammount: procedureToAdd.fee,
					description: procedureToAdd.description,
					feeOverride: null,
					minutes: this.calculateTimeBasedOnAmmount(procedureToAdd.fee),
					show: true,
					name: procedureToAdd.abbrevDescription,
					teeth: null,
					units: null,
				};

				if (parent41 !== null && parent41 !== undefined) {
					// parent is here add child to procedure options, add ammount to parent, calculate new timeframe
					parent41.procedureOptions.push(child);
					parent41.ammount += procedureToAdd.fee;
					parent41.initialMinutes += this.calculateTimeBasedOnAmmount(procedureToAdd.fee);
					this.removeProcedureById(parent41.procedureRuleTypeId);
					this.appointment.selectedRecommendations.push(parent41);
				} else {
					// add parent, add child in proc options, add to parent ammount, calculate time for it;
					let objectToAdd = {
						adaCode: "",
						ammount: procedureToAdd.fee,
						calculationType: 1,
						color: "yellow",
						name: "Custom",
						initialMinutes: this.calculateTimeBasedOnAmmount(procedureToAdd.fee),
						isRecommended: false,
						procedureOptions: [child],
						procedureRuleTypeId: 41,
					};
					this.appointment.selectedRecommendations.push(objectToAdd);
				}

				this.customAdaModalVisible = false;
			},

			removeProcedureById(id) {
				for (let i = 0; i < this.appointment.selectedRecommendations.length; i++) {
					const rec = this.appointment.selectedRecommendations[i];
					if (rec.procedureRuleTypeId == id) {
						this.appointment.selectedRecommendations.splice(i, 1);
					}
				}
			},
			closeCustomAdaModal() {
				this.customAdaModalVisible = false;
			},
			showPreviewModal()
			{
				this.displayPreviewModal = true;
			},		
			closePreviewModal(){
			 	this.displayPreviewModal = false;
			},
			async savePreviewModal(){
				let appointmentNext = (this.$refs.planAppt as any).getAppointmentNext();
				try {
					const res = await ApiService.postData(`/Appointment/${this.appointment.appointmentId}/SaveAppointmentNext`, appointmentNext, {});
					if (res.status == 200) {						
						this.displayPreviewModal = false;
					} else {
						this.toast.error(codes.error);
					}
				} catch (e) {
					this.toast.error(codes.error);
					throw e;
				}
			},
			addTeethImplant(e, teethNumber, obj7) {
				console.log(e, teethNumber, obj7);

				if (!this.type7Object.selectedTeeth) {
					this.type7Object.selectedTeeth = [];
					this.type7Object.selectedTeeth.push(teethNumber);
					e.target.classList.remove("btn-secondary");
					e.target.classList.add("btn-primary");
				} else {
					let remove = this.type7Object.selectedTeeth.find((x) => x === teethNumber);
					if (remove) {
						for (let i = 0; i < this.type7Object.selectedTeeth.length; i++) {
							const teethNum = this.type7Object.selectedTeeth[i];
							if (teethNum === teethNumber) {
								this.type7Object.selectedTeeth.splice(i, 1);
								e.target.classList.remove("btn-primary");
								e.target.classList.add("btn-secondary");
								return;
							}
						}
					} else {
						this.type7Object.selectedTeeth.push(teethNumber);
						e.target.classList.remove("btn-secondary");
						e.target.classList.add("btn-primary");
					}
				}
			},
			shouldShowApplicableTask(task) {
				const isSelected = this.selectedProcedureTypeIds.includes(task.procedureRuleTypeId);
				const isPa = task.procedureRuleTypeId === 35
				const isPAChild = task.calculationType === 7 && !isPa;
				if (isPAChild){
					return false; 
				}
				if (isPa){
					if (this.unusedType7.length === 0){
						return false; 
					} else {
						return true;
					}	
				}
				if (this.recommended_filter == true) {
					return (this.recommended_filter == true ? task.isRecommended : true) && !isSelected;
				} else {
					return !isSelected;
				}
			},

			hide_show_recommended(e) {
				this.recommended_filter = !this.recommended_filter;
				if (this.recommended_filter == true) {
					e.target.style.color = "#50cd89";
				} else {
					e.target.style.color = "grey";
				}
			},

			checkIfcanAddPolish(task) {
				if ((task.procedureRuleTypeId == 7 || task.procedureRuleTypeId == 29) && this.appointment.insuranceStatus == 2) {
					// check if odsp and task is polish if exam recall is opted hes allowed to add polish
					let examRecal = this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 23 || x.procedureRuleTypeId == 28);
					if (examRecal) {
						return true;
					} else {
						return false;
					}
				} else {
					return true;
				}
			},

			closeOdspCriteriaModal() {
				this.showOdspCriteriaModal = false;
				this.odspCriteriaModalObj = null;
				this.odspCheckboxList = [];
			},

			addFluoOrPanToOpted() {
				let task = this.odspCriteriaModalObj.parent;

				// if (this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == task.procedureRuleTypeId)) {
				// 	for (let i = 0; i < this.appointment.selectedRecommendations.length; i++) {
				// 		const selectedRec = this.appointment.selectedRecommendations[i];
				// 		if (selectedRec.procedureRuleTypeId == task.procedureRuleTypeId) {
				// 			this.appointment.selectedRecommendations.splice(i, 1);
				// 		}
				// 	}
				// }
				if (task.procedureRuleTypeId == 8 || task.procedureRuleTypeId == 30) {
					// fluoride needs at least 2
					if (this.odspCheckboxList.length < 2) {
						return;
					}
				} else {
					// pan needs at least 1
					if (this.odspCheckboxList.length < 1) {
						return;
					}
				}
				task.selectedCriteria = this.odspCheckboxList;
				let subItem = this.odspCriteriaModalObj.child;
				const newTask = task;
				if (this.odspCriteriaModalObj.child) {
					newTask.selectedOption = {};
					newTask.procedureOptions.find((x) => x.adaCode === subItem.adaCode).show = true;
					newTask.selectedOption.duration = this.calculateTimeBasedOnAmmount(subItem.ammount);
					newTask.selectedOption.charge = subItem.ammount;
				}
				this.addToAgendaView(newTask);
				this.appointment.selectedRecommendations.push(newTask); // add parent and child in procedure
				this.odspCheckboxList = [];
				this.odspCriteriaModalObj = null;
				this.showOdspCriteriaModal = false;
			},

			addCheckboxToFluoOrPan(e) {
				let txt = e.target.value;
				if (this.odspCheckboxList.find((x) => x == txt)) {
					for (let i = 0; i < this.odspCheckboxList.length; i++) {
						const el = this.odspCheckboxList[i];
						if (el == txt) {
							this.odspCheckboxList.splice(i, 1);
							return;
						}
					}
				} else {
					this.odspCheckboxList.push(txt);
				}
				// console.log(this.odspCheckboxList);
			},

			getType7MaxUnit(procOptions) {
				let max = 0;
				procOptions.forEach((proc) => {
					max = proc.units >= max ? proc.units : max;
				});
				return max;
			},
			canSelect(task){				
				return true;

				//this used to be for old probing. Just keeping this historically encase we need it again
				if (task.procedureRuleTypeId !== 10){
					return true;
				}

				//this is for probe basically
				const examIds= [2,17,23,24,25,26,28];
				if (this.selectedProcedureTypeIds.some(id => examIds.includes(id))){
					return true;
				} else {
					return false;
				}
			},

			getType8InfonStatus(task) {
				let result = "At least one of the following needs to be opted: ";
				let fromselected = null as any;
				let flag = false;
				const examIds= [2,17,23,24,25,26,28];
				for (let i = 0; task.calculationType == 8 && i < task.parentProcedureRuleTypes.length; i++) {
					const parent = task.parentProcedureRuleTypes[i];
					fromselected = this.selectedProcedureTypeIds.find((x) => x.procedureRuleTypeId);
					if (!fromselected) {
						fromselected = this.appointment.recommendations.find((x) => x.procedureRuleTypeId == parent);
					} else {
						flag = true;
					}
					if (fromselected.procedureRuleTypeId) {
						if (i == task.parentProcedureRuleTypes.length - 1) {
							result += fromselected.name + ".";
						} else {
							result += fromselected.name + ", ";
						}
					}
				}
				if (fromselected) {
					// ok
					return { text: result, status: flag };
				} else {
					// not ok
					return { text: result, status: flag };
				}
			},

			addType7ToOpted() {
				let parent = this.type7Object.parent;
				let child = this.type7Object.child;
				let numOfPas = this.type7Object.numOfPa;
				let selectedTeeth = this.type7Object.selectedTeeth;
				child.selectedTeeth = selectedTeeth;
				child.quantity = numOfPas;
				if (numOfPas > this.getType7MaxUnit(parent.procedureOptions) || numOfPas < 0) {
					console.error(`NUM OF PAS CAN'T BE MORE THAN ${this.getType7MaxUnit(parent.procedureOptions)} OR LESS THAN 0`);
					return;
				}
				parent.procedureOptions.find((x) => x.units == numOfPas).show = true;
				let price = 0;
				let totalPas = 0;
				if (this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35)) {
					// already addded one before we should add to it the child now
					for (let i = 0; i < this.appointment.selectedRecommendations.length; i++) {
						const selectedRec = this.appointment.selectedRecommendations[i];
						if (selectedRec.procedureRuleTypeId == 35) {
							let optionsArray = this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.filter((y) => y.show == true);
							optionsArray.forEach((y) => {
								totalPas += y.units;
							});
							for (let a = 0; a < this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.length; a++) {
								this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions[a].show = false;
							}
							if (totalPas > 15) {
								if (totalPas >= 15) {
									this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.find((x) => x.units == 15).show = true;
									price += this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.find((x) => x.units == 15).ammount;
									totalPas = 15;
								}
							} else {
								this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.find((x) => x.units == totalPas).show = true;
								price += this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).procedureOptions.find((x) => x.units == totalPas).ammount;
							}
							this.appointment.selectedRecommendations.push(child);
							this.addToAgendaView(child);
						}
					}
				} else {
					// add parent to opted with child the selected pa
					price = parent.procedureOptions.find((x) => x.units == numOfPas).ammount;
					this.appointment.selectedRecommendations.push(parent, child);
					this.addToAgendaView(parent);
					this.addToAgendaView(child);
				}

				this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).ammount = price;
				this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == 35).initialMinutes = this.calculateTimeBasedOnAmmount(price);
				let x = this.unusedType7;
				this.showType7Modal = false;
			},

			closeType7Modal() {
				this.type7Object = null;
				this.showType7Modal = false;
			},

			changeNowTimezone() {
				let date = new Date();
				let newTimeZoneDate = date.toLocaleString("en-US", { timeZone: "America/New_York" });
				return new Date(newTimeZoneDate);
			},

			appointmentIsOld() {
				if (this.markAsCompleted){
					return true;
				} else {
					let appt = new Date(this.appointment.endDate);
					let old = false;
					if (appt > this.changeNowTimezone()){
						old = false;
						if (this.appointment){
							if (this.appointment.procedures){
								if (this.appointment.procedures.filter(p => p.procedureStatus === 102 && p.amount > 0).length > 0){
									old = true;
								}
							}
						}
					} else {
						old = true;
					}
					return old;
				}
			},

			hasPermissionToView() {
				return (this.instanceInfo.isProvider && this.instanceInfo.providersIds.find((x) => x == this.appointment.providerId) != null) || this.instanceInfo.isClinic;
			},

			async close(appointmentInfo) {
				let id = this.appointment.appointmentId;
				let params = {};
				if (appointmentInfo) {					
					params = {
						target: appointmentInfo.date,
						id: id,
						rnaTotalPlanned: appointmentInfo.rnaTotalPlanned,						
						newReason: appointmentInfo.newReason,
						newTotalCompleted: appointmentInfo.newTotalCompleted,
						isOptimal: appointmentInfo.isOptimal,
					};
				} else {
					params = {
						target: this.originDate ?? this.appointment.startDate,
						id: id,
					};
				}

				if (appointmentInfo && appointmentInfo.goNext){					
					let nextApptId = this.getNextAppointment();
					//i have to find where is next?
					if (nextApptId){						
						this.$router.push({ 	
							path: `/appointment/${nextApptId}`, 
							query: {
								chain: this.$route.query.chain									
							}
						});									
						// await this.fetchEventInfo(nextApptId);
						// this.dmsVisible = this.appointmentIsOld();	
						// window.scrollTo(0,0);						
					} else {
						this.$router.push({ name: `dashboard`, params: params });
					}					
				} else {
					this.$router.push({ name: `dashboard`, params: params });
				}

				
			},

			handleEditSealants(){
				this.editSealantsTeeth(this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === 20));
			},

			editSealantsTeeth(procedure) {
				console.log("edit sealants procedure", procedure)
				if (this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === procedure.procedureRuleTypeId)) {
					this.type5Object = procedure;
					this.showType5Modal = true;
				}
			},
			getAppointmentGoalHourlyProduction() {
				return this.appointment.providerHourlyGoal || this.instanceInfo.goalHourlyProduction;
			},

			compareStrings(a, b) {
				// Assuming you want case-insensitive comparison
				a = a.toLowerCase();
				b = b.toLowerCase();

				return a < b ? -1 : a > b ? 1 : 0;
			},

			groupBy(list, keyGetter) {
				const map = new Map();
				list.forEach((item) => {
					const key = keyGetter(item);
					const collection = map.get(key);
					if (!collection) {
						map.set(key, [item]);
					} else {
						collection.push(item);
					}
				});
				return map;
			},

			transferDentrixToOpted() {
				let type7TotalCharge = 0;
				for (let i = 0; i < this.appointment.inferredRecommendations.length; i++) {
					this.appointment.inferredRecommendations[i].dentrix = true;
					let element = this.appointment.inferredRecommendations[i];
					if (this.appointment.inferredRecommendations[i].procedureOptions.length === 1) {
						this.appointment.inferredRecommendations[i].procedureOptions[0].show = true;
					}
					if (element.calculationType !== 5 && !this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === element.procedureRuleTypeId)) {
						element.charge = element.ammount;
						element.adaUsed = [element.adaCode];
						this.appointment.selectedRecommendations.push(element);
						this.calculateOptedCharge(
							element,
							element.procedureOptions.find((x) => x.show === true),
							false
						);
					} else if (element.calculationType === 5 && !this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === element.procedureRuleTypeId)) {
						this.type5Object = element;
						this.showType5Modal = true;
					}
				}
				if (this.appointment.inferredRecommendations.find((x) => x.procedureRuleTypeId == 35)) {
					this.appointment.inferredRecommendations
						.find((x) => x.procedureRuleTypeId == 35)
						.procedureOptions.filter((x) => x.show == true)
						.forEach((procOp) => {
							type7TotalCharge += procOp.ammount;
						});
					this.appointment.inferredRecommendations.find((x) => x.procedureRuleTypeId == 35).ammount = type7TotalCharge;
					this.appointment.inferredRecommendations.find((x) => x.procedureRuleTypeId == 35).initialMinutes = this.calculateTimeBasedOnAmmount(type7TotalCharge);
				}
				this.appointment.inferredRecommendations = [];
				return true;
			},

			autoTransferType4ToOpted() {
				// console.log("auto adding type 4");
				this.appointment.recommendations.forEach((serv) => {
					if (serv.calculationType === 4 && 
						serv.includeWithScaling && 
						!this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === serv.procedureRuleTypeId)) {
						this.addToOpted(serv);
					}
				});
			},

			closeSealantModal() {
				this.showType5Modal = false;
				this.type5Object = null;
				// console.log("closing");
			},

			checkIfProcedureAlreadyAdded(task) {
				if (this.selectedProcedureTypeIds.includes(task.procedureRuleTypeId)) {
					console.log(`${task.name} already added`);
					return true;
				} else {
					return false;
				}
			},

			breakIntoAdaCodesAndPrice(procOptions, sum) {
				const c = new ProcedureCalculator();
				let proc = c.calculateProceduresFromMinutes(procOptions, sum);
				return { adaCodes: proc.adaCodes, price: proc.fee, commission: proc.commission };
			},

			addTeethSealant(e, teethNumber, procedure) {
				if (!this.type5Object.selectedTeeth) {
					this.type5Object.selectedTeeth = [];
					this.type5Object.selectedTeeth.push(teethNumber);
					e.target.classList.remove("btn-secondary");
					e.target.classList.add("btn-primary");
				} else {
					let remove = this.type5Object.selectedTeeth.find((x) => x === teethNumber);
					if (remove) {
						for (let i = 0; i < this.type5Object.selectedTeeth.length; i++) {
							const teethNum = this.type5Object.selectedTeeth[i];
							if (teethNum === teethNumber) {
								this.type5Object.selectedTeeth.splice(i, 1);
								e.target.classList.remove("btn-primary");
								e.target.classList.add("btn-secondary");
								return;
							}
						}
					} else {
						this.type5Object.selectedTeeth.push(teethNumber);
						e.target.classList.remove("btn-secondary");
						e.target.classList.add("btn-primary");
					}
				}
			},

			getProcedureTime(procedure, procOption) {
				let sum = 0;
				let price = 0;
				let adaCodes = [] as any;
				if (procOption && procedure.calculationType === 1) {
					if (procedure.procedureRuleTypeId == 41) {
						// custom type with custom adacodes
						price = procedure.ammount;
						adaCodes = [] as any;
					} else {
						switch (procedure.calculationType) {
							case 1:
								price = procOption.ammount;
								adaCodes.push(procOption.adaCode);
								break;
						}
					}
				} else {
					switch (procedure.calculationType) {
						case 1:
							price = procedure.ammount;
							adaCodes.push(procedure.adaCode);
							break;
						case 2:
							break;
						case 3:
							sum = Number(procedure.initialMinutes || 2);
							price += procedure.price * procedure.ammount;
							adaCodes = [procedure.adaCode];
							break;
						case 4:
							//only if ruletype id is 18 we do remaining cz scaling
							//need a way to always put the scalling at the end of the array so when the calculation happens so it doesnt take the remaining time because there are additional stuff after it
							if (procedure.procedureRuleTypeId === 18) {
								console.log(procedure);
								// scaling
							} else {
								// not scaling
								//if its not scaling do use initialMinutes, if initialMinutes is null put 2 mins

								// ONLY SHOW MINUTES HERE
								sum = Number(procedure.initialMinutes || 2);
								let returning = this.breakIntoAdaCodesAndPrice(procedure.procedureOptions, sum);
								price += returning.price;
								adaCodes = returning.adaCodes;
							}
							break;
					}
				}
				const durationString = intervalToDuration({
					start: 0,
					end: (procedure.calculationType === 1 ? 
          //hack for janice for fixed 01102 price. good luck
            (adaCodes.includes("01102") ? 15 : this.calculateTimeBasedOnAmmount(price)) : Number(procedure.initialMinutes || 2)) * 60 * 1000,
				});
				return durationString;
			},

			specificProcedureOptionMinutes(task) {
				//used in applicable services
				let sum = ((task.ammount || 0) / this.getAppointmentGoalHourlyProduction()) * 60;

				if (task.adaCode === "01102") {
					sum = 15;
				}

				// this.appointment.selectedRecommendations.find(x=> x.procedureRuleTypeId === task.procedureRuleTypeId).charge = task.ammount;
				// this.appointment.selectedRecommendations.find(x=> x.procedureRuleTypeId === task.procedureRuleTypeId).charge = task.ammount;
				const durationString = intervalToDuration({
					start: 0,
					end: sum * 60 * 1000,
				});
				return durationString;
			},

			collapse(e, task) {
				let elts = document.getElementsByClassName(`hide-${task.procedureRuleTypeId}`) as any;
				let arrow = document.getElementsByClassName(`collapse-${task.procedureRuleTypeId}`)[0] as any;
				if (elts[0] && elts[0]?.style && elts[0]?.style.display !== "none") {
					for (let i = 0; i < elts.length; i++) {
						elts[i].style.display = "none";
					}
					arrow.style.transform = "rotate(180deg)";
				} else {
					for (let i = 0; i < elts.length; i++) {
						elts[i].style.display = "block";
					}
					if (arrow && arrow.style) {
						arrow.style.transform = "rotate(0deg)";
					}
				}
			},

			addProcedureOptionToOpted(task, subItem) {
				console.log("addProcedureOptionToOpted");
				if (this.appointment.insuranceStatus == 2 && (task.procedureRuleTypeId == 30 || task.procedureRuleTypeId == 14 || task.procedureRuleTypeId == 8)) {
					//fluoride or PAN if odsp  // DONT FORGET TO CGANE INSURANCESTATUS TO == 2
					console.log(task);
					this.odspCriteriaModalObj = { parent: task, child: subItem };
					this.showOdspCriteriaModal = true;
				} else if (task.calculationType == 7 && task.procedureRuleTypeId == 35) {
					this.type7Object = { parent: task, child: subItem, numOfPa: 1, selectedTeeth: [] };
					this.showType7Modal = true;
				} else if (this.checkIfProcedureAlreadyAdded(task)) {
					return;
				} else if (task.procedureRuleTypeId === 35) {
				} else {
					console.log("ADDED");
					const newTask = task;
					newTask.procedureOptions.forEach((x) => x.show = false);
					newTask.procedureOptions.find((x) => x.adaCode === subItem.adaCode).show = true;
					newTask.selectedOption = {};					
					newTask.selectedOption.charge = subItem.ammount;
					newTask.selectedOption.ammount = subItem.ammount;
					newTask.selectedOption.adaCode = newTask.procedureOptions.find((x) => x.adaCode === subItem.adaCode).adaCode;
					newTask.selectedOption.duration = newTask.selectedOption.adaCode === "01102" ? 15 : this.calculateTimeBasedOnAmmount(subItem.ammount);
					this.addToAgendaView(newTask);
					this.appointment.selectedRecommendations.push(newTask); // add parent and child in procedure
					
					this.calculateOptedCharge(newTask, newTask.selectedOption, false);
					
				}
			},

			convertDifferenceInMinutesToHumanReadable(diff) {
				const hours = diff.hours < 10 ? (diff.hours === 0 ? null : "0" + diff.hours) : diff.hours;
				const minutes = diff.minutes < 10 ? (diff.minutes === 0 ? "00" : "0" + diff.minutes) : diff.minutes;
				const seconds = diff.seconds < 10 ? (diff.seconds === 0 ? "00" : "0" + diff.seconds) : diff.seconds;
				return `${hours ? hours + ":" : ""}${minutes ? minutes + ":" : ""}${seconds ? seconds : ""}`;
			},

			calculateOptedCharge(procedure, procOption, computed) {
				// computed should be true so when this func is used in a computed func it doesnt trigger a recursive
				let sum = 0;
				let price = 0;
				let adaCodes = [] as any;
        		let commission = 0;
				let returning = {} as any; //used to get price, commission and AdaCodes used for pricing
				if (procOption && procedure.calculationType === 1) {
					switch (procedure.calculationType) {
						case 1:
							price = procOption.ammount;
							adaCodes.push(procOption.adaCode);
              commission = procOption.ammount * procOption.commissionPercentage;
							break;
					}
				} else {
					switch (procedure.calculationType) {
						case 1:
							price = procedure.ammount;
              commission = procedure.ammount * procedure.commissionPercentage;
							break;
						case 2:
							sum = Number(procedure.initialMinutes || 2);
							returning = this.breakIntoAdaCodesAndPrice(procedure.procedureOptions, sum);
							price += returning.price;
							adaCodes = returning.adaCodes;
              commission += returning.commission;
							break;
						case 3:
							let unitOne = procedure.procedureOptions.find((x) => x.units == 1);
							sum = Number(procedure.initialMinutes ?? 2);
							price += Math.round((unitOne.ammount / unitOne.minutes) * sum);
              let results = this.breakIntoAdaCodesAndPrice(procedure.procedureOptions, sum);
              adaCodes = results.adaCodes;
              commission += results.commission;
              break;
						case 4:
							//only if ruletype id is 18 we do remaining cz scaling
							if (procedure.procedureRuleTypeId !== 18) {
								// not scaling
								sum = Number(procedure.initialMinutes || 2);
								returning = this.breakIntoAdaCodesAndPrice(procedure.procedureOptions, sum);
								price += returning.price;
								adaCodes = returning.adaCodes;
                commission += returning.commission;
							}
							break;
						case 5:
							if (!procedure.charge) {
								for (let z = 0; z < procedure.procedureOptions.length; z++) {
									const proc = procedure.procedureOptions[z];
									price += proc.ammount;
									adaCodes.push(proc.adaCode);
                  commission += proc.ammount * proc.commissionPercentage;
								}
							} else {
								price += procedure.charge || procedure.ammount;
								adaCodes = procedure.adaUsed || [procedure.adaCode];
                commission += (procedure.charge || procedure.ammount) * procedure.commissionPercentage;
							}
							break;
						case 7:
							price += procedure.ammount;
              commission += procedure.ammount * procedure.commissionPercentage;
					}
				}
				if (!computed) {
					if (procedure.calculationType != 3) {
						this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === procedure.procedureRuleTypeId).ammount = price;
					}
					this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === procedure.procedureRuleTypeId).charge = price;
					this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === procedure.procedureRuleTypeId).adaUsed = adaCodes;
					this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === procedure.procedureRuleTypeId).initialMinutes = Number(sum);
				}
				return price;
			},

			addToOpted(task) {

				//added this to shortcut for things that only have one procedure option.
				if (task.calculationType === 1 && task.procedureOptions && task.procedureOptions.length === 1){
					this.addProcedureOptionToOpted(task, task.procedureOptions[0]);
					return;
				}

				// adds task to appointment.selectedRecommendations and removes it from appointment.recommendations
				//appointment.recommendations ->  appointment.selectedRecommendations search by adaCode
				if (!this.canSelect(task)){
					return;
				}

				if (this.checkIfProcedureAlreadyAdded(task)) {
					return;
				} else if (!this.checkIfcanAddPolish(task)) {
					return;
				}

				if (task.procedureRuleTypeId == 14 && this.appointment.insuranceStatus == 2) {
					this.odspCheckboxList = [];
					this.odspCriteriaModalObj = { parent: task, child: null };
					this.showOdspCriteriaModal = true;
					return;
				} else if (task.calculationType === 8) {
					this.addToAgendaView(task);						
				} else if (task.calculationType === 6) {					
					this.addToAgendaView(task)
				} else if (task.calculationType !== 5) {
					if (task.children) {
						task.children = [];
					}
					console.log(task);					
					this.addToAgendaView(task)					
					if (task.calculationType === 4) {
						this.autoTransferType4ToOpted();
					}
				} else if (task.calculationType === 5) {
					console.log("creating type5 object", task)
					this.type5Object = task;
					this.showType5Modal = true;
				}
			},
			addToAgendaView(task){
				console.log("adding task", task);
				let transformedTask = JSON.parse(JSON.stringify(task));
				transformedTask.procedureOptions = [];
				if (task.payload && task.payload.length > 0){ //hack for sealants
					for(const toothAda of task.payload){
						transformedTask.procedureOptions.push({adaCode: toothAda.adaCode, teeth: toothAda.teeth[0], name: `Tooth: ${toothAda.teeth[0]}`, 
							ammount: toothAda.charge, commissionPercentage: 0.18  })
					}
				} else {
					transformedTask.procedureOptions = task.procedureOptions.filter(o => o.show); 
				}
				transformedTask.requestedBy = this.getRquestedBy(task);
				(this.$refs.agendaView as any).addItem(transformedTask);
			},


			createType5Payload(proc) {
				let teethPayload = [] as any;
				let obj = proc;
				obj.procedureOptions.sort((a, b) => parseFloat(b.ammount) - parseFloat(a.ammount));
				console.log("lol", obj.procedureOptions);
				for (let i = 0; i < obj.procedureOptions.length; i++) {
					const t = obj.procedureOptions[i];
					teethPayload.push({ teeth: t.teeth, adaCode: t.adaCode });
				}
				return teethPayload;
			},

			addtype5ToOpted() {
				//first tooth in each quad takes the bigger procoption and then the rest in the same quad takes the second procOption
				let charge = 0;
				let firstQuad = true; // (first, second, third and fourth)quads are used to know if this is the first teeth to add from each quadrant to know which adacode to select
				let secondQuad = true;
				let thirdQuad = true;
				let fourthQuad = true;
				let adaCodes = [];
				let self = this;
				let teethPayload = [] as any;
				this.type5Object.procedureOptions.sort((a, b) => parseFloat(b.ammount) - parseFloat(a.ammount));
				this.type5Object.selectedTeeth.forEach((t) => {
					let ada = "";
					let individualCharge = 0;
					if (this.teeth.first.find((x) => x === t)) {
						if (firstQuad) {
							firstQuad = false;
							ada = this.type5Object.procedureOptions[0].adaCode;
							charge += this.type5Object.procedureOptions[0].ammount;
							individualCharge = this.type5Object.procedureOptions[0].ammount;
						} else {
							ada = this.type5Object.procedureOptions[1].adaCode;
							charge += this.type5Object.procedureOptions[1].ammount;
							individualCharge = this.type5Object.procedureOptions[1].ammount;
						}
					} else if (this.teeth.second.find((x) => x === t)) {
						if (secondQuad) {
							secondQuad = false;
							ada = this.type5Object.procedureOptions[0].adaCode;
							charge += this.type5Object.procedureOptions[0].ammount;
							individualCharge = this.type5Object.procedureOptions[0].ammount;
						} else {
							ada = this.type5Object.procedureOptions[1].adaCode;
							charge += this.type5Object.procedureOptions[1].ammount;
							individualCharge = this.type5Object.procedureOptions[1].ammount;
						}
					} else if (this.teeth.third.find((x) => x === t)) {
						if (thirdQuad) {
							thirdQuad = false;
							ada = this.type5Object.procedureOptions[0].adaCode;
							charge += this.type5Object.procedureOptions[0].ammount;
							individualCharge = this.type5Object.procedureOptions[0].ammount;
						} else {
							ada = this.type5Object.procedureOptions[1].adaCode;
							charge += this.type5Object.procedureOptions[1].ammount;
							individualCharge = this.type5Object.procedureOptions[1].ammount;
						}
					} else if (this.teeth.fourth.find((x) => x === t)) {
						if (fourthQuad) {
							fourthQuad = false;
							ada = this.type5Object.procedureOptions[0].adaCode;
							charge += this.type5Object.procedureOptions[0].ammount;
							individualCharge = this.type5Object.procedureOptions[0].ammount;
						} else {
							ada = this.type5Object.procedureOptions[1].adaCode;
							charge += this.type5Object.procedureOptions[1].ammount;
							individualCharge = this.type5Object.procedureOptions[1].ammount;
						}
					}
					teethPayload.push({ teeth: [t], adaCode: ada, charge: individualCharge });
				});
				this.type5Object.payload = teethPayload;
				this.type5Object.charge = charge;
				this.type5Object.adaUsed = adaCodes;
				this.type5Object.initialMinutes = this.calculateTimeBasedOnAmmount(charge);
				if (this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId === this.type5Object.procedureRuleTypeId)) {
					this.appointment.selectedRecommendations.forEach(function (item, i) {
						if (item.procedureRuleTypeId == self.type5Object.procedureRuleTypeId) {
							self.appointment.selectedRecommendations[i] = self.type5Object;
							self.addToAgendaView(self.type5Object);
							self.showType5Modal = false;
							self.type5Object = null;
							return;
						}
					});
				} else {
					for (let i = 0; i < this.appointment.recommendations.length; i++) {
						const element = this.appointment.recommendations[i];
						if (element.procedureRuleTypeId === this.type5Object.procedureRuleTypeId) {
							// this.appointment.recommendations.splice(i, 1);
							this.appointment.selectedRecommendations.push(this.type5Object);

							this.addToAgendaView(this.type5Object);
							this.showType5Modal = false;
							this.type5Object = null;
							return;
						}
					}
				}				
			},
			async fetchEventInfo(apptId) {
				// open modal that has opted and suggested services where you can or remove services at will				
				const procRules = await ApiService.get(`/ProcedureRules`);				
				this.procedureRuletypes = procRules.data;				
				
				let localProcedureRuleTypeById = new Map<number, ProcedureRuleType>();
				procRules.data.forEach((procedureRuleType) => {					
					localProcedureRuleTypeById.set(procedureRuleType.procedureRuleTypeId, procedureRuleType);
				});
				
				this.procedureRuleTypesById = localProcedureRuleTypeById;
				
				// console.log(event.event._def.extendedProps.apptObject.appointmentId);				
				try{
					const optedRes = await ApiService.get(`/Appointment/${apptId}`);
					if (optedRes.status == 200) {
						// console.log(optedRes.data);
					} else {
						// Error cases will be handled by a flag
						this.noAppointmentFound = true;
						// this.toast.error(codes.error);
						return;
					}
					//check if selected has the same as rec if yes add rec to selected with show = false unless its the one that is in selected,
					console.log("data", optedRes.data);
					this.appointment = optedRes.data;
					this.appointmentOrig = JSON.parse(JSON.stringify(optedRes.data));
					this.procedureRuleTypeOptions = this.appointmentOrig.recommendations;

					this.populateInferredRecommendationsProcOptions();
					this.populateSelectedRecommendationsProcOptions();
					this.populateExamsWithTypes8();

					//get providers involved in this appointment
					this.providersInvolved = [];
					this.appointment.procedures.forEach((proc) => {
						if (!this.providersInvolved.includes(proc.providerId)) {
							this.providersInvolved.push(proc.providerId);
						}
					});

					console.log("Modal object", this.appointment);
					this.patientHistory = optedRes.data.proceduresHistory || [];
					if (!this.appointmentIsOld() && this.clinicInfo.showInferredProcedures) this.transferDentrixToOpted();
					// if((this.appointment.selectedRecommendations.length > 0 && dentrixDone) || this.appointment.inferredRecommendations.length > 0){
					//   console.log("adding all type 4");
					//   this.autoTransferType4ToOpted();
					// }
					} catch (e) {
						// this.toast.error(codes.error);
						// Error cases will be handled by a flag
						this.noAppointmentFound = true;
						throw e;
					}
			},
			populateExamsWithTypes8() {
				for (let i = 0; i < this.appointment.selectedRecommendations.length; i++) {
					const task = this.appointment.selectedRecommendations[i];
					if (task.calculationType == 8) {
						for (let j = 0; j < task.parentProcedureRuleTypes.length; j++) {
							const parentId = task.parentProcedureRuleTypes[j];
							this.parentType8 = this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == parentId);
							if (this.parentType8) {
								this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == parentId).children ? this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == parentId).children.push(task) : (this.appointment.selectedRecommendations.find((x) => x.procedureRuleTypeId == parentId).children = [task]);
								break;
							}
						}
					}
				}
			},

			populateSelectedRecommendationsProcOptions() {
				// used to populate missing procOptions from recommended to selected
				for (let i = 0; i < this.appointment.selectedRecommendations.length; i++) {
					const selected = this.appointment.selectedRecommendations[i];
					if (selected.procedureRuleTypeId == 41) {
						// custom make all proc options show = true
						let m = 0;
						selected.procedureOptions.forEach((procoptions) => {
							this.appointment.selectedRecommendations[i].procedureOptions[m].show = true;
							m++;
						});
					}

					for (let j = 0; j < this.appointment.recommendations.length; j++) {
						const recomended = this.appointment.recommendations[j];

						if (selected.procedureRuleTypeId === recomended.procedureRuleTypeId) {
							if (selected.procedureOptions && selected.procedureOptions.length > 0) {
								//procedure that has options we will add the options to the selected but with show = false;
								if (selected.calculationType === 7 && selected.procedureRuleTypeId == 35) {
									for (let k = 0; k < this.appointment.selectedRecommendations[i].procedureOptions.length; k++) {
										if (k == 0) {
											this.appointment.selectedRecommendations[i].ammount = 0;
										}
										this.appointment.selectedRecommendations[i].procedureOptions[k].show = true;
										this.appointment.selectedRecommendations[i].ammount += this.appointment.selectedRecommendations[i].procedureOptions[k].ammount;
									}
									for (let k = 0; k < recomended.procedureOptions.length; k++) {
										const procOption = recomended.procedureOptions[k];
										if (selected.procedureOptions[k] && procOption.adaCode !== selected.procedureOptions[k].adaCode) {
											this.appointment.recommendations[j].procedureOptions[k].show = false;
											this.appointment.selectedRecommendations[i].procedureOptions.push(this.appointment.recommendations[j].procedureOptions[k]);
										}
									}
								} else if (selected.calculationType === 5) {
									this.appointment.selectedRecommendations[i].selectedTeeth = [];
									for (let zo = 0; zo < this.appointment.selectedRecommendations[i].procedureOptions.length; zo++) {
										const zelt = this.appointment.selectedRecommendations[i].procedureOptions[zo];
										this.appointment.selectedRecommendations[i].procedureOptions[zo].show = true;
										this.appointment.selectedRecommendations[i].selectedTeeth.push(zelt.teeth[0]);
									}
									// selected.procedureOptions.forEach((proc) => {
									// 	this.appointment.selectedRecommendations[i].procedureOptions[z].show = true;
									// 	this.appointment.selectedRecommendations[i].selectedTeeth.push(proc.teeth[0])
									// 	z++;
									// });
									this.appointment.selectedRecommendations[i].payload = this.createType5Payload(this.appointment.selectedRecommendations[i]);
								} else {
									for (let k = 0; k < recomended.procedureOptions.length; k++) {
										const procOption = recomended.procedureOptions[k];
										if (procOption.adaCode !== selected.procedureOptions[0].adaCode) {
											this.appointment.recommendations[j].procedureOptions[k].show = false;
											this.appointment.selectedRecommendations[i].procedureOptions.push(this.appointment.recommendations[j].procedureOptions[k]);
										} else {
											this.appointment.selectedRecommendations[i].procedureOptions[0].show = true;
										}
									}
									// this.appointment.recommendations.splice(j, 1);
								}
							} else {
								//normal procedure without options remove it cz its already in selected
								// console.log(this.appointment.recommendations[j]);
								// this.appointment.recommendations.splice(j, 1);
							}
						} else {
							if (selected.procedureOptions.length > 0) {
								this.appointment.selectedRecommendations[i].adaUsed = [selected.procedureOptions[0].adaCode];
							}
						}
					}
				}
			},

			populateInferredRecommendationsProcOptions() {
				// used to populate missing procOptions from recommended to inferred
				for (let i = 0; i < this.appointment.inferredRecommendations.length; i++) {
					let inferredProcOptions = [] as any;
					let inf = this.appointment.inferredRecommendations[i];
					let infProcOptions = this.appointment.recommendations.find((x) => x.procedureRuleTypeId === inf.procedureRuleTypeId);
					if (infProcOptions) {
						infProcOptions = infProcOptions.procedureOptions;
						infProcOptions.forEach((opt) => {
							let option = opt;
							if (inf.procedureOptions[0] && opt.adaCode === inf.procedureOptions[0].adaCode) {
								option.show = true;
							} else {
								option.show = false;
							}
							inferredProcOptions.push(option);
						});
					}
					this.appointment.inferredRecommendations[i].procedureOptions = infProcOptions ? inferredProcOptions : inf.procedureOptions;
				}
			},

			calculateTimeBasedOnAmmount(ammount) {
				//used to calculate time based on ammount for all calc type 1
				let total;
				total = ((ammount || 0) / this.getAppointmentGoalHourlyProduction()) * 60;
				return Math.round(total);
			},

			getRquestedBy(task){
				return (task.procedureOptions.find(x=> x.adaCode.substring(0, 2) == "02")) || (task.adaCode && task.adaCode.substring(0,2) === "02") || (task.procedureOptions.find(x=> x.show == true) && task.procedureOptions.find(x=> x.show == true).adaCode.substring(0,2) === "02") || (task.adaUsed && task.adaUsed.find(x=> x.substring(0,2) == "02")) ? (this.appointment.primaryProviderName.split(" ")[1] ? `(Dr.${this.appointment.primaryProviderName.split(" ")[1]})`:'') : '' 
			}
		},
		async mounted() {
			const apptId = this.$route.params.id;
			this.originDate = this.$route.query.originDate;
			await this.fetchEventInfo(apptId);
			this.dmsVisible = this.appointmentIsOld();

			if (this.appointment.prePlannedAppointment){						
				//clean this code up for me please
				let firstItems = this.appointment.recommendations.filter(p => p.isPrePlanned && p.scheduleOrderBeginning !== null)
				console.log("firstItems", firstItems);
				DateHelper.sortArrayByFunction(firstItems, (item => {return item.scheduleOrderBeginning}), "asc");
				let lastItems = this.appointment.recommendations.filter(p => p.isPrePlanned && p.scheduleOrderEnd !== null)
				DateHelper.sortArrayByFunction(lastItems, (item => {return item.scheduleOrderEnd}), "asc");
				let middleItems = this.appointment.recommendations.filter(p => p.isPrePlanned && p.scheduleOrderEnd === null && p.scheduleOrderBeginning === null)

				let procs = [...firstItems,...middleItems,...lastItems]; 						
				let date = new Date(this.appointment.prePlannedAppointment.appointmentNext?.dateModified);
				//get only date portion of date
				date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

				//format date as mmm dd, yyyy
				let formattedDate = date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });

				this.prePlanTitle = `Apply appointment planned on: ${formattedDate}\n\n`;
				for(const proc of procs){					
					this.prePlanTitle += `${proc.name}: ${proc.initialMinutes}\n`;
				}				

				if (this.appointment.prePlannedAppointment.appointmentNext?.comments){
					this.prePlanTitle += `\nNote: ${this.appointment.prePlannedAppointment.appointmentNext?.comments}\n`;
				}

				
			}
			
		},	

		beforeMount() {
			let show = this.$store.getters.currentClinicInfo.dmsType?.toLowerCase() != "abel";
			this.showDescription = show;
			console.log(`show description ${show} `);
			this.applicableTime = 0;
		},

		setup() {
			const store = useStore();
			const instanceInfo = store.getters.currentInstanceInfo;
			const clinicInfo = store.getters.currentClinicInfo;
			console.log("clinicInfo", clinicInfo);
			const currentUser = store.getters.currentUser;
			console.log(currentUser);
			const userPrefs = JSON.parse(currentUser.preferences) || {};
			// console.log("clinic info: ", clinicInfo);

			setCurrentPageTitle("Appointment");
			return {
				userPrefs,
				clinicInfo,
				currentUser,
				instanceInfo,
			};
		},

	});
