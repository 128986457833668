import ApiService from "@/core/services/ApiService";

export class AppointmentEndpoints {

    static GetNextAppointmentPreview(appointmentId, desiredDate) {
        if (desiredDate){
            const params = {};
            return ApiService.query(`Appointment/${appointmentId}/PreviewNext?desiredDate=${desiredDate}`,params);
        } else {
            return ApiService.get(`Appointment/${appointmentId}/PreviewNext`);
        }
        
    }

    static SaveAppointmentExtraInfo(appointmentId,procedureRuleTypeId: Number, data) {        
        return ApiService.post(`Appointment/${appointmentId}/Procedure/${procedureRuleTypeId}`,data);
    }
} 

