
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { format } from "date-fns";
import { defineComponent } from "@vue/runtime-core";
import { CHART_CONST } from "@/Models/chart.model";
import { DateHelper } from "@/Utilities/DateHelper";

/**
 * Chart Data Interface
 */
interface IChartData {
    name: string;
    data: [number, number][];
}

/**
 * Usage response Data contract
 */
interface IUsageResponseDataContract {
    date: Date;
    name: string;
    optimizedAppts: number;
    percentageUsage: number;
    theISOweek: number;
    totalAppts: number;
}

export default defineComponent({
    data() {
        let {startingStartDate, startingEndDate} = DateHelper.getStartingIntervalDates();


        return {
            startDate: startingStartDate.toISOString().split("T")[0],
			endDate: startingEndDate.toISOString().split("T")[0],
            series: [] as IChartData[],
            loading: false,
            chartOptions: {
                colors: CHART_CONST.ColorPalette,
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                },
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    text: '',
                    align: 'left'
                },
                noData: {
                    text: "No data available",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "black",
                        fontSize: "14px",
                        fontFamily: undefined,
                    },
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "right",
                    tooltipHoverFormatter: function(val, opts) {
                        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                    }
                },
                markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 6,
                },
                tooltip: {
                    x: {
                            show: true,
                            formatter: undefined,
                    },
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        },
                    ]
                },
                grid: {
                    borderColor: '#f1f1f1',
                }
            }
        };
    },
    async mounted() {
        await this.getUsageData();
    },
    setup() {
        setCurrentPageTitle("Usage report");
    },
    methods: {
        async getUsageData() {
            this.loading = true;
            const parseChartData = (responseData: IUsageResponseDataContract[]): IChartData[] => {
                const chartDataPrep: IChartData[] = [];
                responseData.forEach((data) => {
                    const foundChartData = chartDataPrep.find((element) => element.name === data.name); 
                    if (foundChartData) {
                        foundChartData.data.push([Date.parse(data.date.toString()), data.percentageUsage])
                    } else {
                        chartDataPrep.push({
                            name: data.name,
                            data: [[Date.parse(data.date.toString()), data.percentageUsage]]
                        })
                    }
                });
                return chartDataPrep;
            }
            const prepareParams = () =>{
                return {
                    startDate: this.startDate,
                    endDate: this.endDate,
                }
            };
            const result = await ApiService.query("/Report/ProductUsage", {
                params: prepareParams(),
            });
            this.series = parseChartData(result.data);
            this.loading = false;
        },
    }
});
