import axios from "axios";
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class GoatNotesService {
  /**
   * @description property to share vue instance
   */
  public static axiosInstance: AxiosInstance;

  /**
   * @description initialize vue axios
   */
  public static init() {    
    GoatNotesService.axiosInstance = axios.create({baseURL: "https://verifyapp.azurewebsites.net/Api",});     
    //GoatNotesService.axiosInstance = axios.create({baseURL: "http://localhost:5001/Api/",});     
  }

  /**
   * @description set the default HTTP request headers
   */
  public static async setAuthHeader(token : string) {    
    GoatNotesService.axiosInstance.defaults.headers.common["Authorization"] = token;
  }

  /**
   * @description set the default HTTP request header for current clinic
   */
   public static setClinicHeader(clinicGuid) {    
    GoatNotesService.axiosInstance.defaults.headers.common["ClinicGuid"] = clinicGuid;
  
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {        
    return GoatNotesService.axiosInstance.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    
    
    return GoatNotesService.axiosInstance
      .get(`${resource}/${slug}`);
  }

  public static getWithBody(
    resource: string,
    body: any
  ): Promise<AxiosResponse> {


    return GoatNotesService.axiosInstance
      .get(`${resource}`, {
        data: body
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return GoatNotesService.axiosInstance.post(`${resource}`, params);
  }

  public static postData(
    resource: string,
    data : any,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return GoatNotesService.axiosInstance.post(`${resource}`, data, config);
  }
  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return GoatNotesService.axiosInstance.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    data: any,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return GoatNotesService.axiosInstance.put(`${resource}`, data, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return GoatNotesService.axiosInstance.delete(`${resource}`);
  };
  
}
export default GoatNotesService;
