<template>
  <div class="row">
    <div class="col-6 d-flex justify-content-start">
      <select
        class="form-select"
        aria-label="Default select example"
        v-if="providerList.length"
        @change="valueHasChanged"
      >
      <option selected :value="0"> Select a Provider</option>
        <option
          v-for="(provider, index) in providerList"
          :key="index"
          :value="provider.provider_id"
        >
          {{ provider.first_name }} {{ provider.last_name }}
        </option>
      </select>
    </div>

    <div class="col-6 d-flex justify-content-end">
      <h2>
        <u>Earnings per Week: ${{ dailyEarnings || 0 }}</u>
      </h2>
    </div>
  </div>
  <br />

  <FullCalendar ref="calendar" :options="calendarOptions" />
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import Avatar from "@/components/Avatar.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";

export default defineComponent({
  name: "PersonalCalendarController",
  components: { FullCalendar, Avatar },
  data() {
    return {
      toast: useToast(),
      dailyEarnings: 0,
      appointmentsRes: null,
      providerList: [],
      calendarOptions: {
        //callendar options in extendedProps we need to add ishtml to yes and add a tasksarray tasks array will show as list in callendar and as a list in a table in modal under opted services
        plugins: [
          timeGridPlugin,
          dayGridPlugin,
          interactionPlugin,
          resourceTimeGridPlugin,
        ],
        initialView: "timeGridWeek",
        allDaySlot: false,
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        slotMinTime: "4:00:00",
        slotMaxTime: "19:00:00",
        expandRows: true,
        resourceOrder: "title",
        resources: [],
        dateClick: this.handleDateClick,
        eventClick: this.handleEventCick,
        datesSet: (data) => {},
        events: [],
        eventContent: function (arg) {
          let divEl = document.createElement("div");
          let tasks = "";
          if (arg.event.extendedProps.tasks) {
            arg.event.extendedProps.tasks.forEach((task) => {
              tasks += `<li>${task.service}</li>`;
            });
            tasks = `<ul>${tasks}</ul>`;
          }

          let htmlTitle = `<p class='d-flex justify-content-between font-20px'> <span>${arg.event.title}</span>  <span class='mr-2 font-20px'>$${arg.event.extendedProps.apptObject.totalCompleted}</span></p>${tasks}`;
          if (arg.event.extendedProps.isHTML) {
            divEl.innerHTML = htmlTitle;
          } else {
            divEl.innerHTML = arg.event.title;
          }
          divEl.style.overflowY = "auto";
          divEl.style.maxHeight = "-webkit-fill-available";
          let arrayOfDomNodes = [divEl];
          return { domNodes: arrayOfDomNodes };
        },
      },
    };
  },
  methods: {
    getTodaysEarnings(target) {
      if (!this.appointmentsRes) {
        return 0;
      }
      let sum = 0;
      this.appointmentsRes.forEach((dayObj) => {
        if (new Date(dayObj.date).toString() == target.toString()) {
          sum = dayObj.totalCompleted;
        }
      });
      this.dailyEarnings = sum;
    },
    async populateTasks(providerId) {
      try{
        let today = new Date();
        let  startDate = new Date(new Date().setDate(today.getDate() - 60)).toISOString();
        let endDate = new Date(new Date().setDate(today.getDate() + 30)).toISOString();

        let res = await ApiService.get("Appointment?StartDate=" + startDate +  "&EndDate=" + endDate + "&ProviderId=" + providerId);
        if(res.status !== 200){
          this.toast.error(codes.error)
        }
        console.log(res);

        this.appointmentsRes = res.data;

        this.appointmentsRes.forEach((dayObj) => {
          if (dayObj.date == today) {
            this.dailyEarnings = dayObj.totalBilled;
          }
          dayObj.providers.forEach((prov) => {
            prov.appointments.forEach((appt) => {
              this.calendarOptions.events.push({
                resourceId: appt.providerId,
                title: appt.patientInitials,
                start: appt.startDate,
                end: appt.endDate,
                description: "Lecture",
                extendedProps: {
                  isHTML: "YES",
                  apptObject: appt,
                },
              });
            });
          });
        });
      }catch (e) {
        this.toast.error(codes.error)
      }
    },
    handleDateClick(params) {
      //could be used to add an event on click on claendar
      console.log(params);
    },
    async getProviders() {
      try{
        let res = await ApiService.get("Provider");
        console.log(res.data);
        if (res) {
          let tempProviderList = res.data;

          tempProviderList.forEach((element) => {
            if (element.isHygienist) {
              this.providerList.push(element);
            }
          });

          console.log("providerList", this.providerList);
        }
        if(res.status !== 200){
          this.toast.error(codes.error);
        }
      }catch (e) {
        this.toast.error(codes.error);
        throw e;
      }
    },
    async valueHasChanged(e) {
      const providerId = e.target.value;

      if(providerId != "0"){
        await this.populateTasks(providerId);
      }
    },
  },
  async mounted() {
    console.log("mounted");
    await this.getProviders();
  },
  setup() {
    setCurrentPageTitle("Hygienist");
    return {};
  },
});
</script>
<style lang="scss" scoped>
.fc-content {
  white-space: normal;
}
.purple-bg {
  background: rgb(214, 210, 248);
}
.yellow-bg {
  background: rgb(254, 255, 175);
}
td {
  padding: 10px !important;
}
.check {
  color: rgb(76, 203, 237);
}
hr {
  color: rgb(202, 205, 209);
}
.custom-header {
  background: rgb(18, 39, 83);
  h5 {
    color: white;
  }
  .color-green {
    color: rgb(26, 171, 10);
  }
  .color-orange {
    color: rgb(237, 141, 12);
  }
  .x {
    color: white;
    font-size: 20px;
  }
}
</style>
