
import { defineComponent } from "vue";
import * as bootstrap from "bootstrap";
import GoatNotesService from "@/core/services/GoatNotesService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";
import { getDate } from "date-fns";

export default defineComponent({
	name: "Template",
	props: {
        appointmentIdProp:{type: Number,required: false},
        displayAlert:{type: Boolean, requred: false}
    },
	components: {
		Multiselect,
	},

	data() {
		return {
			appointmentId: null as number | null,
			loading: false,
			surfacesModal: null,
			showFullScreen: false,
			selectorIndex: 0,
			copied_clipboard: false,
			noteModal: bootstrap.Modal,
			noteTemplateSelected: "",
			noteIsSelected: false,
			fieldList: new Array(),
			noteTemplate: "",
			initialNoteTemplate: "",
			multiselectValues: {},
			extra_values: {},
			rowData: null as any,
			promptsList: new Array(),
			promptsTypeList: new Array(),
			promptSelectValues: new Array(),

			type5Object: [{
				selectedTeeth: new Array(),
				surfaces: new Array(),
			}],

			teeth: {
				first: [11, 12, 13, 14, 15, 16, 17, 18],
				second: [21, 22, 23, 24, 25, 26, 27, 28],
				third: [31, 32, 33, 34, 35, 36, 37, 38],
				fourth: [41, 42, 43, 44, 45, 46, 47, 48],
				fifth: [51, 52, 53, 54, 55],
				sixth: [61, 62, 63, 64, 65],
				seventh: [71, 72, 73, 74, 75],
				eighth: [81, 82, 83, 84, 85],
			},
            prefilledAnswers: new Array(),
		};
	},
	async mounted() {		
		if (this.appointmentIdProp){
			this.appointmentId = this.appointmentIdProp;
		} else {
			this.appointmentId = Number(this.$route.params.id);
		}

		let patientInitials = this.$route.query.in;
		if (patientInitials){
			setCurrentPageTitle(`New Note | ${patientInitials}`)
		} else {
			setCurrentPageTitle(`New Note`)
		}
		
		this.loading = true;
		
		let oldNoteProgressString = localStorage.getItem('noteProgress');
		await this.getNoteMetaData();		
        await this.getAllTokens();
		await this.getAllTokenTypes();
		await this.getAllTokenValues();
		// let selectedName = this.$route.params.name || x;
		this.noteTemplateSelected = "Hygiene";        
        this.createNewNote();
        await this.loadPrefilledAnswers();
		await this.loadSavedAnswers(oldNoteProgressString);
        let modalElement = document.getElementById("surfaces_modal");
		this.surfacesModal = new bootstrap.Modal(modalElement);
        this.loading = false;		
        
	},
	methods: {
		dropdownClicked(item){			
			item.model.text = "";
			this.extra_values[item.name.replaceAll(" ", "_")] = ""
		},
		async loadSavedAnswers(oldNoteProgressString){
			
			console.log("saved progress", oldNoteProgressString);
			if (oldNoteProgressString){
				let progress = (JSON.parse(oldNoteProgressString))[this.appointmentId as number];
				if (progress){
					this.noteTemplate = progress.text;
					this.selectorIndex = progress.step;

					for(let item of this.fieldList){
						let savedItem = progress.model.find(i => i.name === item.name);
						item.model = savedItem.model;
					}
				}	
			}

		},
		async loadPrefilledAnswers(){

			const params = {
				appointmentId: this.appointmentId,
				templateName: this.noteTemplateSelected                    
			};
			
			let res = await ApiService.query("Note/Prefil", { params }) as any;        
			this.prefilledAnswers.push(...res.data);

			for(let answer of this.prefilledAnswers){
				let answeredField = this.fieldList.find(f => f.name === answer.token);
				if (answeredField){
					//i need to find the control and prefil it
					if (answeredField.type === "Text"){
						let input = document.querySelector(`textarea#${CSS.escape(answeredField.name)}`) as HTMLInputElement                    
						input.value = answer.answer;
						answeredField.model = answer.answer;
					} else if (answeredField.type === "Drop down"){
						let selectElement = document.querySelector(`select#${CSS.escape(answeredField.name)}`) as HTMLSelectElement
						let foundOption = false;
						for (let i = 0; i < selectElement.options.length; i++) {
							if (selectElement.options[i].value == answer.answer){
								selectElement.options[i].selected = true;
								foundOption = true;
							} else {
								selectElement.options[i].selected = false;
							}                        
						}
						if (!foundOption){
							let input = document.querySelector(`input#extra_${CSS.escape(answeredField.name.replaceAll(' ', '_'))}`) as HTMLInputElement                    
							input.value = answer.answer;  
							answeredField.model = answer.answer;
							this.extra_values[`${answeredField.name.replaceAll(" ", "_")}`] = answer.answer;
						}                    
					} else if (answeredField.type === "Multiselect") {
						let extraInput = document.querySelector(`input#extra_${CSS.escape(answeredField.name.replaceAll(' ', '_'))}`) as HTMLInputElement
						let answers = answer.answer.split(answeredField.seperator)
						let allOptions =  Array.from(document.querySelectorAll(`[data-group="${answeredField.name}"]`)); 
						let noMatches = new Array();
						for(let singleAnswer of answers){
							let matchingOption = allOptions.find(o => (o as HTMLInputElement).value.toUpperCase() === singleAnswer.toUpperCase()) as HTMLInputElement
							//let matchingOption = (null as any) as HTMLInputElement
							if (matchingOption) {
								matchingOption.checked = true;
								answeredField.model.options[answeredField.name] = true;
							} else {
								noMatches.push(singleAnswer);
							}
							
						}

						extraInput.value = noMatches.join(answeredField.seperator);
						answeredField.model.text = extraInput.value;
						this.extra_values[`${answeredField.name.replaceAll(" ", "_")}`] = extraInput.value;
					}
					this.livePreview(answeredField.name);
				}
			}
			this.instantPreview();
		},
        multiselectClick(option, item, e){
			let noneOption = item.list.find(v => v.isNone);
			if (!noneOption){
				return;
			}			
			
			let allOptions =  Array.from(document.querySelectorAll(`[data-group="${item.name}"]`));			
			let noneOptionHtml = allOptions.find(v => (v as HTMLInputElement).value === noneOption.value) as HTMLInputElement;
			console.log("option clicked", option, item, e, allOptions, noneOptionHtml);
			if (option.isNone){ //was the none option clicked? 
				
				if (noneOptionHtml.checked){ //if yes, then uncheck everything else
					allOptions.filter(v => (v as HTMLInputElement).value  !== noneOption.value).forEach(v => (v as HTMLInputElement).checked = false);					
				}
			} else { //someting else was clicked, clear the none option
				//find the nulll option				
				noneOptionHtml.checked = false;
			}
		},

		//returns text with prompts highlighted
		returnHighlightedNote() {
			let prompts = this.promptsList.map(x => x.name);
			for (let i = 0; i < prompts.length; i++) {
				prompts[i] = `~${prompts[i]}~`
			}
			let initialText = this.noteTemplate;
			prompts.forEach(prompt => {
				initialText = initialText.replaceAll(prompt, `<b>${prompt}</b>`)
			});
			if (this.$refs.textareafake) {
				console.log("filling div");
				(this.$refs.textareafake as HTMLDivElement).innerHTML = `${initialText.replaceAll("\n", "<br />\n")}`;
			}
		},

		//return surfaces for current tooth number
		checkSurfacesForTeethNumber(endingToothNumber) {
			let num = endingToothNumber.toString().split("")[1];
			if (num == 4 || num == 5 || num == 6 || num == 7 || num == 8) {
				return ["M", "O", "D", "B", "L"];
			} else if (num == 1 || num == 2 || num == 3) {
				return ["M", "I", "D", "B", "L"];
			}
		},

		//used to set text field type value
		inputClicked(event) {
			console.log(event.key);
			let enterClicked = event.key == "Enter";
			if (enterClicked) {
				if (this.selectorIndex < this.fieldList.length - 1) {
					this.selecterdecrementOrIncrement(1);
				} else if (this.selectorIndex == this.fieldList.length - 1) {
					this.preview();
				}
			}
		},

		// used for previous and next button knows when to revert step or live preview current and go to next one
		selecterdecrementOrIncrement(inc) {
			if (inc > 0) {
				this.livePreview(this.fieldList[this.selectorIndex].name);
			} else if (inc < 0) {
				this.revertStep();
			}
			if (this.selectorIndex < this.fieldList.length && this.selectorIndex >= 0) {
				this.selectorIndex += inc;
				this.selectorIndex = this.selectorIndex < 0 ? 0 : this.selectorIndex;
				this.selectorIndex = this.selectorIndex >= this.fieldList.length ? this.fieldList.length - 1 : this.selectorIndex;
			}
		},


		async getNoteMetaData() {
            //let res = await ApiService.get(`/appointment/${this.appointmentId}/Note`);
			let params = {
					groupName: this.$store.getters.currentClinicInfo.name,
					templateName : this.$store.getters.currentUser.email,
				}
            let res = await GoatNotesService.query(`Template`, { params });
			this.rowData = res.data;
		},


		async getAllTokens() {
			let res = await GoatNotesService.get("Token");
			if (res) {
				this.promptsList = res.data;
				console.log(this.promptsList);
			}			
		},


		async getAllTokenTypes() {
			await GoatNotesService.get("TokenType").then((res) => {
				if (res) {
					this.promptsTypeList = res.data;
				}
			});
		},


		async getAllTokenValues() {
			let res = await GoatNotesService.get("TokenPossibleValue");
			let res2 = await ApiService.get(`Note/TokenPossibleValue/${this.appointmentId}`);
			console.log("promptSelectValues1",res.data);			
			for(let token of this.promptsList){
				let clinicSpecific = res2.data[token.name];				
				if (clinicSpecific && clinicSpecific.length> 0){
					clinicSpecific.forEach((tpv) => {
						tpv.tokenId = token.tokenId;
					});
					this.promptSelectValues.push(...clinicSpecific);					
				} else {
					this.promptSelectValues.push(...(res.data.filter(t => t.tokenId === token.tokenId)))
				}
			}
			console.log("promptSelectValues2", this.promptSelectValues);
		},

		//used to set extra text value on top
		setFieldExtraValue(type, e, item) {
			console.log(e.target.value, item.name);
			if (type === "Drop down"){
				item.model.options = null;
				let selectVal = document.getElementById(item.name) as HTMLSelectElement;
				selectVal.value = '';
			}
			this.extra_values[`${item.name.replaceAll(" ", "_")}`] = e.target.value;
			this.instantPreview()
		},

		//gets tokens from note and create the fieldList
		createNewNote() {
			this.fieldList = [];
			this.noteIsSelected = true;
			this.noteTemplate = this.rowData.text;
			this.initialNoteTemplate = this.noteTemplate;
			let regexp = new RegExp("~(.*?)~", "g");
			let selectedUsedPrompts: RegExpMatchArray | null = [];

			selectedUsedPrompts = Array.from(this.noteTemplate.matchAll(regexp), (m) => m[0]);
			console.log(selectedUsedPrompts);
			let usedPrompts = new Array();
			let newUsedPrompts = new Array();

			if (selectedUsedPrompts && selectedUsedPrompts[0]) {
				usedPrompts = selectedUsedPrompts[0].split("~");
				selectedUsedPrompts.forEach((element) => {
					if (element.includes("~")) {
						element = element.replaceAll("~", "");
						if (!newUsedPrompts.includes(element)) {
							newUsedPrompts.push(element);
						}
					}
				});

				newUsedPrompts.forEach(async (element) => {
					for (var i = 0; i < this.promptsList.length; i++) {
						if (this.promptsList[i].name.toLowerCase() == element.toLowerCase()) {
							let type = this.promptsTypeList.filter((x) => x.tokenTypeId == this.promptsList[i].tokenTypeId);
							let list = new Array();
							if (type[0].tokenTypeName == "Drop down" || type[0].tokenTypeName == "Multiselect" || 
								type[0].tokenTypeName == "Multiselect and count" || type[0].tokenTypeName == "checklist") {								
								list = this.promptSelectValues.filter((x) => x.tokenId == this.promptsList[i].tokenId);
							} else {
								list = [];
							}
							if (type[0].tokenTypeName == "Tooth surface select" || type[0].tokenTypeName == "Toothselect") {
								this.type5Object[element] = {
									selectedTeeth: [],
									surfaces: [],
								}
							}
							let model = null as any;
							if (type[0].tokenTypeName === "Multiselect"){
								let selected = list.filter(l => l.isDefault).map(l => l.value);
								if (selected.length > 0){
									model = { text: "", options: selected };
								} else {
									model = { text: "", options: {} };
								}								
							} else if (type[0].tokenTypeName === "Drop down"){
								let selected = list.filter(l => l.isDefault).map(l => l.value);;
								if (selected.length > 0){
									model = { text: "", options: selected[0] };
								} else {
									model = { text: "", options: "" };
								}
							} else {
								model = "";
							}
							console.log(element, model);
							this.fieldList.push({ name: element, 
								type: type[0].tokenTypeName, list: list, seperator: this.promptsList[i].seperator, model: model});
						}
					}
				});
				console.log(this.type5Object)
			}
		},

		//same as live preview func but gets called onChange for any input concerning prompts
		instantPreview() {
			console.log("instant preview", this.fieldList);
			this.showFullScreen = false;
			this.returnHighlightedNote();
			this.noteTemplate = this.initialNoteTemplate;
			let currentIndex: number = 0;
			this.fieldList.forEach((field) => {
				if (currentIndex <= this.selectorIndex) {
					this.processField(field);
					currentIndex++;
				} else {
					return;
				}
			});
		},

		//goes back one step and completes all but current step
		revertStep() {
			this.showFullScreen = false;
			this.noteTemplate = this.initialNoteTemplate;
			let currentIndex: number = 0;
			this.fieldList.forEach((field) => {
				if (currentIndex < this.selectorIndex - 1) {
					this.processField(field);
					currentIndex++;
				} else {
					return;
				}
			});
			this.returnHighlightedNote();

		},

		// goes forward 1 step and completes previous step
		livePreview(id) {
			let field = this.fieldList.find((x) => x.name == id);
			this.processField(field);
		},

		// completes all steps in one go and copies to clipboard
		preview() {
			this.noteTemplate = this.initialNoteTemplate;
			console.log(this.fieldList);

			this.fieldList.forEach((field) => {
				this.processField(field);
			});
			let self = this;
			this.fieldList.forEach((f) => {
				this.noteTemplate = this.noteTemplate.replaceAll(`~${f.name}~`, "");
				this.noteTemplate = this.noteTemplate.replaceAll("<mark>", "")
				this.noteTemplate = this.noteTemplate.replaceAll("</mark>", "")

			});
			navigator.clipboard.writeText(this.noteTemplate).then(
				function () {
					console.log("Async: Copying to clipboard was successful!");
					self.copied_clipboard = true;
					self.showFullScreen = true;
					setTimeout(() => {
						self.copied_clipboard = false;
					}, 2000);
				},
				function (err) {
					console.error("Async: Could not copy text: ", err);
				}
			);
		},

		processField(field){
			let inputVal = document.getElementById(field.name) as HTMLInputElement;
			let extra_valuetext = this.extra_values[field.name.replaceAll(" ", "_")] || "";

			if (field.type == "Text") {
				if (inputVal.value && inputVal.value != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${inputVal.value}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			}
			let selectVal = document.getElementById(field.name) as HTMLSelectElement;

			if (field.type == "Drop down") {
				if (selectVal.value && selectVal.value != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${selectVal.value}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				} else if (extra_valuetext && extra_valuetext.value != "") {
                    this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${extra_valuetext}</mark>`);
                }
			} else if (field.type == "Multiselect") {
				let values = "";
				let count = 0;
				console.log(field);
				let sep = this.promptsList.find((x) => x.tokenId == field.list[0].tokenId).seperator;
				for (let i = 0; i < field.list.length; i++) {
					const element = field.list[i];
					let inputVal = document.getElementById(element.tokenPossibleValueId) as HTMLInputElement;

					if (inputVal && inputVal.checked && inputVal.value && inputVal.value != "") {
						count += 1;
						count == 1 ? (values = inputVal.value) : (values = values + (sep == null ? "" : sep || "") + (inputVal.value || ""));
					}
				}
				if (values != null && values !== "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${values}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				} else if (extra_valuetext != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Multiselect and count") {
				let values = "";
				let count = 0;
				console.log(field);
				let sep = this.promptsList.find((x) => x.tokenId == field.list[0].tokenId).seperator;
				for (let i = 0; i < field.list.length; i++) {
					const element = field.list[i];
					let inputVal = document.getElementById(`_${element.tokenPossibleValueId}`) as HTMLInputElement;
					let counterInputVal = document.getElementById(`counter_${element.tokenPossibleValueId}`) as HTMLInputElement || "";
					if (Number(counterInputVal.value) > 0) {
						inputVal.checked = true;
					} else {
						inputVal.checked = false;
					}
					if (inputVal && inputVal.checked && inputVal.value && inputVal.value != "") {
						count += 1;
						count == 1 ? (values = (counterInputVal.value ? ` ${counterInputVal.value}` : '') + " " + inputVal.value) : (values = values + (sep == null ? "" : sep || "") + (counterInputVal.value ? ` ${counterInputVal.value}` : '') + " " + (inputVal.value || ""));
					}
				}
				if (values != null && values !== "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${values}${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				} else if (extra_valuetext != "") {
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${extra_valuetext ? " " + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Toothselect") {
				if (this.type5Object[field.name] && this.type5Object[field.name].selectedTeeth != null && this.type5Object[field.name].selectedTeeth.length > 0) {
					let type5String = "";
					for (let z = 0; z < this.type5Object[field.name].selectedTeeth.length; z++) {
						if (z == this.type5Object[field.name].selectedTeeth.length - 1) {
							let t = this.type5Object[field.name].selectedTeeth[z];
							let s = this.type5Object[field.name].surfaces[z];
							type5String += `${t}${s.join("")}`;
						} else {
							let t = this.type5Object[field.name].selectedTeeth[z];
							let s = this.type5Object[field.name].surfaces[z];
							type5String += `${t}${s.join("")}, `;
						}
					}
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${type5String.toString()}${extra_valuetext ? ' ' + extra_valuetext : ""}</mark>`);
				}
			} else if (field.type == "Tooth surface select") {
				if (this.type5Object[field.name] && this.type5Object[field.name].selectedTeeth != null && this.type5Object[field.name].selectedTeeth.length > 0) {
					let type5String = "";
					for (let z = 0; z < this.type5Object[field.name].selectedTeeth.length; z++) {
						if (z == this.type5Object[field.name].selectedTeeth.length - 1) {
							let t = this.type5Object[field.name].selectedTeeth[z];
							let s = this.type5Object[field.name].surfaces[z];
							type5String += `${t}${s.join("")}`;
						} else {
							let t = this.type5Object[field.name].selectedTeeth[z];
							let s = this.type5Object[field.name].surfaces[z];
							type5String += `${t}${s.join("")}, `;
						}
					}
					this.noteTemplate = this.noteTemplate.replaceAll("~" + field.name + "~", `<mark>${type5String.toString()}${extra_valuetext ? ' ' + extra_valuetext : ""}</mark>`);
				}
			}
			let local = localStorage.getItem('noteProgress');
			let noteProgress = {};
			if (local){
				noteProgress = JSON.parse(local);				
				for(let appointmentId in noteProgress){
					const timeDifferenceHours = ((new Date() as any) - noteProgress[appointmentId].dateModified) / (1000 * 60 * 60);
					if (timeDifferenceHours > 24){
						delete noteProgress[appointmentId];
					}
				}
			}
			noteProgress[this.appointmentId as number] = {step: this.selectorIndex, 
					text: this.noteTemplate, model: this.fieldList, dateModified: new Date()};
			localStorage.setItem('noteProgress', JSON.stringify(noteProgress));
		},

		// used to open modal containing surfaces
		openSurfacesModal(evt) {
			var VPWH = [];                  // view port width / height
			var intVPW, intVPH;             // view port width / height
			var intCoordX = evt.clientX;
			var intCoordY = evt.clientY;    // distance from click point to view port top
			var intDistanceScrolledUp = document.body.scrollTop;
			// distance the page has been scrolled up from view port top
			var intPopupOffsetTop = intDistanceScrolledUp + intCoordY;
			// add the two for total distance from click point y to top of page

			var intDistanceScrolledLeft = document.body.scrollLeft;
			var intPopupOffsetLeft = intDistanceScrolledLeft + intCoordX;

			// VPWH = [window.innerWidth, (window.innerHeight as )];    // view port Width/Height
			// intVPW = VPWH[0];
			// intVPH = VPWH[1];
			(this.surfacesModal as any).show(); //surfaces_modal
			// let popup = document.getElementById("surfaces_modal") as HTMLDivElement;
			//
			// popup.style.position = 'absolute';
			// // if not display: block, .offsetWidth & .offsetHeight === 0
			// popup.style.display = 'block';
			// popup.style.zIndex = '10100';
			//
			// if ( intCoordX > intVPW/2 ) { intPopupOffsetLeft -= popup.offsetWidth; }
			// // if x is in the right half of the viewport, pull popup left by its width
			// if ( intCoordY > intVPH/2 ) { intPopupOffsetTop -= popup.offsetHeight; }
			// // if y is in the bottom half of view port, pull popup up by its height
			//
			// popup.style.top = intPopupOffsetTop + 'px';
			// popup.style.left = intPopupOffsetLeft + 'px';

		},

		// used to close modal containing surfaces
		closeSurfacesModal() {
			(this.surfacesModal as any).hide();
			this.instantPreview() // added this here because whenever modal closes it should be when user is done thus updating values
		},

		//used to add a surface for a specific tooth for a specific token
		addSurfaceForTooth(toothNumber, surface, name) {
			for (let i = 0; i < this.type5Object[name].selectedTeeth.length; i++) {
				const element = this.type5Object[name].selectedTeeth[i];
				if (element == toothNumber) {
					if (!this.type5Object[name].surfaces[i].find((x) => x == surface)) {
						this.type5Object[name].surfaces[i].push(surface);
					} else {
						let targetIndex = this.type5Object[name].surfaces[i].indexOf(surface);
						this.type5Object[name].surfaces[i].splice(targetIndex, 1);
					}
				}
			}
		},

		//used to add a tooth for a specific token then opens the tooth surface modal if type needs surfaces
		addTeethSealant(e, teethNumber, type, name) {
			console.log(e)
			let endingToothNumber = teethNumber.toString().split("")[1];

			if (!this.type5Object[name].selectedTeeth) {
				this.type5Object[name].selectedTeeth = [];
				this.type5Object[name].selectedTeeth.push(teethNumber);
				this.type5Object[name].surfaces = [];
				e.target.classList.remove("btn-secondary");
				e.target.classList.add("btn-primary");
				if (endingToothNumber == 4 || endingToothNumber == 5 || endingToothNumber == 6 || endingToothNumber == 7 || endingToothNumber == 8 || endingToothNumber == 1 || endingToothNumber == 2 || endingToothNumber == 3) {
					this.type5Object[name].surfaces.push([]);
					if (type == 'Tooth surface select') {
						this.openSurfacesModal(e);
					} else {
						this.instantPreview()
					}
				} else {
					this.instantPreview()
				}
			} else {
				let remove = this.type5Object[name].selectedTeeth.find((x) => x === teethNumber);
				if (remove) {
					for (let i = 0; i < this.type5Object[name].selectedTeeth.length; i++) {
						const teethNum = this.type5Object[name].selectedTeeth[i];
						if (teethNum === teethNumber) {
							this.type5Object[name].selectedTeeth.splice(i, 1);
							this.type5Object[name].surfaces.splice(i, 1);
							e.target.classList.remove("btn-primary");
							e.target.classList.add("btn-secondary");
							this.instantPreview();
							return;
						}
					}
				} else {
					this.type5Object[name].selectedTeeth.push(teethNumber);
					e.target.classList.remove("btn-secondary");
					e.target.classList.add("btn-primary");
					if (endingToothNumber == 4 || endingToothNumber == 5 || endingToothNumber == 6 || endingToothNumber == 7 || endingToothNumber == 8 || endingToothNumber == 1 || endingToothNumber == 2 || endingToothNumber == 3) {
						this.type5Object[name].surfaces.push([]);
						if (type == 'Tooth surface select') {
							this.openSurfacesModal(e);
						} else {
							this.instantPreview();
						}
					} else {
						this.instantPreview()
					}
				}
			}
		},


	},
});
