
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { defineComponent, reactive, computed, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Models } from "@/Models/Models";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";
import { ProviderTypeEnum } from "@/Models/data-contract/index";  
import { useStore } from "vuex";
interface FormState {
    providerId: string;
    providerFirstName: string;
    providerLastName : string;
    goalWage: number;
    baseWage: number;
    perHourGoal: number;
    excludeFromCalendar: boolean;
    notes: string;
    excludeFromAnalysis: boolean;
    userId: number;
    providerType: ProviderTypeEnum;
}



export default defineComponent({
  name: "EditProvider",
  props: {
    selectedProvider: {
      required: true,
      type: Object,
    },
    initialProvider: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      toast: useToast(),
      errorMessageCustom: "",
      assignableUsers: null,
      providers: [] as { id: ProviderTypeEnum; name: string; }[],
      providerTypeHygienist: ProviderTypeEnum.Hygienist
    };
  },
  updated() {
  if (this.selectedProvider  && this.providerId != this.selectedProvider.provider_id) {
    console.log(this.selectedProvider)
        this.providerId = this.selectedProvider.provider_id;
        this.state.providerId = this.selectedProvider.provider_id;
        this.state.providerFirstName = this.selectedProvider.first_name;
        this.state.providerLastName = this.selectedProvider.last_name;
        this.state.notes = this.selectedProvider.notes;
        this.state.userId = this.selectedProvider.userId;
        this.state.excludeFromAnalysis = this.selectedProvider.excludeFromAnalysis.toString() == "Yes" ? true : false;
        if (this.selectedProvider.goalWage == null || this.state.goalWage.toString() == '' ){
          this.state.goalWage = 0;
        }else{
          this.state.goalWage = this.selectedProvider.goalWage;
        }
        this.state.perHourGoal = Math.round(this.state.goalWage/this.$store.getters.currentClinicInfo.goalPercentage);

        if (this.selectedProvider.baseWage == null || this.state.baseWage.toString() == ''){
          this.state.baseWage = 0;
        }else{
          this.state.baseWage = this.selectedProvider.baseWage;
        }

        this.state.excludeFromCalendar = this.selectedProvider.excludeFromCalendar.toString() == "Yes" ? true : false;
        this.state.providerType = this.selectedProvider.providerType;

      }
    },
  methods: {
    clearForm(){
      (this.$refs.editProviderForm as HTMLFormElement).reset();
    },
    showErrorMessage(message) {
      this.errorMessageCustom = message;
      let self = this;
      setTimeout(() => {
        self.errorMessageCustom = "";
      }, 5000);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const initialProvider = this.initialProvider as any;
          // console.log(initialProvider);

          if (this.state.perHourGoal == null || this.state.perHourGoal.toString() == ''){
            this.state.perHourGoal = 0;
          }

          if (this.state.baseWage == null || this.state.baseWage.toString() == ''){
            this.state.baseWage = 0;
          }

          let payload: any = {
            providerId: this.state.providerId,
            goalWage: Math.round(((this.state.perHourGoal * this.$store.getters.currentClinicInfo.goalPercentage) + Number.EPSILON) * 100) / 100, //this.state.goalWage,
            baseWage: this.state.baseWage,
            excludeFromCalendar: this.state.excludeFromCalendar,
            notes: this.state.notes,
            excludeFromAnalysis: this.state.excludeFromAnalysis,
            userId: this.state.userId,
            providerType: this.state.providerType
          };

          if (!payload) {
            return;
          } else {
            // console.log(payload);
            const response = await ApiService.postData("Provider", payload, {});
            // console.log("RESPONSE", response);

            if (response.status === 200) {
              // console.log(response.data);
              if (this.selectedProvider) {
                this.$emit("closeEditProviderForm");
              }
            } else {
              console.error(response);
              this.toast.error(codes.error)
            }
          }
        } catch (ex) {
          this.toast.error(codes.error)
          console.warn(ex);
        }
      }
    },
    discard() {
      // console.log("discard");
      this.$emit("verifyParamsSubmitCompleted");
    },
  },

  async mounted(){
    try {
      let res = await ApiService.get('/User/GetAssignableUsers')
      if(res.status === 200) {
        this.assignableUsers = res.data;
      }else{
        this.toast.error(codes.error)
      }
    }catch (e) {
      this.toast.error(codes.error)
    }
    // Prepare Provider data
    const prepareProvider = () => { 
      return [  
      {
        id: ProviderTypeEnum.Doctor,
        name: 'Doctor'
      },
      {
        id: ProviderTypeEnum.Hygienist,
        name: 'Hygienist'
      },
      {
        id: ProviderTypeEnum.Other,
        name: 'Other'
      },
    ]};
    this.providers = prepareProvider();
  },

   setup(props, context) {

    const store = useStore();
    // create provider form
    // console.log("props: ", props);
    let providerId = "";
    let state: FormState = reactive({
        providerId: "",
        providerFirstName: "",
        providerLastName: "",
        goalWage: 0,
        perHourGoal: 0,
        baseWage: 0,
        excludeFromCalendar: true,
        notes: "",
        excludeFromAnalysis: true,
        userId: -1,
        providerType: ProviderTypeEnum.Other
    });

    if (props.selectedProvider) {
      const provider_to_edit = props.selectedProvider as Models.ProviderToEdit;
      providerId = provider_to_edit.provider_id;

      if (provider_to_edit.goalWage == null || provider_to_edit.goalWage.toString() == ''){
        provider_to_edit.goalWage = 0;
      }

      if (provider_to_edit.baseWage == null || provider_to_edit.goalWage.toString() == ''){
        provider_to_edit.baseWage = 0;
      }

      let stateEdit: any = reactive({
          providerId: provider_to_edit.provider_id,
          providerFirstName: provider_to_edit.first_name,
          providerLastName: provider_to_edit.last_name,
          goalWage: provider_to_edit.goalWage,
          perHourGoal: Math.round(provider_to_edit.goalWage/store.getters.currentClinicInfo.goalPercentage),
          baseWage: provider_to_edit.baseWage,
          excludeFromCalendar: provider_to_edit.excludeFromCalendar.toString() == "Yes" ? true : false,
          notes: provider_to_edit.notes,
          excludeFromAnalysis: provider_to_edit.excludeFromAnalysis.toString() == "Yes" ? true : false,
          userId: provider_to_edit.userId,
          providerType: provider_to_edit.providerType
      });

      state = stateEdit;

    }

    //const greaterThanBase = (value) => state.goalWage >= state.baseWage;

    const rules: any = computed(() => {
      return {
        // goalWage: {
        //   maxValue: helpers.withMessage("Goal wage can't be less than base wage.", greaterThanBase),
        // }
      };
    });


    let v$ = useValidate(rules, state);

    const closeForm = () => {
      context.emit("closeEditProviderForm");
    };

    return {
      state,
      closeForm,
      v$,
      providerId,
    };
  },
});
