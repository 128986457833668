<template>
  <div :class="`pl-0 col-12 col-sm-12 col-md-6 col-lg-6`" v-if="permission">
    <div
      :class="
        `card p-2 ${
          remainingToGoal > 0
            ? 'orange-white-background'
            : 'green-white-background'
        }`
      "
    >
      <div class="row px-7 d-flex align-items-center justify-content-center">
        <div class="col-4 ">
          <div>
            <div class="d-flex mb-2">              
              <span class="fs-2hx fw-bolder text-gray-800 me-2 lh-1 color-white" v-if="this.userPrefs.performanceDisplay !== 'dollars' && this.userPrefs.performanceDisplay !== 'none'">{{ getGrade }}</span>
            </div>            
            <span class="fs-5 fw-bold text-gray-400 w-100 color-white" v-if="this.userPrefs.performanceDisplay !== 'none'" :title="`(${achievedValue}/${goalValue})`">${{appointmentProductionInDollars}}</span>
          </div>
        </div>
        <div
          class="col-4 d-flex justify-content-center middle-card-indicator py-2"
          style="height: 74px;"
        >          
          <div class="row d-flex align-items-center">
            <span class="col-3 color-orange"><i v-if="earningsStatus().status" class="fas fs-30-px fa-check-circle color-green" width="100%"></i><inline-svg  :src="earningsStatus().icon" class="fs-3" width="100%" v-else /> </span>
            <span class=" col-9 color-orange fs-5 fw-bold">{{ earningsStatus().message }}</span>
          </div>
        </div>
        <div class="col-4 d-flex flex-row-reverse">
          <radial-chart
            :series="radialChartDataProvider"
            :title="userPrefs.performanceDisplay == 'dollars' || userPrefs.performanceDisplay == 'none' ? '' : 'A+'"
            :color="remainingToGoal > 0 ? '#ff8000' : '#50cd89'"
          />
        </div>
        <!-- <div class="col-2"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import RadialChart from "@/components/Charts/RadialChart.vue";

export default {
  components: { RadialChart },
  data() {
    return {};
  },
  props: {
    permission: { type: Boolean, required: true },
    appointmentProductionInDollars: { type: Number, required: true },
    goalValue: { type: Number, required: true },
    userPrefs: {type: Object, required: true},
    achievedValue: {type: Number, required: true},
  },
  methods:{
    earningsStatus(){

        let message = "";
        let grade = this.getGrade;
        switch (grade) {
          case "A+":
            message = "Excellent!"
            break;
          case "A":
            message =  "Looking good!"
            break;
          case "B":
            message =  "Almost there!"
            break;
          case "C":
            message =  "Almost there!"
            break;
          case "D":
            message =  "Let's get going."
            break;
          default:
            message =  "Something went wrong please contact support."
            break;
        }
        if(grade == "A+"){
          return {message: message, icon: '/media/icons/duotone/Navigation/Check.svg', status: true};
        }else{
          return {message: message, icon: '/media/svg/dashboard/need_improve.svg', status: false};
        }      
    },
  },
  mounted(){


  },
  computed: {
    radialChartDataProvider: function() {
      let series = [];
      let sum = this.achievedValue;
      let seriesLength = Math.floor(sum / this.goalValue);
      for (let i = 0; i < seriesLength; i++) {
        series.push(100);
        sum -= this.goalValue;
      }
      series.push(Math.floor((sum / this.goalValue) * 100));
      return series;
    },
    remainingToGoal: function() {
      let remaining = 0;
      // if (this.old) {
      //   remaining = this.goal - this.appointment.totalCompleted;
      // } else {
      remaining = this.goalValue - this.achievedValue;
      // }
      return remaining < 0 ? 0 : remaining;
    },
    getGrade: function(){
      let goal = this.goalValue;
      let remaining = goal - this.appointmentProductionInDollars;
      let grade = "";
      if(this.achievedValue  >= this.goalValue ){
        grade = "A+";
      }
      if(this.achievedValue < this.goalValue){
        grade = "A";
      }
       if(this.achievedValue < this.goalValue * 0.9){
        grade = "B";
      }
      if(this.achievedValue < this.goalValue * 0.8){
        grade = "C";
      } 
      if(this.achievedValue < this.goalValue * 0.7){
        grade = "D";
      }
      return grade;      
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/customStyle/style.scss';
.orange-white-background {
  background: $orange;
  background: linear-gradient(90deg, $orange 50%, rgba(255, 255, 255, 1) 50%);

  .middle-card-indicator {
    background: white;
    border: 0.5px solid $orange;
    border-radius: 50px;
  }
}
.green-white-background {
  background: $green;
  background: linear-gradient(90deg, $green 50%, rgba(255, 255, 255, 1) 50%);
  .middle-card-indicator {
    background: white;
    border: 0.5px solid $green;
    border-radius: 50px;

    .color-orange {
      color: $green !important;
    }
  }
}
.icon-success{
  border-radius: 50px;
}
.red-white-background {
  background: $red;
  background: linear-gradient(90deg, $red 50%, rgba(255, 255, 255, 1) 50%);
  .middle-card-indicator {
    background: rgb(255, 255, 255);
    border: 0.5px solid $red;
    border-radius: 50px;

    .color-orange {
      color: $red !important;
    }
  }
}
	@media screen and (max-width: 500px) {
		.pl-0 {
			padding: 0px !important;
		}
	}
</style>
