<template>
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="user_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Add user for ${currentClinicName}` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <edit-user
          v-if="showCreateEditUser"
          :selectedUser="null"          
          @closeAddUserForm="closeAddUserForm"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!--end::Modal - New Card-->

  <div :class="`card ${widgetClasses}`" style="width:100%;height:100%;">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Users</span>

        <!--<span class="text-muted mt-1 fw-bold fs-7">Over 500 members</span>-->
      </h3>

      <div
        class="card-toolbar mr-3 "
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a new user"
      >
        
        <a @click="newUser()" class="btn btn-sm btn-light-primary add-user button-font-shadow shadow">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotone/Communication/Add-user.svg" />
          </span>
          New User
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 row">
      <!--begin::Table container-->
      <div class="table-responsive row">
        <!--begin::Table-->
        <div class="row">
          <ag-grid-vue
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :rowHeight="35"
            @grid-ready="onGridReady"
            @gridSizeChanged="gridSizeChanged"
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
          >
          </ag-grid-vue>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>

  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="editUser_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Edit user for ${currentClinicName}` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <edit-user
          v-if="editUserVisible"
          :selectedUser="selectedUser"
          :initialUser="initialUser"
          @closeEditUserForm="closeEditUserForm"
        />
      </div>
    </div>
  </div>
  <!-- end modal -->
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="delete_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Delete User` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this user?</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button class="btn btn-danger" @click="deleteUser(this.selectedUser.userId)">Confirm</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal -->
  <!-- start modal -->
  <div class="modal fade" tabindex="-1" id="password_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ `Change User Password` }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
            <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <EditUserPassword :selected-user="selectedUser"  @closeEditPassModal = "passwordModal.hide()"/>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal -->

  <!--end::Tables Widget 9-->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
// import CreateEditUser from "@/components/settings/CreateEditUser.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EditUser from "@/components/settings/EditUser.vue";
import { useStore } from "vuex";
import EditDeletePassword from "@/components/settings/EditDeletePassword.vue";
import EditUserPassword from "@/components/settings/EditUserPassword.vue";
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";
import UserManagementLastLoggedIn from "@/components/settings/UserManagementLastLoggedIn.vue"
// import UserManagmentBtnCellRenderer from "@/components/settings/UserManagmentBtnCellRenderer.vue"
export default defineComponent({
  name: "kt-widget-9",
  components: {
    EditUserPassword,
    AgGridVue,
    EditUser
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      toast: useToast(),
      list: [],
      modal: null,
      editUserModal: null,
      deleteUserModal: null,
      showCreateEditUser: false,
      editUserVisible: false,
      passwordModal: null,
      selectedUser: null,
            //ag-grid
      defaultColDef: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      api: null,
    };
  },
  async beforeMount() {
    this.refreshTable();
  },

  async mounted() {
    //     const store = useStore();
    // const initialUser = store.getters.currentUser;

    this.loadData();
    var modalElement = document.getElementById("user_modal");
    this.modal = new bootstrap.Modal(modalElement);
    if (modalElement) {
      var self = this;
      modalElement.addEventListener("hidden.bs.modal", function(event) {
        self.showCreateEditUser = false;
      });
    }
    // editUser_modal
    var modalElement = document.getElementById("editUser_modal");
    this.editUserModal = new bootstrap.Modal(modalElement);
  },
  methods: {
    async refreshTable() {
      try{
        this.defaultColDef = {
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
          suppressMenu: true,
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true,
            suppressAndOrCondition: true,
          },
        };

        let self = this;
        this.columnDefs = [   
          { field: "firstName", filter: true },
          { field: "lastName", filter: true },
          { field: "email", filter: true},
          { field: "lastLoggedInDate",
            headerName: "Last Logged In",
            cellRendererFramework: UserManagementLastLoggedIn, 
            filter:true,
            cellRendererParams: {
              resendInvitation: this.resendInvitation,

            },
        }
        ,
          {
            field: "edit",
            headerName: "Actions",
            cellRendererFramework: EditDeletePassword,
            resizable: false,
            filter: false,
            maxWidth: 187,
            cellRendererParams: {
              isOnlyEdit: false ,             
              clicked: function(selectedData) {
                if (self.showCreateEditUser) {
                  self.showCreateEditUser = !self.showCreateEditUser;
                }
                self.selectedUser = selectedData;
                self.editUserVisible = true;
                self.editUserModal.show();
              },
              deleteClicked: function(selectedData) {
                self.showDeleteUserModal(selectedData);
              },
              changePassword: function(selectedData) {
                self.changeUserPassword(selectedData);
              },
            },
          },
        ];

        let usersList = await ApiService.get("User");
        if(usersList.status !== 200){
          this.toast.error(codes.error)
        }
        usersList = usersList.data;
        console.log(usersList);
        for (let i = 0; i < usersList.length; i++) {
          usersList[i].edit = usersList[i];
        }
        console.log(usersList)
        this.rowData = usersList;
      }catch (e) {
        //console.log(e);
        this.toast.error(codes.error)
      }
    },
     async resendInvitation(userId){
        let res = await ApiService.post(`user/${userId}/invitation`);
        if(res.status !== 200 && res.status !== 204){
          this.toast.error(codes.error)
        }
        else{
          this.toast.success("Invitation was sent");
        }
    },
    async deleteUser(userId) {
      let res = await ApiService.delete(`user/${userId}`);
      if(res.status !== 200 && res.status !== 204){
          this.toast.error(codes.error)
        }
        this.refreshTable();
        this.closeDeleteModal();

    },
    closeAddUserForm() {
      this.refreshTable();
      this.modal.hide();
      this.showCreateEditUser = false;
    },
    showDeleteUserModal(userData) {
      this.selectedUser = userData;
      var modalElement = document.getElementById("delete_modal");
      this.deleteUserModal = new bootstrap.Modal(modalElement);
      this.deleteUserModal.show();
    },
    changeUserPassword(userData) {
      delete userData.edit;
      this.selectedUser = userData;
      if(!userData.isSSO){
        var modalElement = document.getElementById("password_modal");
        this.passwordModal = new bootstrap.Modal(modalElement);
        this.passwordModal.show();
      }
    },
    closeDeleteModal() {
      
      this.deleteUserModal.hide();
    },
    closeEditUserForm() {
      this.refreshTable();
      this.editUserVisible = false;
      this.editUserModal.hide();
    },
    onGridReady(params) {
      this.api = params.api;
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    gridSizeChanged() {
      this.api.sizeColumnsToFit();
    },
    getSelectedRows() {
      try {
        if (this.showCreateEditUser) {
          this.showCreateEditUser = !this.showCreateEditUser;
        }
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        console.log("data", selectedData);
        console.log(`Selected nodes: ${selectedData}`);
        if (!selectedNodes[0]) {
          throw "Select a user first.";
        }
        this.selectedUser = selectedData[0];
        this.editUserVisible = true;
        this.editUserModal.show();
      } catch (err) {}
    },
    newUser() {
      this.modal.show();
      this.showCreateEditUser = true;
    },
    async loadData() {
      try{
        let res = await ApiService.get("user");
        if(res.status !== 200){
          this.toast.error(codes.error)
        }
        this.list = res.data;
      }catch (e) {
        this.toast.error(codes.error)
      }
    },
    onSubmitCompleted() {
      console.log("hitting here");
      this.loadData();

      this.modal.hide();
    },
  },
  setup() {
    setCurrentPageTitle("User Management");
    const checked = ref(false);
    const store = useStore();
    const initialUser = store.getters.currentUser;
    const currentClinicName = initialUser.currentClinic.name;

    return {
      checked,
      initialUser,
      currentClinicName,
    };
  },
});
</script>

<style lang="scss" scoped>
.mr-3{
  margin-right: 3rem !important;
}

@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
