
import { defineComponent, ref } from "vue";
import { useAuth0 } from "@/auth/auth";
import ApiService from "@/core/services/ApiService";
import { UserContext } from "@/auth/UserContext";
import { ClinicContext } from "@/auth/ClinicContext";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { ClinicInfo } from "@/store/modules/AuthModule";
import store from "@/store/";
import { InstanceInfoContext } from '@/auth/InstanceInfoContext';
import GoatNotesService from "@/core/services/GoatNotesService";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
  },
  methods: {},
  async mounted() {
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    const domain = "dev-qpn294pj.us.auth0.com";
    const clientId = "z9TMAZxs1GANE869RHEvEQgGXO62EHgK";
    const audience = "https://api.rna180.ca";

    const redirectTo = this.$route.query.redirectFrom ?? "dashboard";
    console.log("redirectTo", redirectTo);
    console.log("vue query", this.$route.query);
    console.log("window query", window.location.pathname);

    const auth0 = await useAuth0({
      domain,
      clientId,
      audience,
      redirectUri: `${window.location.origin}/login?redirectFrom=${redirectTo}`,
      onRedirectCallback: (appState) => {
        console.log("appstate", appState);
        this.$router.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      },
    });

    const params = new URL(window.location.href).searchParams;
    const routeLoginHint = params.get("u");

    console.log(auth0);

    if (auth0.loading) {
      throw "something is wrong";
    }

    if (!this.$auth.isAuthenticated) {
      console.log("not authenticated");

      if (!this.$auth.error) {
        if (routeLoginHint) {
          console.log("routeLoginHint", routeLoginHint);
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.$auth.loginWithRedirect({
            login_hint: routeLoginHint as string,
          });
        } else {
          console.log("loginWithRedirect", "login");
          this.$auth.loginWithRedirect({});
        }
        await ApiService.setAuthHeader();
      }
    } else {
      await ApiService.setAuthHeader();
    }

    GoatNotesService.setAuthHeader("ApiKey 77b91fcf-2ba9-42c1-ad39-ff35dde0bb5d");
    //need to get GoatNotes user and goatnotes clinicGuid

    //let's check if we should change the Api path based on query string
    let pr = null as String | null;
    let redirectLinkContent : any  = null;
    if (this.$route.redirectedFrom){
      redirectLinkContent = this.$route.redirectedFrom.query;
    }

    if(redirectLinkContent && redirectLinkContent.pr){
      console.log("here", redirectLinkContent)
      pr = redirectLinkContent.pr;
    } else if (this.$route.query.redirectFrom){
      pr = (new URL("http://www.dummy.com" + this.$route.query.redirectFrom)).searchParams.get("pr")            
    }   

    console.log("PR is", pr);
    if (pr){
      ApiService.vueInstance.axios.defaults.baseURL = `https://rna180-pr-${pr}.azurewebsites.net`
    }

    const context = new UserContext(this.$route, this.$store, this.$router);
    if (!await context.buildCurrentUser(auth0)){
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      return;
    }
    const clinicContext = new ClinicContext(this.$route, this.$store);
    await clinicContext.buildClinic(auth0);
    const instanceInfo = new InstanceInfoContext(this.$route, this.$store);
    await instanceInfo.buildInstance();
    this.$router.push(redirectTo.toString());
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");

    const currentUser = this.$store.getters.currentUser;
    const clinicInfo = this.$store.getters.currentClinicInfo;

    (window as any).pendo.identify({
        visitor: {
            id: currentUser.userId,
            email: currentUser.email,
        },
        account: {
            id: clinicInfo.clinicId,
            clinicGuid: clinicInfo.clinicGuid,
            name: clinicInfo.name,
        }
    });    	

    // Add custom data to elastic apm
			// const customContext = {
			// 	currentuser: {
			// 		id: currentUser.userId,
			// 		email: currentUser.email
			// 	},
			// 	clinic: {
			// 		name: clinicInfo.name,
			// 		id: clinicInfo.clinicId,
			// 	},
			// };
			// (this as any).$apm.setCustomContext(customContext)
  },
});
