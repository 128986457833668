<template>
  <div class="loader" v-if="loading"></div>
  <h2>Provider Search</h2>
  <div class="row card">
    <div class="col-12">
      <div class="d-flex  py-2 align-items-center">
        <div class="d-flex align-items-center grey-bg py-2 px-7">
          <div class="symbol symbol-50px me-5">
            <i
              class="fas fa-calendar-alt fs-30-px mx-2"
              style="color: #009ef7;"
            ></i>
          </div>
          <div class="flex-grow-1 text-start">
            <span class="text-muted d-block fw-bold">Start</span>
            <input type="date" name="start" class="form-control border-none p-0" v-model="startDate" />
          </div>
        </div>
        <div class="d-flex align-items-center grey-bg py-2 px-7">
          <div class="symbol symbol-50px me-5">
            <i
              class="fas fa-calendar-alt fs-30-px mx-2"
              style="color: #009ef7;"
            ></i>
          </div>
          <div class="flex-grow-1">
            <span class="text-muted d-block fw-bold">End</span>
            <input type="date" name="end" class="form-control border-none p-0" v-model="endDate" />
          </div>
        </div>

        <Multiselect
        v-model="value"
        mode="tags"
        class="cornered-radius"
        ref="multiselect"
        :close-on-select="false"
        :searchable="true"
        :create-option="true"
        :options="providerList"/>
      <button
        class="btn btn-primary mx-10 cornered-radius"
        @click="getProviderData(value)"
        style="width: fit-content"
      >
        Generate
      </button>
      </div>
    </div>
  </div>

  <div
    v-for="(provider, index) in totalPayroll"
    :key="index"
    class="row my-5 card"
  >
  <reports-table 
    :name="`${provider.first_name} ${provider.last_name}`"
    :baseWage="provider.baseWage"
    :paymentPeriod="`${formatDate(startDate)} to ${formatDate(endDate)}`"
    :payrollArray="provider.payrollReport"
    :totalHours="provider.totalHours"
    :totalWage="provider.totalWage"
    :total-bonus="provider.totalBonus"
    :isBonus="true"
    />

  </div>
</template>

<script>
	import { useStore } from "vuex";
import { defineComponent } from "@vue/runtime-core";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect/dist/multiselect";
import { minutesToHours, format, sub } from "date-fns";
import ReportsTable from '@/components/Tables/ReportsTable.vue';
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import {useToast} from "vue-toastification";
  import {codes} from "@/Codes/Codes";
  import {DateHelper} from "@/Utilities/DateHelper";

export default defineComponent({
  components: { Multiselect, ReportsTable },
  data() {

    let {startingStartDate, startingEndDate} = DateHelper.getStartingIntervalDates();
    return {
      // Note `isActive` is left out and will not appear in the rendered table
      fields: ["first_name", "last_name", "age"],
      loading: false,
      value: [],
      providerList: [],
      totalPayroll: [],
      startDate: startingStartDate.toISOString().split("T")[0],
			endDate: startingEndDate.toISOString().split("T")[0],
      toast: useToast(),
    };
  },
  async mounted() {
    console.log("mounted");
    await this.getProviders();
    this.$refs.multiselect.select(this.providerList);
    this.getProviderData(this.value);
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), "yyyy-MM-dd");
    },
    dayOfWeek(date) {
      return format(new Date(date), "EEEE");
    },
    getHours(totalMinutes) {
      return (totalMinutes / 60).toFixed(2);
    },
    getTimestamp(timestamp) {
      let time = format(new Date(timestamp), "H:mm");
      if (time == "0:00") return "-";
      return time;
    },
    async getProviders() {
      this.loading = true;
      try{
        let res =  await ApiService.query(`Provider/Details?activeOnly=true`);
        console.log(res.data);
        if(res.status !== 200){
          this.toast.error(codes.error)
        }
        if (res) {
          let tempProviderList = res.data;
          if(this.instanceInfo.isProvider){
            tempProviderList = res.data.filter(x=> x.provider_id == this.instanceInfo.providersIds.find(y=> y==x.provider_id));
          }
          tempProviderList.forEach((element) => {
            if (element.isHygienist) {
              this.providerList.push({
                value: {
                  provider_id: element.provider_id,
                  baseWage: element.baseWage,
                  first_name: element.first_name,
                  last_name: element.last_name,
                },
                label: element.first_name + " " + element.last_name,
              });
            }
          });

          console.log("providerList", this.providerList);
        }
      }catch (e) {
        this.toast.error(codes.error)
      }finally{
                this.loading = false;
            }
    },
    async getProviderData(selectedProviders) {
      this.totalPayroll = [];
      for (const provider of selectedProviders) {
        console.log("provider info ", provider);
        const providerInfo = {
          first_name: provider.first_name,
          last_name: provider.last_name,
          baseWage: provider.baseWage ? provider.baseWage : 0,
        };
        console.log("provider info 1", providerInfo);
        try {
          const params = {
            startDate: this.startDate,
            endDate: this.endDate,
            providerID: provider.provider_id,
          };
          let res = await ApiService.query("/Report/GetPayrollReportByCommission", {
            params,
          });
          if (res.status === 200) {
            if(res.data){
              providerInfo.payrollReport = res.data;
              let totalMinutes = 0;
              let totalWage = 0;
              let totalBonus = 0;
              for (const day of providerInfo.payrollReport) {
                totalMinutes = totalMinutes + day.totalMinutesClocked;
                totalWage = totalWage + day.totalPay;
                totalBonus += day.bonus || 0;
              }
              providerInfo.totalHours = this.getHours(totalMinutes);
              providerInfo.totalWage = totalWage.toFixed(2);
              providerInfo.totalBonus = totalBonus.toFixed(2);
              this.totalPayroll.push(providerInfo);
            }
          }else{
            this.toast.error(codes.error)
          }
        } catch (e) {
          this.toast.error(codes.error)
        }
      }
      console.log("total report", this.totalPayroll);
    },
    getAllData() {
      this.getData();
      this.getProviderData();
    },
    getHours(totalMinutes) {      
      var hours = (totalMinutes / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + ":" + String(rminutes).padStart(2,'0');
    },
  },
  setup(props, context){
    setCurrentPageTitle("Payroll");
    const store = useStore();
    const instanceInfo = store.getters.currentInstanceInfo;
    return {
      instanceInfo,
    }
  }
});
</script>

<style scoped>
table {
  border-collapse: collapse;
}
td {
  border: 1px solid #ccc;
}
th,
td {
  padding: 10px;
  text-align: left;
}
tr:nth-child(even) td {
  background-color: #eee;
}
tr:nth-child(odd) td {
  background-color: #fff;
}
tr:last-child td {
  border: 0 !important;
}
td.total {
  background-color: rgba(0, 0, 0, 0) !important;
  text-align: right;
}
td.hours {
  background-color: #c2d1f5 !important;
}
td.price {
  background-color: #fbdfc4 !important;
}
td.timestamp {
  width: 3%;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
    left: -25%;
}
:deep( .multiselect-tag) {
  background: var(--ms-tag-bg, #0095e8);
}

:deep( .multiselect-clear:hover .multiselect-clear-icon) {
  background: var(--ms-clear-color, #999);
}
</style>