<template>
	<td v-if="opted" class="text-center" >

		<span v-if="task.calculationType === 7 && task.procedureRuleTypeId == 35">
			<p class="mb-0" v-for="(ada, index) in task.procedureOptions.filter(x=> x.show == true)" :key="index">
				{{ ada.adaCode }}
			</p>
		</span>
		<span v-else-if="task.calculationType === 8">
			<p :class="`head-p`">
				&nbsp;
			</p>
		</span>

		<span v-else-if="task.calculationType !== 7">
			<p v-if="(!task.adaUsed || task.adaUsed.length == 0) && task.adaCode">
				{{ task.adaCode }}
			</p>
			<p v-else-if="task.adaUsed">
				<span v-for="(ada, index) in task.adaUsed" :key="index"> {{ ada }} <br /> </span>
			</p>
			<p v-else>&nbsp;</p>
			<div :class="`${item.show ? 'pl-5 sub-p' : ''}`" v-show="task.calculationType !== 4 && (task.calculationType == 1 && task.procedureOptions && task.procedureOptions.length > 1)" v-for="(item, cindex) in task.procedureOptions" :key="`c_${cindex}`">
				<p v-if="(!task.adaUsed || task.adaUsed.length == 0) && item.show && task.calculationType !== 4">
					{{ item.adaCode }}
				</p>
			</div>
		</span>

	</td>
	<td v-else class="text-center" >
		<span v-if="task.calculationType === 7 && task.procedureRuleTypeId == 35">
			<p :class="`head-p`">
				&nbsp;
			</p>
			<p style="display: none;" :class="`pl-5 sub-p hide-${task.procedureRuleTypeId}`" v-for="(item, index) in type7s" :key="`x_${index}`">
				&nbsp;
			</p>
		</span>
		<span v-else-if="task.calculationType === 8">
			<p :class="`head-p`">
				&nbsp;
			</p>
			<p style="display: none;" :class="`pl-5 sub-p hide-${task.procedureRuleTypeId}`" v-for="(item, index) in type7s" :key="`x_${index}`">
				&nbsp;
			</p>
		</span>
		<span v-else-if="task.calculationType !== 7">
			<p :class="`${task.procedureOptions && task.procedureOptions.length > 0 ? 'head-p' : ''}`">{{ task.adaCode }} <span v-if="!task.adaCode">&nbsp;</span></p>
			<p style="display: none;" :class="`pl-5 sub-p hide-${task.procedureRuleTypeId}`" v-for="(item, index) in task.procedureOptions" :key="`x_${index}`">
				{{ item.adaCode }}
			</p>
		</span>
	</td>
</template>

<script>
	export default {
		props: { type7s: { required: true }, appointment: { type: Object, required: true }, task: { type: Object, required: true }, opted: { type: Boolean, required: true } },
	};
</script>
